import React from 'react'
import { withSnackbar } from 'notistack'
import Dialog from '@material-ui/core/Dialog'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import UserProfilePage from './UserProfilePage'

const Services = require('../../RemoteServices/RemoteServices.js')
class ReferralDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            showDelieveredAmount: false,
            showCurrentLessonUpdate: false,
            showAssignmentVisibilityUpdate: false,
            showCreateNotifications: false,
            historyData: [],
            studentDetails: {
                current_lesson: {
                    title: ''
                }
            },
            studentDetailsNew: {},
            studentId: this.props?.studentData?.user?.id,
            assignmentImages: [],
            imagesModal: false,
            referralListModal: false,
            followListModal: false,
            followList: [],
            followListNext: '',
            followListPrevious: '',
            followTableTitle: '',
            pageLoading: true
        }
        this.openImagesInNewTab = this.openImagesInNewTab.bind(this)
    }


    async componentDidMount() {
        await this.getDetails()
    }


    async getDetails() {
        console.log('asdfasdfasdf',this.props.studentData)
        const userId = this.props.studentData.id
        await Services.getUserDetail(userId).then((response) => {
            this.setState({ studentDetails: response, pageLoading: false })
        })
    }

    async openImagesInNewTab() {
        console.log('reached here', this.state.selectedRow)
        const images = []
        if (this.state.selectedRow) {
            let userAssignmentImages = this.state.selectedRow.images
            userAssignmentImages.forEach((image) => {
                images.push({ src: image.image, alt: '' })
                // window.open(image.image, "_blank")
            })
            this.setState({ assignmentImages: images })
            this.setState({ imagesModal: true })
            // console.log(this.state.assignmentImages)
        }
    }

    async setRowData(rowData) {
        await this.setState({ selectedRow: rowData })
        await this.openImagesInNewTab()
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    render() {
   
        return (this.state.pageLoading ?
                <> Loading </> :
                <>
                    <div>

                        <Dialog style={{ zIndex: 800 }} fullScreen open={this.state.open} onClose={() => {
                            this.props.closePanel()
                        }} TransitionComponent={this.Transition}>

                            <AppBar color='info' style={{ position: 'relative' }}>
                                <Toolbar>
                                    <Typography variant='h6' style={{
                                        marginLeft: 20,
                                        flex: 1
                                    }}>
                                        <h2>Referal Details</h2>
                                    </Typography>
                                    <IconButton edge='start' color='inherit' onClick={() => {
                                        this.props.closePanel()
                                    }} aria-label='close'>
                                        <CloseIcon />
                                    </IconButton>
                                </Toolbar>
                            </AppBar>
                            <UserProfilePage studentDetails={this.state.studentDetails} userId={this.props.studentData.new_id} oldId={this.props.studentData.id} />
                        </Dialog>
                    </div>
                </>
        )
    }
}

export default withSnackbar(ReferralDetails)