import React, { useState } from 'react'
import './RatingCard.css'
import Avatar from '../../assets/img/theme/avatar.png'
import Rating from '@material-ui/lab/Rating';
import { Button, Modal } from 'reactstrap'
import moment from 'moment'
import { withSnackbar } from 'notistack';
import { FormatImage } from 'utils/ImageFormat';
const Services = require('../../RemoteServices/RemoteServices.js');



const RatingCard = (props) => {

  const { ratingData, setCurrentRatingData, fetchRatings, showComments } = props
  let [showReply, setShowReply] = useState(false)
  let [replyId, setReplyId] = useState('')
  let [confirmModal, setConfirmModal] = useState(false)
  console.log(ratingData)


  const handleApproveDeny = (type) => {
    setCurrentRatingData(ratingData.id, ratingData.message, type, ratingData.user.id, ratingData.lesson, ratingData.rating)
  }


  const handleModal = () => {
    setConfirmModal(!confirmModal)
  }

  const convertDate = (date) => {
    const add = moment(date, "YYYY-MM-DDTHH:mm:ss").format("YYYY-MM-DD");
    return add;
  };

  const handleDeleteReply = () => {
    Services.deleteAppRatingReply(replyId).then((response) => {
      props.enqueueSnackbar('Reply deleted', {
        variant: 'success',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
      fetchRatings(showComments === 'review' ? false : true)
      handleModal()

    }).catch((error) => {
      props.enqueueSnackbar('Something went wrong. Please try again.', {
        variant: 'error',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right',
        }
      }
      )
    })
  }


  if (ratingData === undefined) {
    return ''
  }

  return (
    <div className="commentCardContainer">
      <div className="commentCardCommentInfoDiv">
        <div className="commentCardContainerImageDiv">
          <img src={ratingData.user.profile_picture !== null ? FormatImage(ratingData.user.profile_picture) : Avatar} className="commentCardContainerImageDiv_image" alt=''></img>
        </div>
        <div className="commentCardCommentDetailsDiv">
          <div className="commentCardActionButtonDiv"><span className="commentCardUserName">{ratingData.user.full_name}
            &nbsp;
            {<i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}

            <span className="commentDateSpan">{convertDate(ratingData.created_at)}</span>  </span> <div className="commentCardButtons"> <i onClick={() => handleApproveDeny('Publish')} class={showComments === 'review' ? 'fas fa-check-circle' : 'fas fa-edit'}></i><i onClick={() => handleApproveDeny('Delete')} class="fas fa-times-circle"></i></div></div>
          <p className="commentCardCommentText">{ratingData.message} </p>
          {ratingData.app_reply.length > 0 &&
            <div className="ratingReplyContainer">
              <span className="showCommentText" onClick={() => setShowReply(!showReply)}> {showReply ? 'Hide Replies' : 'Show Replies'}</span>
              {showReply && <div className="ratingReplyInnerContainer">
                {ratingData.app_reply.map((data) => {
                  return (<div>
                    <span className="replyUserName">{data.user.full_name}</span>
                    <span className="ratingReplyText">{data.message} <i class="fas fa-trash" onClick={() => { handleModal(); setReplyId(data.id) }}></i> </span>
                  </div>)
                })}
              </div>
              }
            </div>}
        </div>


      </div>
      <div className="commentCardLessonInfoDiv">
        <div className="ratingCardStarsContainer">
          <Rating
            name="read-only"
            value={ratingData.rating}
            readOnly
          />
        </div>
      </div>
      <Modal
        className="modal-dialog-centered"
        isOpen={confirmModal}
        toggle={handleModal}
      >
        <div className="modal-header">
          <h5 className="modal-title" id="exampleModalLabel">
            Confirmation
          </h5>
          <button
            aria-label="Close"
            className="close"
            data-dismiss="modal"
            type="button"
            onClick={handleModal}
          >
            <span aria-hidden={true}>×</span>
          </button>
        </div>
        <div className="modal-body">
          <h4>Are you sure you want to delete this reply?</h4>
        </div>
        <div className="modal-footer">
          <Button
            color="secondary"
            data-dismiss="modal"
            type="button"
            onClick={handleModal}
          >
            Cancel
          </Button>
          <Button color="primary" type="button"
            onClick={() => handleDeleteReply()}>
            Delete
          </Button>
        </div>
      </Modal>
    </div>
  )
}

export default withSnackbar(RatingCard)