import { EditorState, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { withSnackbar } from 'notistack'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import Select from 'react-select'
import {
    Button,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from 'reactstrap'


const Services = require('../../RemoteServices/RemoteServices.js')


class CoursesForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            description: '',
            shortDescription: '',
            isPaid: false,
            hasRequested: false,
            isYoutuber: false,
            unpaid: false,
            isReferred: false,
            onlyStudents: false,
            smsMarketing: false,
            emailMarketing: false,
            notificationMarketing: true,
            data: [],
            editorState: EditorState.createEmpty(),
            editorContentHtml: '',
            selectedCheckboxes: [],
            courseList: [],
            dropdownOptions: [],
            selectedOptionId: '',
            disabled: false,
            delete_after: '90_days',

        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.chooseFilters = this.chooseFilters.bind(this)
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOptionId: selectedOption.value, selectedOption }
        )
    }

    handleCheckboxChange = (event) => {
        const { name, checked } = event.target
        const { selectedCheckboxes } = this.state

        if (checked) {
            this.setState({
                selectedCheckboxes: [...selectedCheckboxes, name]
            })
        } else {
            this.setState({
                selectedCheckboxes: selectedCheckboxes.filter((checkbox) => checkbox !== name)
            })
        }
    }

    onEditorStateChange(editorState) {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    };


    uploadImageCallBack(file) {
        let token = localStorage.getItem('token')

        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    //Image selection and cases

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    handleSubmit = async () => {
        const {
            isPaid,
            hasRequested,
            isYoutuber,
            unpaid,
            isReferred,
            onlyStudents,          
            delete_after
        } = this.state
        let leadsData = {
            delete_after: delete_after,
            title: this.state.title,
            message: this.state.notificationMarketing ? this.state.editorContentHtml : this.state.description,
            short_description: this.state.shortDescription,
            course: this.state.selectedCheckboxes.includes('Paid') ? this.state.selectedOptionId : null,
            selected_checkboxes: this.state.selectedCheckboxes.length > 0 ? this.state.selectedCheckboxes : null,
        }
        this.setState({ disabled: true })
        let str = '?'
        if (isPaid) str = str + '&payment_status=Paid'
        if (unpaid) str = str + '&payment_status=UnPaid'
        if (hasRequested) str = str + '&course_requested=true'
        if (isYoutuber) str = str + '&is_youtuber=true'
        if (isReferred) str = str + '&user__referrer=true'
        if (onlyStudents) str = str + '&is_youtuber=false'
        if (this.state.smsMarketing) {
            Services.createSmsCampaign(leadsData, str).then((response) => {
                this.props.enqueueSnackbar('Sms Marketing kept in queue', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })

                this.props.onSuccess();
                this.setState({ disabled: false })
            })
                .catch((error) => {
                    this.setState({ disabled: false })
                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    )
                })
        }

        if (this.state.emailMarketing) {
            Services.createEmailCampaign(leadsData, str).then((response) => {
                this.props.enqueueSnackbar('Email Marketing kept in queue', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.setState({ disabled: false })

                this.props.onSuccess()
            })
                .catch((error) => {
                    this.setState({ disabled: false })
                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    )
                })
        }

        if (this.state.notificationMarketing) {
            Services.createNotificationCampaign(leadsData, str).then((response) => {
                this.props.enqueueSnackbar('Notifications Marketing kept in queue', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.setState({ disabled: false })

                this.props.onSuccess()
            })
                .catch((error) => {
                    this.setState({ disabled: false })
                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                    )
                })
        }
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    chooseFilters = async (event) => {
        if (event === 'smsMarketing') {
            this.setState({
                notificationMarketing: false,
                emailMarketing: false,
                smsMarketing: true
            })
        } else if (event === 'notificationMarketing') {
            this.setState({
                smsMarketing: false,
                emailMarketing: false,
                notificationMarketing: true
            })
        } else {
            this.setState({
                emailMarketing: true,
                notificationMarketing: false,
                smsMarketing: false
            })
        }
    }

    async componentDidMount() {
        await this.getCourseList()
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }


    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    render() {
        const options = this.state.dropdownOptions
        return (
            <>
                <Form>
                    <div
                        style={{
                            display: 'flex',
                            justifyContent: 'center',
                            flexDirection: 'row',
                            gap: '20px',
                            padding: '10px',
                            height: '80px',
                            marginBottom: '10px'
                        }}>
                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '18%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.notificationMarketing ? '1px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => this.chooseFilters('notificationMarketing')}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }}
                                className={'font-weight-bold'}>Notification</span>
                        </div>
                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '18%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.emailMarketing ? '1px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => this.chooseFilters('emailMarketing')}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }} className={'font-weight-bold'}>Email</span>
                        </div>
                        <div style={{
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '18%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.smsMarketing ? '1px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => this.chooseFilters('smsMarketing')}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }} className={'font-weight-bold'}>SMS</span>
                        </div>
                    </div>
                </Form>
                <Form role='form' style={{
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', width: '100%' }}>
                        <div style={{ width: '75%' }}>
                            <h3>Choose Filters:</h3>
                            <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                                <input
                                    className='custom-control-input'
                                    id='Paid'
                                    name='Paid'
                                    type='checkbox'
                                    checked={this.state.selectedCheckboxes.includes('Paid')}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className='custom-control-label' htmlFor='Paid'>
                                    Paid
                                </label>
                            </div>

                            <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                                <input
                                    className='custom-control-input'
                                    id='Unpaid'
                                    name='Unpaid'
                                    type='checkbox'
                                    checked={this.state.selectedCheckboxes.includes('Unpaid')}
                                    onChange={this.handleCheckboxChange}
                                />
                                <label className='custom-control-label' htmlFor='Unpaid'>
                                    Unpaid
                                </label>
                            </div>

                            <div className='custom-control custom-control-alternative custom-checkbox mb-3'>
                                <input
                                    className='custom-control-input'
                                    id='Referred'
                                    name='Referred'
                                    type='checkbox'
                                    onClick={this.handleCheckboxChange}
                                />
                                <label className='custom-control-label' htmlFor='Referred'>
                                    Referred
                                </label>
                            </div>

                            {this.state.selectedCheckboxes.includes('Paid') &&
                                <>
                                    <h3>Choose Certain Course:</h3>
                                    <FormGroup>
                                        <Select options={options} placeholder='Select Course' value={this.state.selectedOption}
                                            onChange={this.handleChange.bind(this)} />
                                    </FormGroup>
                                </>
                            }
                        </div>
                        <div style={{ width: '25%' }}>
                            <FormGroup style={{ width: '100%' }}>
                                <Input type={'select'}
                                    name={'delete_after'}
                                    value={this.state.delete_after}
                                    onChange={this.handleInputChange}>
                                    <option value={'with_new'}>With New</option>
                                    <option value={'3_days'}>3 Days</option>
                                    <option value={'7_days'}>1 Week</option>
                                    <option value={'30_days'}>1 Month</option>
                                    <option value={'90_days'}>3 Month</option>
                                </Input>
                            </FormGroup>
                        </div>
                    </div>



                    {!this.state.smsMarketing ?
                        <FormGroup>
                            <div className={'d-flex flex-row-reverse'}>
                                {this.state.title.length > 0 && <span style={{ fontSize: 18 }}
                                    className={'font-weight-bold'}>{this.state.title.length}/155</span>}
                            </div>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Title' type='text' name='title' value={this.state.title}
                                    onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        :
                        ''
                    }

                    {this.state.notificationMarketing ?
                        <FormGroup>
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                <Label>Short Description</Label>
                                {this.state.shortDescription.length > 0 && <span style={{ fontSize: 18 }}
                                    className={'font-weight-bold'}>{this.state.shortDescription.length}/500</span>}
                            </div>
                            <InputGroup className='input-group-alternative mb-3'>
                                <Input rows='4' placeholder='Short Description' type='textarea' name='shortDescription'
                                    value={this.state.shortDescription}
                                    onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        : ''
                    }

                    {!this.state.notificationMarketing ?
                        <FormGroup>
                            <InputGroup className='input-group-alternative'>
                                <Input
                                    id='exampleFormControlTextarea1'
                                    placeholder='Description / Message'
                                    rows='3'
                                    type='textarea'
                                    name='description'
                                    value={this.state.description}
                                    onChange={this.handleInputChange}
                                />
                            </InputGroup>
                        </FormGroup>
                        :
                        <Editor
                            editorStyle={{
                                border: '1px solid black',
                                padding: '5px',
                                borderRadius: '2px',
                                height: '400px',
                                width: '100%'
                            }}
                            placeholder='Description'
                            editorState={this.state.editorState}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            onEditorStateChange={this.onEditorStateChange.bind(this)}
                            toolbar={{
                                inline: { inDropdown: true },
                                list: { inDropdown: true },
                                textAlign: { inDropdown: true },
                                link: { inDropdown: true },
                                history: { inDropdown: true },
                                image: {
                                    uploadCallback: this.uploadImageCallBack,
                                    previewImage: true,
                                    defaultSize: { width: '100%', height: 'auto' }
                                }
                            }}
                        />
                    }


                    <div className='pb-4'>
                        <Button disabled={this.state.disabled} className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}

export default withSnackbar(CoursesForm)