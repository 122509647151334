import React from 'react'
import Header from 'components/Headers/Header.js'
import Icon from '@material-ui/core/Icon'

import {
    Form,
    Card,
    DropdownMenu,
    DropdownItem,
    DropdownToggle,
    Container,
    FormGroup,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
    Row,
    Button,
    Modal,
    Dropdown,
    Label
} from 'reactstrap'
import Select from 'react-select'
import MaterialTable, { MTableToolbar } from 'material-table'
import CircularProgress from '@material-ui/core/CircularProgress'
import StudentForm from 'components/Forms/StudentForm.js'
import Chip from '@material-ui/core/Chip'
import Avatar from '@material-ui/core/Avatar'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { withSnackbar } from 'notistack'
import { TablePagination } from '@material-ui/core'
import StudentDetails from 'components/Student/StudentDetails'
import avatar from '../../assets/img/dummyUser.png'
import PaymentStatus from '../../components/Modal/PaymentStatus'

import debounce from 'lodash.debounce'; 

const Services = require('../../RemoteServices/RemoteServices.js')


class Users extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            addFormView: false,
            passwordModal: false,
            data: [],
            coursesLookup: {},
            updatePasswordView: false,
            newPassword1: '',
            newPassword2: '',
            referralListShow: false,
            selectedRow: null,
            count: null,
            next: '',
            previous: '',
            pageNumber: 0,
            searchText: '',
            detailsPanel: false,
            searchType: '',
            searchTypeText: '',
            normalDropDownOpen: false,
            dateDropDown: false,
            dateText: '',
            dropdownOptions: [],
            selectedCourse: '',
            selectedCourseId: '',
            courseLessonList: [],
            courseLessonOptions: [],
            selectedLesson: '',
            selectedLessionId: '',
            rowsPerPage: 20,
            rowsPerpage2: 20,
            masterPassword: '',
            confirmModal: false,
            paymentHistory: false,
            studentData: '',
            newTableData: '',
            oldTableData: '',
            discountModal: false,
            totalAmount: '',
            discountAmount: '',
            amount: '',
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.submitChangePassword = this.submitChangePassword.bind(this)
        this.changeSearchType = this.changeSearchType.bind(this)
        this.renderCoursesFilters = this.renderCoursesFilters.bind(this)
        this.onSearchWithTypes = this.onSearchWithTypes.bind(this)
        this.PatchedPagination = this.PatchedPagination.bind(this)
        this.debouncedSearch = debounce(this.onSearchChange, 500);
    }

    componentWillUnmount() {
        // Ensure that any pending debounced calls are executed before unmounting
        this.debouncedSearch.cancel();
    }

    onSearchChange = async (searchTextValue) => {
        this.setState({ searchTextValue, loading: false });
        // If searchTextValue is empty, reset data and return
        if (searchTextValue.trim() === '') {
            this.getUsersList(20)
        } else {
            try {
                // Call your API service to fetch data based on searchTextValue
                const response = await Services.getStudentsSearch(searchTextValue,this.state.rowsPerPage);

                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                });
            } catch (error) {
                console.error('Error fetching students:', error);
                // Handle error appropriately
                this.setState({
                    data: [],
                    loading: false,
                    count: 0,
                    next: null,
                    previous: null
                });
            }
        }
    };

    handleInputSearchChange = (e) => {
        const searchTextValue = e.target.value;
        this.setState({ searchTextValue })
        this.debouncedSearch(searchTextValue);
    };


    getAmount = () => {
        Services.getTotalAndPercentage().then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                amount: response[0].total_amount,
            }))

        })
    }



    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        if (name === 'totalAmount') {
            this.setState({
                totalAmount: Number(value),
                discountAmount: Number(this.state.amount) - Number(value)
            })
        } else if (name === 'discountAmount') {
            this.setState({
                totalAmount: Number(this.state.amount) - Number(value),
                discountAmount: Number(value)
            })
        } else {
            this.setState({
                [name]: value
            })
        }
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    toggleBasicDropdown = () => {
        this.setState({
            normalDropDownOpen: !this.state.normalDropDownOpen
        })
    }

    async componentDidMount() {
        this.getCourseList()
        this.getUsersList(20)
        await this.getCourses()
        this.getAmount();
    }

    getUsersList(limit) {
        this.setState({ loading: true })
        Services.getStudents(limit).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous,
                verifiedEmail: response.total_verified_emails
            })
        })
    }

    getRequestedUsers() {
        this.setState({ loading: true })
        Services.getRequestedStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getPaidUsers() {
        this.setState({ loading: true })
        Services.getPaidStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getHasReferralsUsers() {
        this.setState({ loading: true })
        Services.getHasReferralsStudents().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getYoutubeUsers() {
        this.setState({ loading: true })
        Services.getYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getNonYoutubeUsers() {
        this.setState({ loading: true })
        Services.getNonYoutubeUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getOnlineStudent() {
        this.setState({loading : true})
        Services.getOnlineStudent().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            }) 
        })
    }

    getTestUsers() {
        this.setState({ loading: true })
        Services.getTestUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getDueUsers() {
        this.setState({ loading: true })
        Services.getDueUsers().then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async getCourses() {
        let coursesLookup = {}
        await Services.getCourses().then((response) => {
            (response).forEach((item) => {
             
                let itemId = item.id
                coursesLookup[itemId] = item.title
            })
        })
        
        await this.setState({ coursesLookup: coursesLookup })
    }

    submitChangePassword() {
        let userId = this.state.selectedRow.user.id
        let data = {
            password1: this.state.newPassword1,
            password2: this.state.newPassword2
        }
        Services.updatePassword(userId, data).then((response) => {
            this.setState({ updatePasswordView: false })
            this.props.enqueueSnackbar('Password Updated Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })
    }


    changeSearchType = async (type, typeText) => {
        this.setState({ searchType: type, searchTypeText: typeText, searchText: '' })
        if (type === '') {
            Services.getStudents().then((response) => {
                this.setState({
                    data: response.results,
                    loading: false,
                    count: response.count,
                    next: response.next,
                    previous: response.previous
                })
            })

        }
    }

    AddOrSubractDays = (startingDate, number) => {
        return new Date(new Date().setDate(startingDate.getDate() - number)).toLocaleDateString()
    }

    formatDate = (date) => {
        const dateParts = date.split('/')
        const outputDate = dateParts[2] + '-' + ('0' + dateParts[0]).slice(-2) + '-' + ('0' + dateParts[1]).slice(-2)
        return outputDate
    }

    changeDateType = async (event) => {
        this.setState({ dateType: event.target.value })
        await Services.getStudentsWithDate(
            event.target.value === 'All' ? '' : this.formatDate(new Date().toLocaleDateString()),
            event.target.value === 'today' ? this.formatDate(new Date().toLocaleDateString()) : event.target.value === '1' ? this.formatDate(this.AddOrSubractDays(new Date(), 1)) : event.target.value === '2' ? this.formatDate(this.AddOrSubractDays(new Date(), 2)) : event.target.value === '3' ? this.formatDate(this.AddOrSubractDays(new Date(), 3)) : event.target.value === '7' ? this.formatDate(this.AddOrSubractDays(new Date(), 7)) : event.target.value === '30' ? this.formatDate(this.AddOrSubractDays(new Date(), 30)) : ''
        ).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    async onSearchWithTypes(search) {
        await this.setState({ searchTextValue: search.target.value })
        await Services.getStudentsWithSearchType(this.state.searchType, this.state.searchText).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    populateLessonsDropdown() {
        this.setState({ courseLessonOptions: [] })
        let data = this.state.courseLessonList
        data.forEach((item) => {
            this.state.courseLessonOptions.push({ value: item.id, label: item.order + `. ${item.title}` })
        })


    }

    handleCourseSelect = async selectedCourse => {
        this.setState(
            { selectedCourse, selectedCourseId: selectedCourse.value }
        )
        await Services.filterByCourseLesson(selectedCourse.value, this.state.selectedLessionId).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })
        await Services.getCourseLessons(selectedCourse.value).then((response) => {
            this.setState({
                courseLessonList: response
            })
        })
        this.populateLessonsDropdown()
    }

    handleLessonSelect = async (selectedLesson) => {
        this.setState({
            selectedLesson: selectedLesson.label, selectedLessonId: selectedLesson.value
        })
        await Services.filterByCourseLesson(this.state.selectedCourseId, selectedLesson.value).then((response) => {
            this.setState({
                data: response.results,
                loading: false,
                count: response.count,
                next: response.next,
                previous: response.previous
            })
        })

    }

    renderCoursesFilters() {
        let coursesLookup = this.state.coursesLookup
        let str = ''
      
        for (let courseId in coursesLookup) {
     
            str = str + '<Chip avatar={<Avatar style={{backgroundColor: \'blue\', color: \'white\'}}>OS</Avatar>} label=\'Only Test\' style={{marginRight: 10}} onClick={this.getTestUsers.bind(this)}  />'
        }
        return (
            <>
                {str}
            </>
        )
    }


    PatchedPagination = function (props) {
        const {
            ActionsComponent,
            onChangePage,
            onChangeRowsPerPage,
            count,
            page,
            rowsPerPage,
            ...tablePaginationProps
        } = props

        return (
            <TablePagination
                {...tablePaginationProps}
                // @ts-expect-error onChangePage was renamed to onPageChange
                onPageChange={(e, page) => {
                    if (page > this.state.pageNumber) {
                        this.setState({ pageNumber: this.state.pageNumber + 1, loading: false })
                        Services.getCustom(this.state.next).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    } else {
                        this.setState({ pageNumber: this.state.pageNumber - 1, loading: false })
                        Services.getCustom(this.state.previous).then((response) => {
                            this.setState({
                                data: response.results,
                                loading: false,
                                count: response.count,
                                next: response.next,
                                previous: response.previous
                            })
                        })
                    }
                }}
                onRowsPerPageChange={(e, page) => {
                    this.onRowsPerPageChange()
                    this.setState({ rowsPerPage: page.props.value })
                }}
                rowsPerPage={this.state.rowsPerPage}
                count={this.state.count}
                page={this.state.pageNumber}
                ActionsComponent={(subprops) => {
                    const { onPageChange, onRowsPerPageChange, ...actionsComponentProps } = subprops
                    return (
                        // @ts-expect-error ActionsComponent is provided by material-table
                        <ActionsComponent
                            {...actionsComponentProps}
                            onChangePage={onPageChange}
                            onChangeRowsPerPage={onRowsPerPageChange}
                        />
                    )
                }}
            />
        )
    }


    handleAmountUpdate = () => {
        new Promise((resolve, reject) => {
            setTimeout(() => {
                if (JSON.parse(localStorage.getItem('user')).role_type !== 'StudentEditor' ) {
                    if (this.state.newTableData.course !== null && this.state.newTableData.payment_status !== 'Unpaid') {
                        let reqDTO = {
                            full_name: this.state.newTableData.full_name,
                            email: this.state.newTableData.email,
                            discountAmount: Number(this.state.discountAmount),
                            course: this.state.newTableData.course,
                            payment_status: this.state.newTableData.payment_status,
                            phone_number: this.state.newTableData.phone_number
                        }

                        // Allow update only for specificRoleType
                        Services.updateStudent(this.state.oldTableData.id, reqDTO).then((response) => {
                            this.getUsersList(20);
                            this.setState({ studentData: response, discountModal: false, totalAmount: 0, discountAmount: 0 })

                            if (this.state.oldTableData.payment_status === 'Unpaid' && this.state.oldTableData.course === null) {
                                this.toggleState('paymentHistory')
                            }
                            this.props.enqueueSnackbar('User Updated Successfully', {
                                variant: 'success',
                                anchorOrigin: {
                                    vertical: 'bottom',
                                    horizontal: 'right'
                                }
                            })
                        })
                            .catch((error) => {
                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                    variant: 'warning',
                                    anchorOrigin: {
                                        vertical: 'bottom',
                                        horizontal: 'right'
                                    }
                                })
                            })
                    } else {
                        this.props.enqueueSnackbar('You Are not allowed to perform this action', {
                            variant: 'success',
                            anchorOrigin: {
                                vertical: 'bottom',
                                horizontal: 'right'
                            }
                        })
                    }
                }
                
                else {
                    this.props.enqueueSnackbar('Please Contact Adminstrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    })
                }
                resolve()
            }, 300)
        })
    }

    handleCreateStudentOpen() {
        this.toggleState('passwordModal')
        this.toggleState('addFormView')
    }

    handleUserUpdate(oldData, newData) {

    }

    render() {
        const coursesLookup = this.state.coursesLookup
        const referralsList = this.state.selectedRow
        const courseOptions = this.state.dropdownOptions
        const lessonOptions = this.state.courseLessonOptions
        this.renderCoursesFilters()
        return (
            <>
                <Header onlineCount={this.props.onlineCount} onlineUsers={this.props.onlineUsers} />
                <Container className='mt--7' fluid>

                    <Row>
                        <div className=' col'>
                            <Card className=' shadow'>
                                {!(this.state.addFormView) ?
                                    this.state.loading ?
                                        <CircularProgress style={{
                                            margin: 50,
                                            alignSelf: 'center'
                                        }} />
                                        :
                                        <>
                                            <div style={{ display: 'flex', flexDirection: 'column' }}>


                                                <FormGroup
                                                    style={{ margin: 0, padding: 15, display: 'flex', width: '100%' }}>
                                                    <Dropdown isOpen={this.state.normalDropDownOpen} toggle={() => {
                                                        this.toggleState('normalDropDownOpen')
                                                    }}>
                                                        <DropdownToggle caret>
                                                            {this.state.searchTypeText ? this.state.searchTypeText : 'All'}
                                                        </DropdownToggle>
                                                        <DropdownMenu container='body'>
                                                            <DropdownItem
                                                                onClick={() => this.changeSearchType('', 'All')}>All</DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => this.changeSearchType('user_full_name', 'Name')}>Name</DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => this.changeSearchType('user_id', 'User Id')}>User
                                                                Id</DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => this.changeSearchType('user_phone_number', 'Phone No')}>Phone
                                                                No.</DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => this.changeSearchType('user_referral_code', 'Referal Code')}>Referal
                                                                Code</DropdownItem>
                                                            <DropdownItem
                                                                onClick={() => this.changeSearchType('user_email', 'Email Address')}>Email
                                                                Address</DropdownItem>

                                                        </DropdownMenu>
                                                    </Dropdown>
                                                    <InputGroup
                                                        className='input-group-alternative mb-3'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-hat-3' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input
                                                            placeholder={this.state.searchTypeText ? this.state.searchTypeText : 'Search..'}
                                                            type='text'
                                                            name='searchTextValue'
                                                            value={this.state.searchTextValue}
                                                            onChange={this.handleInputSearchChange}
                                                        />
                                                    </InputGroup>
                                                    <Input className={'ml-2'} type={'select'}
                                                        value={this.state.dateType}
                                                        onChange={this.changeDateType}>
                                                        <option value={''}>Select Date</option>
                                                        <option value={'All'}>All</option>
                                                        <option value={'today'}>Today</option>
                                                        <option value={'1'}>Last 1 Days</option>
                                                        <option value={'2'}>Last 2 Days</option>
                                                        <option value={'3'}>Last 3 Days</option>
                                                        <option value={'7'}>Last 7 Days</option>
                                                        <option value={'30'}> Last 30 Days</option>
                                                    </Input>
                                                </FormGroup>

                                                <div style={{
                                                    padding: '10px 10px',
                                                    zIndex: '100',
                                                    width: '100%',
                                                    display: 'flex',
                                                    justifyContent: 'space-around'
                                                }}>
                                                    <div style={{ width: '45%' }}><Select options={courseOptions}
                                                        placeholder='Select course'
                                                        value={this.state.selectedCourse}
                                                        onChange={this.handleCourseSelect.bind(this)} />
                                                    </div>
                                                    <div style={{ width: '45%' }}><Select options={lessonOptions}
                                                        placeholder='Select lesson'
                                                        value={this.state.selectedOption}
                                                        onChange={this.handleLessonSelect.bind(this)} />
                                                    </div>

                                                </div>
                                            </div>
                                            <MaterialTable
                                                columns={[
                                                    {
                                                        title: 'Avatar',
                                                        field: 'imageUrl',
                                                      editable: JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 'always' : 'never',
                                                        render: rowData => (
                                                            <div style={{ position: 'relative', width: 40, height: 40 }}>
                                                                <img
                                                                    src={rowData.user.profile_picture || avatar}
                                                                    style={{
                                                                        width: '100%',
                                                                        height: '100%',
                                                                        objectFit: 'fill',
                                                                        borderRadius: '50%'
                                                                    }}
                                                                    alt=''
                                                                />
                                                                <span
                                                                    style={{
                                                                        position: 'absolute',
                                                                        bottom: 0,
                                                                        right: 0,
                                                                        width: 12,
                                                                        height: 12,
                                                                        borderRadius: '50%',
                                                                        backgroundColor: rowData.user.is_online ? 'green' : 'lightgrey',
                                                                        border: '2px solid white'
                                                                    }}
                                                                />
                                                            </div>
                                                        )
                                                    },
                                                    { title: 'Roll. No.', field: 'new_id', editable: false },
                                                    {
                                                        title: 'Full Name',
                                                        field: 'user.full_name',
                                                      editable: JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 'always' : 'never',
                                                        render: rowData => <span style={{ cursor: 'pointer' }}
                                                            onClick={async () => {
                                                                await this.setState({
                                                                    detailsPanel: true,
                                                                    selectedRow: rowData
                                                                })
                                                            }
                                                            }>{rowData.user.full_name}
                                                            &nbsp;
                                                            {rowData?.user?.email_verified && <i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}

                                                        </span>
                                                    },
                                                    {
                                                        title: 'Email', field: 'user.email',
                                                      editable: JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 'always' : 'never',
                                                         render: rowData =>
                                                            <>
                                                             {JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 
                                                                <span>
                                                                    {rowData.user.email}</span> :'NA'}

                                                            </>
                                                    },
                                                   
                                                    { title: 'Phone', field: 'user.phone_number',
                                                      editable: JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 'always' : 'never',
                                                      render:rowData=>
                                                      <>
                                                      {
                                                        JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 
                                                        <span> {rowData.user.phone_number} </span>:'NA'
                                                      }
                                                      </>
                                                    },
                                                    {
                                                        title: 'Course',
                                                        field: 'course',
                                                        render: rowData => <> {rowData.course ? coursesLookup[rowData.course] : 'NA'}</>,
                                                        lookup: coursesLookup
                                                    },
                                                    {
                                                        title: 'Payment Status',
                                                        field: 'payment_status',
                                                      editable: JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ? 'always' : 'never',
                                                        render: rowData =>
                                                            <>
                                                        {rowData.payment_status === 'Paid' &&
                                                            <Icon style={{ color: 'green' }}>check</Icon>
                                                        }
                                                        {
                                                            rowData.payment_status === 'Unpaid' &&
                                                            <Icon style={{ color: 'red' }}>cancel</Icon>
                                                        }
                                                        {
                                                            rowData.payment_status === 'Due' &&
                                                            <h3>Due</h3>
                                                        }
                                                    </>
                                                            ,
                                                        lookup: { 'Paid': 'Paid', 'Unpaid': 'Not Paid', 'Due': 'Due' }
                                                    },
                                                    {
                                                        title: 'Group',
                                                        lookup: {
                                                            'youtuber': 'Youtuber',
                                                            'student': 'Student',
                                                            'test': 'Test'
                                                        },
                                                        hidden: true
                                                    },
                                                    {
                                                        title: 'Referral Code',
                                                        hidden: true,
                                                        render: rowData => <b>{rowData.user.referral_code}</b>
                                                    },
                                                    {
                                                        title: 'Referred Count',
                                                        hidden: true,
                                                        render: rowData => <b>
                                                            {rowData.user.referred_count}
                                                            {rowData.user.referred_count > 0 &&
                                                                <Button className='view-icon btn-2' color='secondary'
                                                                    outline type='button' onClick={() => {
                                                                        this.setState({
                                                                            referralListShow: true,
                                                                            selectedRow: rowData
                                                                        })
                                                                    }}>
                                                                    <span className='btn-inner--icon'>
                                                                        <i className='ni ni-bullet-list-67' />
                                                                    </span>
                                                                </Button>
                                                            }

                                                        </b>
                                                    }
                                                ]}
                                                data={this.state.data}
                                                title={<div style={{ display: 'flex', width: '500px', alignItems: 'center', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                    <h2>Student List</h2>
                                                    {JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'SUPERUSER' ||JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'ADMIN'?<span>Verified Email: {this.state.verifiedEmail || 0}</span>:''}
                                                   {JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'SUPERUSER' ||JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'ADMIN'? <span>Online: {this?.props?.onlineUsers?.length>0?this?.props?.onlineUsers?.length:this.props.onlineCount}</span>:''}

                                                </div>}
                                                actions={ JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' ?
                                                    [
                                                    {
                                                        icon: 'add',
                                                        tooltip: 'Add Student',
                                                        isFreeAction: true,
                                                        onClick: (event) => this.toggleState('passwordModal')
                                                    }, {
                                                        icon: 'update',
                                                        tooltip: 'Update Password ',
                                                        onClick: (event, rowData) => {
                                                            this.setState({
                                                                updatePasswordView: true,
                                                                selectedRow: rowData
                                                            })
                                                            
                                                        }
                                                    },

                                                    {
                                                        icon: 'visibility',
                                                        tooltip: 'View ',
                                                        onClick: async (event, rowData) => {
                                                            await this.setState({
                                                                detailsPanel: true,
                                                                selectedRow: rowData
                                                            })
                                                           
                                                        }
                                                    },
                                                    JSON.parse(localStorage.getItem('user')).role_type !== 'StudentEditor' && {
                                                        icon: 'delete',
                                                        tooltip: 'Delete User ',
                                                        onClick: (event, rowData) => {
                                                            this.setState({
                                                                confirmModal: true,
                                                                selectedRow: rowData
                                                            })
                                                        }
                                                    }
                                                ]:[
                                                    {
                                                        icon: 'visibility',
                                                        tooltip: 'View ',
                                                        onClick: async (event, rowData) => {
                                                            await this.setState({
                                                                detailsPanel: true,
                                                                selectedRow: rowData
                                                            })
                                                          
                                                        }
                                                    },
                                                ]
                                            }


                                                components={{
                                                    Pagination: props => (
                                                        <>
                                                            <TablePagination
                                                                {...props}
                                                                rowsPerPageOptions={[20, 100, 500]}
                                                                rowsPerPage={this.state.rowsPerPage}
                                                                count={JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'SUPERUSER' ? this.state.count : 'n/a'}
                                                                page={this.state.pageNumber}
                                                                onChangeRowsPerPage={(e, page) => {
                                                                    this.setState({ rowsPerPage: page.props.value })
                                                                    this.getUsersList(page.props.value)

                                                                }}
                                                                onChangePage={(e, page) => {
                                                                    if (page > this.state.pageNumber) {
                                                                        this.setState({
                                                                            pageNumber: this.state.pageNumber + 1,
                                                                            loading: true
                                                                        })
                                                                        Services.getCustom(this.state.next).then((response) => {
                                                                            this.setState({
                                                                                data: response.results,
                                                                                loading: false,
                                                                                count: response.count,
                                                                                next: response.next,
                                                                                previous: response.previous
                                                                            })
                                                                        })
                                                                    } else {
                                                                        this.setState({
                                                                            pageNumber: this.state.pageNumber - 1,
                                                                            loading: true
                                                                        })
                                                                        Services.getCustom(this.state.previous).then((response) => {
                                                                            this.setState({
                                                                                data: response.results,
                                                                                loading: false,
                                                                                count: response.count,
                                                                                next: response.next,
                                                                                previous: response.previous
                                                                            })
                                                                        })
                                                                    }
                                                                }
                                                                }

                                                            >
                                                            </TablePagination>
                                                        </>
                                                    ),
                                                    Toolbar: props => (
                                                       <>
                                                       {  JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' &&
                                                         <div>
                                                         <MTableToolbar {...props} />
                                                         <div style={{
                                                             padding: '10px 10px',
                                                             marginLeft: 10,
                                                             paddingBottom: 20
                                                         }}>Filters:
                                                             <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'green',
                                                                 color: 'white'
                                                             }}>A</Avatar>} label='All'
                                                                 style={{ marginRight: 10, marginLeft: 10 }}
                                                                 onClick={this.getUsersList.bind(this)} />
                                                             || <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'green',
                                                                 color: 'white'
                                                             }}>HR</Avatar>} label='Requested'
                                                                 style={{ marginRight: 10, marginLeft: 10 }}
                                                                 onClick={this.getRequestedUsers.bind(this)} />
                                                             <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'yellow',
                                                                 color: 'black'
                                                             }}>IR</Avatar>} label='Referred'
                                                                 style={{ marginRight: 10 }}
                                                                 onClick={this.getHasReferralsUsers.bind(this)} />
                                                             || Payment Status : <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'blue',
                                                                 color: 'white'
                                                             }}>HP</Avatar>} label='Paid' style={{ marginRight: 10 }}
                                                                 onClick={this.getPaidUsers.bind(this)} />
                                                             <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'yellow',
                                                                 color: 'black'
                                                             }}>OS</Avatar>} label='Due' style={{ marginRight: 10 }}
                                                                 onClick={this.getDueUsers.bind(this)} />
                                                             || Group : <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'green',
                                                                 color: 'white'
                                                             }}>OY</Avatar>} label='Youtubers'
                                                                 style={{ marginRight: 10 }}
                                                                 onClick={this.getYoutubeUsers.bind(this)} />
                                                             <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'yellow',
                                                                 color: 'black'
                                                             }}>OS</Avatar>} label='Students'
                                                                 style={{ marginRight: 10 }}
                                                                 onClick={this.getNonYoutubeUsers.bind(this)} />
                                                             <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'blue',
                                                                 color: 'white'
                                                             }}>OS</Avatar>} label='Test' style={{ marginRight: 10 }}
                                                                 onClick={this.getTestUsers.bind(this)} />
                                                              || Online: <Chip avatar={<Avatar style={{
                                                                 backgroundColor: 'green',
                                                                 color: 'white'
                                                             }}>On</Avatar>} label='Online Users'
                                                                 style={{ marginRight: 10 }}
                                                                 onClick={this.getOnlineStudent.bind(this)} />    
                                                         </div>
                                                   
                                                     </div>
                                                       }
                                                       </>
                                                    )
                                                }}
                                                options={{
                                                    exportButton: true,
                                                    exportAllData: true,
                                                  
                                                    search: true,
                                                    pageSize: this.state.rowsPerPage,
                                                    rowsPerPage: this.state.rowsPerPage,
                                                    pageSizeOptions: [20, 100, 500],
                                                    columnsButton: true,
                                                    actionsColumnIndex: -1,
                                                    actionsCellStyle: {
                                                      paddingLeft:JSON.parse(localStorage.getItem('user')).role_type === 'Teacher' ? '10%':''
                                                    },
                                                    headerStyle: {
                                                        backgroundColor: '#6772E5',
                                                        color: '#FFF'
                                                    }
                                                }}
                                                editable={{
                                                    onRowUpdate: (newData, oldData) =>
                                                        new Promise((resolve, reject) => {
                                                            setTimeout(() => {
                                                                if (JSON.parse(localStorage.getItem('user')).role_type === 'Superuser') {
                                                                    if (newData.course !== null && newData.payment_status !== 'Unpaid') {
                                                                        if (oldData.course === null && oldData.payment_status === 'Unpaid') {
                                                                            this.setState({ newTableData: newData, oldTableData: oldData, discountModal: true })
                                                                        } else {
                                                                            Services.updateStudent(oldData.id, newData).then((response) => {
                                                                                this.getUsersList(20);
                                                                                this.setState({ studentData: response })
                                                                                if (oldData.payment_status === 'Unpaid' && oldData.course === null) {
                                                                                    this.toggleState('paymentHistory')
                                                                                }
                                                                                this.props.enqueueSnackbar('User Updated Successfully', {
                                                                                    variant: 'success',
                                                                                    anchorOrigin: {
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'right'
                                                                                    }
                                                                                })
                                                                            })
                                                                                .catch((error) => {
                                                                                    this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                                        variant: 'warning',
                                                                                        anchorOrigin: {
                                                                                            vertical: 'bottom',
                                                                                            horizontal: 'right'
                                                                                        }
                                                                                    })
                                                                                })
                                                                        }
                                                                    } else {
                                                                        const reqDto = {
                                                                            user: {
                                                                                full_name: newData.user.full_name,
                                                                                email: newData.user.email,
                                                                                phone_number: newData.user.phone_number
                                                                            },
                                                                            course: null,
                                                                        }
                                                                        Services.updateStudent(oldData.id, reqDto).then((response) => {
                                                                            this.getUsersList(20);
                                                                            this.setState({ studentData: response })
                                                                            this.props.enqueueSnackbar('User Updated Successfully', {
                                                                                variant: 'success',
                                                                                anchorOrigin: {
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }
                                                                            })
                                                                        }).catch((error) => {
                                                                            // this.getUsersList(20);
                                                                            this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                                variant: 'warning',
                                                                                anchorOrigin: {
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }
                                                                            })
                                                                        })
                                                                       
                                                                    }

                                                                   
                                                                } 
                                                                else 
                                                                if (JSON.parse(localStorage.getItem('user')).role_type === 'Teacher') {
                                                                  
                                                                    if (oldData.course !== null && oldData.payment_status === 'Paid') {
                                                                        Services.updateStudent(oldData.id, newData).then((response) => {
                                                                            this.getUsersList(20);
                                                                            this.setState({ studentData: response })
                                                                           
                                                                            this.props.enqueueSnackbar('User Updated Successfully', {
                                                                                variant: 'success',
                                                                                anchorOrigin: {
                                                                                    vertical: 'bottom',
                                                                                    horizontal: 'right'
                                                                                }
                                                                            })
                                                                        })
                                                                            .catch((error) => {
                                                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                                                    variant: 'warning',
                                                                                    anchorOrigin: {
                                                                                        vertical: 'bottom',
                                                                                        horizontal: 'right'
                                                                                    }
                                                                                })
                                                                            })
                                                                    }else{
                                                                        this.props.enqueueSnackbar('You Are not allowed to perform this action on unpaid', {
                                                                            variant: 'warning',
                                                                            anchorOrigin: {
                                                                                vertical: 'bottom',
                                                                                horizontal: 'right'
                                                                            }
                                                                        }) 
                                                                    } 
                                                                 
                                                                }
                                                                else {
                                                                    this.props.enqueueSnackbar('You Are not allowed to perform this action', {
                                                                        variant: 'warning',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                }
                                                                resolve()
                                                            }, 100)
                                                        })
                                                }}
                                            />
                                        </>
                                    :
                                    <>
                                        <div class='d-flex mt-3'>
                                            <div class='mr-auto ml-4 mt-2'><h2>Students List</h2></div>
                                            <div class='p-2'>
                                                <Button className='btn-icon btn-3' color='primary' type='button'
                                                    onClick={() => this.toggleState('addFormView')}>
                                                    <span className='btn-inner--icon'>
                                                        <i className='ni ni-bag-17' />
                                                    </span>
                                                    <span className='btn-inner--text'>Back</span>
                                                </Button>
                                            </div>
                                        </div>

                                        <hr class='solid'></hr>
                                        <StudentForm onSuccess={() => {
                                            this.setState({ addFormView: false })
                                            this.getUsersList()
                                        }}></StudentForm>
                                    </>
                                }
                            </Card>
                        </div>
                    </Row>
                </Container>
             {  JSON.parse(localStorage.getItem('user')).role_type !== 'Teacher' &&
                   <Dialog open={this.state.updatePasswordView} onClose={() => this.toggleState('updatePasswordView')}>
                   <DialogTitle id='form-dialog-title'>Change Password</DialogTitle>
                   <DialogContent>
                       <DialogContentText>
                           Please enter new password to change.
                       </DialogContentText>
                       <FormGroup>
                           <InputGroup className='input-group-alternative mb-3'>
                               <InputGroupAddon addonType='prepend'>
                                   <InputGroupText>
                                       <i className='ni ni-hat-3' />
                                   </InputGroupText>
                               </InputGroupAddon>
                               <Input placeholder='Enter Password' type='text' name='newPassword1'
                                   value={this.state.newPassword1} onChange={this.handleInputChange.bind(this)} />
                           </InputGroup>
                       </FormGroup>

                       <FormGroup>
                           <InputGroup className='input-group-alternative mb-3'>
                               <InputGroupAddon addonType='prepend'>
                                   <InputGroupText>
                                       <i className='ni ni-hat-3' />
                                   </InputGroupText>
                               </InputGroupAddon>
                               <Input placeholder='Enter Password Again' type='text' name='newPassword2'
                                   value={this.state.newPassword2} onChange={this.handleInputChange.bind(this)} />
                           </InputGroup>
                       </FormGroup>
                   </DialogContent>
                   <DialogActions>
                       <Button onClick={() => this.toggleState('updatePasswordView')} color='primary'>
                           Cancel
                       </Button>
                       <Button onClick={this.submitChangePassword.bind(this)} color='primary'>
                           Submit
                       </Button>
                   </DialogActions>
               </Dialog>
             }


                <Dialog disableBackdropClick fullWidth
                    maxWidth="sm" open={this.state.discountModal} onClose={() => this.toggleState('discountModal')}>
                    {/* <DialogTitle id='form-dialog-title'>Add Amount</DialogTitle> */}
                    <h2 className='ml-3 mt-1'>Total Amount: {this.state.amount}</h2>
                    <DialogContent>
                        <FormGroup>
                            <Label>User Paid Amount</Label>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Total Amount' type='text' name='totalAmount'
                                    value={this.state.totalAmount} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>

                        <FormGroup>
                            <Label>Discount From Total Amount</Label>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Enter Discount Amount' type='text' name='discountAmount'
                                    value={this.state.discountAmount} onChange={this.handleInputChange.bind(this)} />
                            </InputGroup>
                        </FormGroup>
                    </DialogContent>
                    <DialogActions>
                        <Button onClick={() => this.handleAmountUpdate()} color='primary'>
                            Submit
                        </Button>
                    </DialogActions>
                </Dialog>

                {this.state.referralListShow &&
                    <Modal
                        className='modal-dialog-centered'
                        isOpen={this.state.referralListShow}
                        toggle={() => this.toggleState('referralListShow')}
                    >
                        <div className='modal-header'>
                            {/* <h4 className="modal-title" id="modal-title-default">
                  Referrals List
                </h4> */}
                            <Button
                                size='sm'
                                className='ml-auto'
                                color='primary'
                                data-dismiss='modal'
                                type='button'
                                onClick={() => this.toggleState('referralListShow')}
                            >
                                Close
                            </Button>
                        </div>
                        {/* <div className="modal-body"> */}
                        <MaterialTable
                            columns={[
                                { title: 'Full name', field: 'full_name' },
                                { title: 'Email', field: 'email' }
                            ]}
                            data={referralsList.user.referrals}
                            title='Referrals List'
                            options={{
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: '#6772E5',
                                    color: '#FFF'
                                }
                            }}
                        />
                        {/* </div> */}

                    </Modal>

                }
                {
                    this.state.detailsPanel &&
                    <StudentDetails studentData={this.state.selectedRow} closePanel={() => {
                        this.setState({ detailsPanel: false })
                    }}></StudentDetails>
                }
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.passwordModal}
                    toggle={() => this.toggleState('passwordModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Enter password to perform this action.</h4>
                        <Form onSubmit={
                            (e) => {
                                e.preventDefault()
                                Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                    this.handleCreateStudentOpen()
                                }).catch((error) => {
                                    this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                        variant: 'warning',
                                        anchorOrigin: {
                                            vertical: 'bottom',
                                            horizontal: 'right'
                                        }
                                    }
                                    )
                                })
                            }}>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('passwordModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        this.handleCreateStudentOpen()
                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })
                                }
                            }>
                            Enter
                        </Button>
                    </div>
                </Modal>
                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.confirmModal}
                    toggle={() => this.toggleState('confirmModal')}
                >
                    <div className='modal-header'>
                        <h5 className='modal-title' id='exampleModalLabel'>
                            Confirmation
                        </h5>
                        <button
                            aria-label='Close'
                            className='close'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            <span aria-hidden={true}>×</span>
                        </button>
                    </div>
                    <div className='modal-body'>
                        <h4>Are you sure you want to delete this user?</h4>
                        <Form>
                            <FormGroup>
                                <Input type='password' onChange={(e) => {
                                    this.setState({ masterPassword: e.target.value })
                                }} placeholder='Enter master password'></Input>
                            </FormGroup>
                        </Form>
                    </div>
                    <div className='modal-footer'>
                        <Button
                            color='secondary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('confirmModal')}
                        >
                            Cancel
                        </Button>
                        <Button color='primary' type='button'
                            onClick={
                                () => {
                                    let selectedRowId = this.state.selectedRow.id
                                    Services.verifyMasterPassword({ password: this.state.masterPassword }).then((response) => {
                                        Services.deleteStudent(selectedRowId).then((response) => {
                                            this.toggleState('confirmModal')
                                            this.getUsersList()
                                            this.props.enqueueSnackbar('Successfully deleted', {
                                                variant: 'success',
                                                anchorOrigin: {
                                                    vertical: 'bottom',
                                                    horizontal: 'right'
                                                }
                                            }
                                            )
                                        })
                                            .catch((error) => {
                                                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                                                    variant: 'warning',
                                                    anchorOrigin: {
                                                        vertical: 'bottom',
                                                        horizontal: 'right'
                                                    }
                                                }
                                                )
                                            })

                                    }).catch((error) => {
                                        this.props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                        )
                                    })
                                }
                            }>
                            Yes
                        </Button>
                    </div>
                </Modal>
                {this.state.paymentHistory &&
                    <PaymentStatus
                        paymentStatus={this.state.paymentHistory}
                        toggleState={this.toggleState}
                        studentData={this.state.studentData}
                        type={'table'}
                    />

                }
            </>
        )
    }
}

export default withSnackbar(Users)



