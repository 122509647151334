import React from 'react'
import 'image-upload-react/dist/index.css'
import '../../views/examples/Lessons.css'
import {
    Button,
    Col,
    Form,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label,
    Row
} from 'reactstrap'
import { withSnackbar } from 'notistack'
import { ContentState, convertFromHTML, convertToRaw, EditorState } from 'draft-js'
import { Editor } from 'react-draft-wysiwyg'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import draftToHtml from 'draftjs-to-html'
import htmlToDraft from 'html-to-draftjs'
import { customChunkRenderer } from 'draft-js-helpers'
import '@fortune-sheet/react/dist/index.css'

import { NotificationType } from '../../constants/NotificationType'

const Services = require('../../RemoteServices/RemoteServices.js')


class NotificationForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            notificationNumber:'',
            category: '',
            tags: '',
            tag: '',
            link_slug: '',
            link_image: '',
            lessonNo: '',
            title: '',
            description: '',
            videoLink: '',
            selectedOptionId: '',
            dropdownOptions: [],
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtmlCopied: '',
            imagePreview: '',
            image: '',
            remarks: [],
            uniqueId: '',
            editorContentHtml: '',
            showEditorCode: false,
            showAllButton: true,
            showText: false,
            showImage: false,
            showVideo: false,
            video: '',
            link: {
                tag: '',
                tags: '',
                lessonNo: '',
                title: '',
                description: '',
                videoLink: '',
                selectedOptionId: '',
                dropdownOptions: [],
                editorState: EditorState.createEmpty(),
                editorStateCopied: EditorState.createEmpty(),
                editorContentHtmlCopied: '',
                imagePreview: '',
                image: '',
                remarks: [],
                uniqueId: '',
                editorContentHtml: '',
                video: '',
                itemList: [{
                    showEditorCode: false,
                    editorState: EditorState.createEmpty(),
                    editorStateCopied: EditorState.createEmpty(),
                    editorContentHtmlCopied: '',
                    imagePreview: '',
                    editorContentHtml: '',
                    image: '',
                    description: '',
                    thumbnail: '',
                    video: '',
                    order: '',
                    orientation: '',
                    buttonLink: '',
                    buttonName: '',
                    subTitle: '',
                    imageBlob: '',
                    showAllButton: true,
                    addButton: true,
                    showText: false,
                    showImage: false,
                    showVideo: false,
                    showTable: false,
                    showSubTitle: false,
                    showQuiz: false,
                    quizData: '',
                    rawStr: '',
                    data: [],
                    tableArray: ''
                }]
            },
            selectedQuizId: null,
            notificationType:'Unpaid'
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.handlePaste = this.handlePaste.bind(this)
    }



    handleTableChange = (e, idx, rowIndex, colIndex) => {
        const newData = [...this.state.link.itemList]
        newData[idx]['tableArray'][rowIndex][colIndex] = e.target.value
        //console.log('asdfasfd', ...newData)
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...newData]
            }
        }))
    }

    tableRow = (data, idx) => (
        <table className={'mt-2 mb-2 table table-bordered table-responsive'}>
            {data.map((row, i) => (
                <tr style={{ width: 'auto' }} key={i}>
                    {row.map((col, j) => (
                        <td key={j}><input
                            style={{ border: 'none' }}
                            type='text'
                            value={col ? col : ''}
                            onChange={(e) => this.handleTableChange(e, idx, i, j)}
                        /></td>
                    ))}
                </tr>
            ))}
        </table>
    )

    countQuotes = (str) => {
        return str.split('"').length - 1
    }

    parseClip = (str) => {
        let r,
            rlen,
            rows,
            arr = [],
            a = 0,
            c,
            clen,
            multiline,
            last
        rows = str
            .split('\r\n')
            .reduce((acc, item) => acc.concat(item.split('\n')), [])
            .reduce((acc, item) => acc.concat(item.split('\r')), [])
        if (rows.length > 1 && rows[rows.length - 1] === '') {
            rows.pop()
        }
        for (r = 0, rlen = rows.length; r < rlen; r += 1) {
            rows[r] = rows[r].split('\t')
            for (c = 0, clen = rows[r].length; c < clen; c += 1) {
                if (!arr[a]) {
                    arr[a] = []
                }
                if (multiline && c === 0) {
                    last = arr[a].length - 1
                    arr[a][last] = arr[a][last] + '\n' + rows[r][0]
                    if (multiline && this.countQuotes(rows[r][0]) & 1) {
                        //& 1 is a bitwise way of performing mod 2
                        multiline = false
                        arr[a][last] = arr[a][last]
                            .substring(0, arr[a][last].length - 1)
                            .replace(/""/g, '"')
                    }
                } else {
                    if (
                        c === clen - 1 &&
                        rows[r][c].indexOf('"') === 0 &&
                        this.countQuotes(rows[r][c]) & 1
                    ) {
                        arr[a].push(rows[r][c].substring(1).replace(/""/g, '"'))
                        multiline = true
                    } else {
                        arr[a].push(rows[r][c].replace(/""/g, '"'))
                        multiline = false
                    }
                }
            }
            if (!multiline) {
                a += 1
            }
        }
        return arr
    }

    changeState = (state) => {
        this.setState((prevState) => ({
            ...prevState,
            [state]: !this.state[state]

        }))
    }


    addButtonHandler = (state, idx) => {
        let item = [...this.state.link.itemList]
        item[idx][state] = !this.state.link.itemList[idx][state]
        this.setState((prevState) => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...item]
            }
        }))

    }

    editTable = async (event, idx) => {
        if (event) {
            //console.log('asdfasdf', event)
            // let itemTransaction = [...this.state.link.itemList]
            // itemTransaction[idx]['tableData'] = event
            // await this.setState(prevState => ({
            //     ...prevState,
            //     link: {
            //         ...prevState.link,
            //         itemList: [...itemTransaction]
            //     }
            // }))
        }

    }

    addItem = async (idx) => {
        const { link } = this.state
        let item = {
            showEditorCode: false,
            editorState: EditorState.createEmpty(),
            editorStateCopied: EditorState.createEmpty(),
            editorContentHtmlCopied: '',
            imagePreview: '',
            editorContentHtml: '',
            image: '',
            description: '',
            video: '',
            order: '',
            orientation: '',
            buttonLink: '',
            buttonName: '',
            subTitle: '',
            thumbnail: '',
            imageBlob: '',
            paymentButtonName:'',
            paymentAmount:'',
            showAllButton: true,
            addButton: true,
            showText: false,
            showImage: false,
            showTable: false,
            showVideo: false,
            showButton: false,
            showSubTitle: false,
            showQuiz: false,
            quizData: '',
            rawStr: '',
            table: {
                rows: [],
                columns: []
            },
            data: '',
            tableArray: ''
        }
        await this.setState({
            showAllButton: true,
            link: {
                ...link,
                itemList: [...link.itemList, item]
            }

        })
        let itemUpdated = [...this.state.link.itemList]
        itemUpdated[idx]['addButton'] = false
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemUpdated]
            }
        }))

    }
    editItem = (event, idx, type) => {

        let name = event.target.name
        let value = event.target.value
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx][name] = value
        if (type === 'table') {
            itemTransaction[idx]['rawStr'] = event.target.value
            itemTransaction[idx]['data'] = this.parseClip(event.target.value)
            itemTransaction[idx]['tableArray'] = this.parseClip(event.target.value)
            this.setState({
                itemList: [...itemTransaction]
            })
        }
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))

    }
    removeItem = async (idx) => {
        if (idx > 0) {
            let item = [...this.state.link.itemList]
            item.splice(idx, 1)
            await this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: item
                }
            }))
            let itemUpdated = [...this.state.link.itemList]
            itemUpdated[idx - 1]['addButton'] = true
            await this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemUpdated]
                }
            }))
        } else if (idx === 0 && this.state.link.itemList.length >= 2) {
            let item = [...this.state.link.itemList]
            item.splice(idx, 1)
            await this.setState(prevState => ({
                ...prevState,
                showAllButton: true,
                link: {
                    ...prevState.link,
                    itemList: item
                }
            }))
        } else {
            let item = [...this.state.link.itemList]
            item.splice(idx, 1)
            await this.setState(prevState => ({
                ...prevState,
                showAllButton: true,
                link: {
                    ...prevState.link,
                    itemList: [{
                        image: '',
                        description: '',
                        video: '',
                        order: '',
                        orientation: '',
                        thumbnail: '',
                        showAllButton: true,
                        addButton: true,
                        tableData: '',
                        buttonLink: '',
                        buttonName: '',
                        subTitle: ''
                    }]
                }
            }))
        }
    }


    addTable = async (idx) => {
        const { itemList } = this.state.link
        let item = {
            rows: [],
            columns: []
        }
        await this.setState({
            showAllButton: true,
            itemList: {
                ...itemList,
                tableArray: [...itemList.tableArray, item]
            }

        })
    }
    editTable = (event, idx, type) => {

        let name = event.target.name
        let value = event.target.value
        let itemTransaction = [...this.state.link.itemList.tableArray]
        itemTransaction[idx][name] = value
        // if (type === 'table') {
        //     {console.log('asdfasdf',event.target.value)}
        //     itemTransaction[idx]['rawStr'] = event.target.value
        //     itemTransaction[idx]['data'] = this.parseClip(event.target.value)
        //     this.setState({
        //         itemList: [...itemTransaction]
        //     })
        // }
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))

    }
    removeTable = async (idx) => {
        let item = [...this.state.link.itemList.tableArray]
        item.splice(idx, 1)
        await this.setState(prevState => ({
            ...prevState,
            showAllButton: true,
            itemList: {
                ...prevState.itemList,
                tableArray: [{
                    rows: [],
                    column: []
                }]
            }
        }))
    }


    closeAllState = () => {
        this.setState((prevState) => ({
            ...prevState,
            showAllButton: false,
            showText: false,
            showImage: false,
            showVideo: false
        }))
    }

    handleSubmit() {
        let newData = {
            // description: this.state.editorContentHtml,
            title: this.state.title,
            category: this.state.notificationType,
            description: this.state.description,
            number:this.state.notificationNumber,
            objects:
                this.state.link.itemList.map((item, index) => (
                        //console.log(item),
                            item.editorContentHtml !== '' && item.video === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '' ?
                                {
                                    link: '',
                                    order: item.order || index + 1,
                                    orientation: item.orientation || 'left',
                                    text: item.editorContentHtml
                                } : item.video !== '' && item.editorContentHtml === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '' ? {
                                    link: '',
                                    youtube_link: item.video,
                                    order: item.order || index + 1,
                                    orientation: item.orientation || 'left',
                                    thumbnail: item.thumbnail ? item.thumbnail : null
                                } : item.image !== '' && item.editorContentHtml === '' && item.video === '' && item.buttonLink === '' && item.tableArray === '' ? {
                                    link: '',
                                    order: item.order || index + 1,
                                    orientation: item.orientation || 'left',
                                    image: item.image
                                } : item.buttonLink !== '' && item.editorContentHtml === '' && item.video === '' && item.image === '' && item.tableArray === '' && item.subTitle == '' ? {
                                    link: '',
                                    order: item.order || index + 1,
                                    orientation: item.orientation || 'left',
                                    button_link: item.buttonLink,
                                    button_name: item.buttonName
                                } : item.paymentButtonName !== '' && item.editorContentHtml === '' && item.video === '' && item.image === '' && item.subTitle == '' ? {
                                    link: '',
                                    order: item.order || index + 1,
                                    payment_button:item.paymentButtonName,
                                    orientation: item.orientation || 'left',
                                    amount: item.paymentAmount
                                } :{
                                    link: '',
                                    order: item.order || index + 1,
                                    orientation: item.orientation || 'left',
                                    title: item.subTitle
                                }
                    )
                )
        }

        Services.addNotification(newData).then((response) => {
            if (response.status === 200) {
                this.props.enqueueSnackbar('Notification Created Successfully', {
                    variant: 'success',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
                this.props.onSuccess(this.state.selectedOption)
            } else {
                this.props.enqueueSnackbar(response.msg, {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                })
            }
        })
            .catch((error) => {
                //console.log('asdfasdf', error)
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }

    componentDidMount() {
        // this.getCourseList()
    }

    handleTag = async (event) => {
        await this.setState(prevState => ({
            ...prevState,
            tags: event,
            tag: event.map(obj => obj).join(', ')
        }))
    }

    getCourseList() {
        Services.getCourses().then((response) => {
            this.setState({ courseList: response })
            this.populateSelectDropdown()
        })
    }

    populateSelectDropdown() {
        let data = this.state.courseList
        data.forEach((item) => {
            this.state.dropdownOptions.push({ value: item.id, label: item.title })
        })
    }

    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    handleChange = selectedOption => {
        this.setState(
            { selectedOptionId: selectedOption.value, selectedOption }
        )
    }

    onEditorStateChange = async (editorState, idx) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorContentHtml'] = draftToHtml(rawContentState)
        itemTransaction[idx]['editorState'] = editorState
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))

    }

    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')
        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }

    //Image selection and cases

    getExtension = (filename) => {
        var parts = filename.split('.')
        return parts[parts.length - 1]
    }

    isImage = (filename) => {
        var ext = this.getExtension(filename)
        switch (ext.toLowerCase()) {
            case 'jpg':
            case 'gif':
            case 'bmp':
            case 'png':
            case 'jpeg':
                //etc
                return true
        }
        return false
    }

    handleImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            // itemTransaction[idx]['image'] = e.target.files[0]
            // itemTransaction[idx]['imagePreview'] = URL.createObjectURL(e.target.files[0])
            // this.setState(prevState => ({
            //     ...prevState,
            //     link: {
            //         itemList: [...itemTransaction]
            //     }
            // }))
            // let reader = new FileReader()
            // reader.onloadend = function() {
            //     let data = (reader.result).split(',')[1]
            //     let binaryBlob = atob(data)
            //     itemTransaction[idx]['imageBlob'] = binaryBlob
            //     this.setState(prevState => ({
            //         ...prevState,
            //         link: {
            //             itemList: [...itemTransaction]
            //         }
            //     }))
            // }.bind(this)
            // reader.readAsDataURL(file)
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['image'] = response.data.link
                        itemTransaction[idx]['imagePreview'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageSelect = async (e) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        //console.log('asdf', response)
                        this.setState(prevState => ({
                            ...prevState,
                            link_image: response.data.link
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleLinkImageRemove = async (e) => {
        await this.setState(prevState => ({
            ...prevState,
            link_image: ''
        }))
    }

    handleYoutubeImageSelect = async (e, idx) => {
        let file = e.target.files[0]
        let filename = e.target.files[0].name
        if (this.isImage(filename)) {
            let itemTransaction = [...this.state.link.itemList]
            let token = localStorage.getItem('token')
            return new Promise(
                (resolve, reject) => {
                    const xhr = new XMLHttpRequest()
                    const serverUrl = window.config.apiUrl + 'common/image-upload'
                    xhr.open('POST', serverUrl)
                    xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                    const data = new FormData()
                    data.append('image', file)
                    xhr.send(data)
                    xhr.addEventListener('load', () => {
                        const response = JSON.parse(xhr.responseText)
                        resolve(response)
                        itemTransaction[idx]['thumbnail'] = response.data.link
                        this.setState(prevState => ({
                            ...prevState,
                            link: {
                                itemList: [...itemTransaction]
                            }
                        }))
                    })
                    xhr.addEventListener('error', () => {
                        const error = JSON.parse(xhr.responseText)
                        reject(error)
                    })
                }
            )

        } else {
            this.props.enqueueSnackbar('Please choose a correct image format', {
                variant: 'warning',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
        }

    }
    handleYoutubeImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['thumbnail'] = ''
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }


    handleImageRemove = async (e, idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['imagePreview'] = ''
        itemTransaction[idx]['image'] = ''
        await this.setState(prevState => ({
            ...prevState,
            link: {
                itemList: [...itemTransaction]
            }
        }))
    }


    handleRemarksOptionChange = (optionArray) => {
        this.setState({ remarks: optionArray })
    }


    handlePaste = () => {
        this.setState({
            editorState: this.props.editorState,
            editorContentHtml: this.props.editorContentHtml
        })
    }

    onEditEditorHTML = (idx, e) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorContentHtml'] = e.target.value
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ editorContentHtml: e.target.value })
    }
    toggleEditorCode = async (idx) => {
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['showEditorCode'] = !itemTransaction[idx].showEditorCode
        await this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))
        // this.setState({ showEditorCode: !this.state.showEditorCode })
    }
    returnHtmlBlock = (text) => {
        if (this.state.lessonViewType === true) {
            const processedHTML = htmlToDraft(text, customChunkRenderer)
            return ContentState.createFromBlockArray(processedHTML)
        } else {
            const processedHTML = convertFromHTML(text)
            return ContentState.createFromBlockArray(processedHTML)
        }
    }
    addHtmlToEditor = (idx) => {
        const { editorContentHtml } = this.state.link.itemList[idx]
        let editorState
        if (editorContentHtml.trim() !== '') {
            editorState = EditorState.createWithContent(this.returnHtmlBlock(editorContentHtml))
        } else {
            editorState = EditorState.createEmpty()
        }
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['editorState'] = editorState
        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))

        // this.setState({ editorState })
    }

    getUploadParams = ({ meta }) => {
        return { url: 'https://httpbin.org/post' }
    }

    handleChangeStatus(meta, file, idx) {
        // this.setState({image:file})
        let reader = new FileReader()
        reader.onloadend = function() {
            // //console.log('RESULT', reader.result)
            // this.setState({ image: reader.result })
            let itemTransaction = [...this.state.link.itemList]
            itemTransaction[idx]['image'] = reader.result
            this.setState(prevState => ({
                ...prevState,
                link: {
                    ...prevState.link,
                    itemList: [...itemTransaction]
                }
            }))
        }.bind(this)
        reader.readAsDataURL(file)
        //  //console.log(this.state)

    }

    handleQuizArray = (idx, quizData) => {
        //console.log('quizData',quizData)
        let itemTransaction = [...this.state.link.itemList]
        itemTransaction[idx]['quizData'] = quizData

        this.setState(prevState => ({
            ...prevState,
            link: {
                ...prevState.link,
                itemList: [...itemTransaction]
            }
        }))

    }

    setNotificationType = (item) => {
        this.setState({ notificationType: item })
    }


    render() {
     
        return (
            <>
                <div style={{
                    width: '100%',
                    justifyContent: 'center',
                    marginBottom: '30px'
                }}>
                    <div style={{
                        display: 'flex',
                        justifyContent: 'center',
                        gap: '10px'
                    }}>
                        {NotificationType.map((item, idx) => {
                            return (
                                <div key={idx} style={{
                                    display: 'flex',
                                    flexDirection: 'row',
                                    cursor: 'pointer'
                                }}>
                                    <div
                                        onClick={() => this.setNotificationType(item.label)}
                                        style={{
                                            width:'90px',
                                            alignItems:'center',
                                            justifyContent:'center',
                                            borderRadius: '5px',
                                            display: 'flex',
                                            flexDirection: 'row',
                                            padding: '5px',
                                            boxShadow: '1px 1px 0px 0px grey',
                                            border: this.state.notificationType === item.label ? '2px solid purple' : '1px solid grey'
                                        }}>
                                        {item.label}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
                <Form encType='multipart/form-data' role='form' style={{
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <div style={{ display: 'flex', justifyContent: 'flex-end' }}>
                        {this.props.copyStatus &&
                            <Button type='button' color='secondary' onClick={() => this.handlePaste()}>Paste
                                Data</Button>}
                    </div>
                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%', gap: '20px' }}>
                        <FormGroup style={{
                            marginBottom: '0px',
                            display: 'flex',
                            width: '60%',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <Label><span className={'font-weight-bold'}> Notification Title</span></Label>
                            <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                    <InputGroupText>
                                        <i className='ni ni-hat-3' />
                                    </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Notification Title' type='text' name='title'
                                       value={this.state.title}
                                       onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                        <FormGroup style={{
                            marginBottom: '0px',
                            display: 'flex',
                            width: '40%',
                            flexDirection: 'column',
                            justifyContent: 'space-between'
                        }}>
                            <Label><span className={'font-weight-bold'}> Notification Number</span></Label>
                            <InputGroup className='input-group-alternative mb-3'>
                                <Input placeholder='Enter Notification Number' type='text' name='notificationNumber'
                                       value={this.state.notificationNumber}
                                       onChange={this.handleInputChange} />
                            </InputGroup>
                        </FormGroup>
                    </div>
                    <FormGroup style={{ display: 'flex', flexDirection: 'column', justifyContent: 'space-between' }}>
                        <Label><span className={'font-weight-bold'}> Description</span></Label>
                        <InputGroup className='input-group-alternative'>
                            <Input rows={'3'} type='textarea' placeholder='Notification Description' name='description'
                                   value={this.state.description}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    {!this.state.showAllButton &&
                        <Row>
                            <Col xl={12}>
                                <Button onClick={() => this.addItem()}
                                        className={'mb-2 btn'}><i
                                    className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                            </Col>
                        </Row>
                    }
                    {this.state.link.itemList && this.state.link.itemList.map((item, idx) => (
                        <div>
                            {this.state?.link?.itemList[idx]?.showSubTitle &&
                                <>
                                    <h4>Title</h4>
                                    <FormGroup className={'mt-3 mb-0'}
                                               style={{
                                                   display: 'flex',
                                                   width: '100%',
                                                   height: 'auto',
                                                   justifyContent: 'space-between'
                                               }}>
                                        <InputGroup className='input-group-alternative'>
                                            <Input type='textarea' placeholder='Title'
                                                   rows={'3'}
                                                   name='subTitle'
                                                   value={item.subTitle}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup style={{
                                        display: 'flex',
                                        width: '50%',
                                        justifyContent: 'space-between',
                                        marginTop: '5px',
                                        gap: '10px'
                                    }}>
                                        <InputGroup
                                            className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Order' type='text'
                                                   name='order' value={item.order}
                                                   onChange={(event) => this.editItem(event, idx)} />

                                        </InputGroup>
                                        <Input type={'select'}
                                               name={'orientation'}
                                               value={item.orientation}
                                               onChange={(event) => this.editItem(event, idx)}>
                                            <option>Select Orientation</option>
                                            <option value={'left'}>Left</option>
                                            <option value={'center'}>Center</option>
                                            <option value={'right'}>Right</option>
                                        </Input>

                                    </FormGroup>
                                </>
                            }
                            {this.state.link.itemList[idx].showText &&
                                <div className={'mt-3'}>
                                    <h4>Text</h4>
                                    <Editor
                                        key={idx}
                                        handlePastedText={() => false}
                                        editorStyle={{
                                            border: '1px solid black',
                                            padding: '5px',
                                            borderRadius: '2px',
                                            minHeight: '300px',
                                            height: 'auto',
                                            width: '100%',
                                            fontSize: '18px',
                                        }}
                                        placeholder='Description / Notes  [NEW Layout]'
                                        editorState={item.editorState}
                                        wrapperClassName='demo-wrapper'
                                        editorClassName='demo-editor'
                                        onEditorStateChange={(editorState) => this.onEditorStateChange(editorState, idx)}
                                        toolbar={{
                                            inline: { inDropdown: true },
                                            list: { inDropdown: true },
                                            textAlign: { inDropdown: true },
                                            link: { inDropdown: true },
                                            history: { inDropdown: true },
                                            image: {
                                                uploadCallback: (file) => this.uploadImageCallBack(file),
                                                previewImage: true,
                                                defaultSize: { width: '100%', height: 'auto' }
                                            }
                                        }}
                                    />
                                    <FormGroup className={'mt-1'} style={{
                                        display: 'flex',
                                        width: '50%',
                                        justifyContent: 'space-between'
                                    }}>
                                        <InputGroup className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Order' type='text' name='order' value={item.order}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                        <Input className={'ml-2'} type={'select'} name={'orientation'}
                                               value={item.orientation} onChange={(event) => this.editItem(event, idx)}>
                                            <option>Select Orientation</option>
                                            <option value={'left'}>Left</option>
                                            <option value={'center'}>Center</option>
                                            <option value={'right'}>Right</option>
                                        </Input>

                                    </FormGroup>

                                </div>
                            }
                            {this.state?.link?.itemList[idx].showImage &&
                                <>
                                    <div>
                                        <FormGroup className={'mb-0'}>
                                            <h4>Upload Image</h4>
                                            <input className='thumbnail_upload_button' accept='png/jpeg' type='file'
                                                   onChange={(e) => this.handleImageSelect(e, idx)} />
                                            <br />
                                            {item.imagePreview && <div className='thumbnailContainerAddLesson'><i
                                                onClick={(e) => this.handleImageRemove(e, idx)}
                                                class='far fa-times-circle'></i> <img
                                                className='lessonFormThumbnailImage' src={item.imagePreview} alt='' />
                                            </div>}

                                        </FormGroup>
                                        <FormGroup style={{
                                            display: 'flex',
                                            width: '50%',
                                            justifyContent: 'space-between'
                                        }}>
                                            <InputGroup className='input-group-alternative mb-3'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-hat-3' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder='Order' type='text' name='order'
                                                       value={item.order}
                                                       onChange={(event) => this.editItem(event, idx)} />
                                            </InputGroup>
                                        </FormGroup>
                                    </div>
                                </>
                            }

                            {this.state?.link?.itemList[idx]?.showVideo &&
                                <>
                                    <FormGroup className={'mt-3 mb-0'}
                                               style={{ display: 'flex', justifyContent: 'space-between' }}>
                                        <InputGroup className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Video Link' type='text' name='video'
                                                   value={item.video}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup>
                                        <h4>Upload Thumbnail Image</h4>
                                        <input className='thumbnail_upload_button' accept='png/jpeg'
                                               type='file'
                                               onChange={(e) => this.handleYoutubeImageSelect(e, idx)} />
                                        <br />
                                        {item.thumbnail &&
                                            <div className='thumbnailContainerAddLesson'><i
                                                onClick={(e) => this.handleYoutubeImageRemove(e, idx)}
                                                class='far fa-times-circle'></i> <img
                                                className='lessonFormThumbnailImage'
                                                src={item.thumbnail}  alt=''/>
                                            </div>}

                                    </FormGroup>


                                    <FormGroup style={{
                                        display: 'flex',
                                        width: '50%',
                                        justifyContent: 'space-between',
                                        marginTop: '0px'
                                    }}>
                                        <InputGroup className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Order' type='text' name='order' value={item.order}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>
                                </>
                            }

                            {this.state?.link?.itemList[idx]?.showButton &&
                                <>
                                    <h4>Button Link</h4>
                                    <FormGroup className={'mt-3 mb-0'}
                                               style={{
                                                   width: '100%',
                                                   display: 'flex',
                                                   justifyContent: 'space-between',
                                                   gap: '10px'
                                               }}>
                                        <div style={{ display: 'flex', width: '50%' }}>
                                            <InputGroup
                                                className='input-group-alternative mb-3'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-hat-3' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder='Button Name'
                                                       type='text'
                                                       name='buttonName'
                                                       value={item.buttonName}
                                                       onChange={(event) => this.editItem(event, idx)} />
                                            </InputGroup>
                                        </div>
                                        <InputGroup
                                            className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Button Link' type='text'
                                                   name='buttonLink'
                                                   value={item.buttonLink}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup style={{
                                        display: 'flex',
                                        width: '50%',
                                        justifyContent: 'space-between',
                                        marginTop: '0px',
                                        gap: '10px'
                                    }}>
                                        <InputGroup
                                            className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Order' type='text'
                                                   name='order' value={item.order}
                                                   onChange={(event) => this.editItem(event, idx)} />

                                        </InputGroup>
                                        <Input type={'select'}
                                               name={'orientation'}
                                               value={item.orientation}
                                               onChange={(event) => this.editItem(event, idx)}>
                                            <option>Select Orientation</option>
                                            <option value={'left'}>Left</option>
                                            <option value={'center'}>Center</option>
                                            <option value={'right'}>Right</option>
                                        </Input>

                                    </FormGroup>
                                </>
                            }
                            {this.state?.link?.itemList[idx]?.showTable &&
                                <>
                                    <h4>Payment</h4>
                                    <FormGroup className={'mt-3 mb-0'}
                                               style={{
                                                   width: '100%',
                                                   display: 'flex',
                                                   justifyContent: 'space-between',
                                                   gap: '10px'
                                               }}>
                                        <div style={{ display: 'flex', width: '100%' }}>
                                            <InputGroup
                                                className='input-group-alternative mb-3'>
                                                <InputGroupAddon addonType='prepend'>
                                                    <InputGroupText>
                                                        <i className='ni ni-hat-3' />
                                                    </InputGroupText>
                                                </InputGroupAddon>
                                                <Input placeholder='Payment Button Name'
                                                       type='text'
                                                       name='paymentButtonName'
                                                       value={item.paymentButtonName}
                                                       onChange={(event) => this.editItem(event, idx)} />
                                            </InputGroup>
                                        </div>
                                        <InputGroup
                                            className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Payment Amount' type='text'
                                                   name='paymentAmount'
                                                   value={item.paymentAmount}
                                                   onChange={(event) => this.editItem(event, idx)} />
                                        </InputGroup>
                                    </FormGroup>

                                    <FormGroup style={{
                                        display: 'flex',
                                        width: '50%',
                                        justifyContent: 'space-between',
                                        marginTop: '0px',
                                        gap: '10px'
                                    }}>
                                        <InputGroup
                                            className='input-group-alternative mb-3'>
                                            <InputGroupAddon addonType='prepend'>
                                                <InputGroupText>
                                                    <i className='ni ni-hat-3' />
                                                </InputGroupText>
                                            </InputGroupAddon>
                                            <Input placeholder='Order' type='text'
                                                   name='order' value={item.order}
                                                   onChange={(event) => this.editItem(event, idx)} />

                                        </InputGroup>
                                        <Input type={'select'}
                                               name={'orientation'}
                                               value={item.orientation}
                                               onChange={(event) => this.editItem(event, idx)}>
                                            <option>Select Orientation</option>
                                            <option value={'left'}>Left</option>
                                            <option value={'center'}>Center</option>
                                            <option value={'right'}>Right</option>
                                        </Input>

                                    </FormGroup>
                                </>
                            }

                            <Row key={idx}>
                                {this.state?.link?.itemList[idx]?.showAllButton &&
                                    <>
                                        <Col xl={12} className={'mb-2'}>

                                            {!this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                <Button onClick={() => this.addButtonHandler('showSubTitle', idx)}
                                                        className={'btn btn-primary'}><i
                                                    className={'fa fa-plus'} />&nbsp;&nbsp; Add Title</Button>
                                            }
                                            {this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showQuiz && !this.state.link.itemList[idx].showText && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start'
                                                }}>
                                                    {!this.state.link.itemList.length <= 1 &&
                                                        <>
                                                            <Button onClick={() => {
                                                                this.addButtonHandler('showSubTitle', idx)
                                                                this.removeItem(idx)
                                                            }}
                                                                    style={{ backgroundColor: 'red', color: 'white' }}
                                                                    className={'btn'}><i
                                                                className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                        </>
                                                    }
                                                    {this.state.link.itemList[idx].addButton &&
                                                        <Button onClick={() => this.addItem(idx)}
                                                                className={'btn'}><i
                                                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                    }
                                                </div>
                                            }

                                            {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                <Button onClick={() => this.addButtonHandler('showText', idx)}
                                                        className={'btn btn-primary'}><i
                                                    className={'fa fa-plus'} />&nbsp;&nbsp; Add Text</Button>
                                            }
                                            {this.state.link.itemList[idx].showText && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start'
                                                }}>
                                                    {!this.state.link.itemList.length <= 1 &&
                                                        <>
                                                            <Button onClick={() => {
                                                                this.addButtonHandler('showText', idx)
                                                                this.removeItem(idx)
                                                            }}
                                                                    style={{ backgroundColor: 'red', color: 'white' }}
                                                                    className={'btn'}><i
                                                                className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                        </>
                                                    }
                                                    {this.state.link.itemList[idx].addButton &&
                                                        <Button onClick={() => this.addItem(idx)}
                                                                className={'btn'}><i
                                                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                    }
                                                </div>
                                            }

                                            {!this.state?.link?.itemList[idx]?.showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showVideo &&
                                                <Button onClick={() => this.addButtonHandler('showImage', idx)}
                                                        className={'btn btn-primary'}><i
                                                    className={'fa fa-plus'} />&nbsp;&nbsp; Add Image</Button>

                                            }
                                            {this.state.link.itemList[idx].showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showVideo &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start'
                                                }}>
                                                    <Button onClick={() => {
                                                        this.addButtonHandler('showImage', idx)
                                                        this.removeItem(idx)
                                                    }}
                                                            className={'btn'}
                                                            style={{ backgroundColor: 'red', color: 'white' }}><i
                                                        className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                    {this.state.link.itemList[idx].addButton &&
                                                        <Button onClick={() => this.addItem(idx)}
                                                                className={'btn'}><i
                                                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                    }
                                                </div>
                                            }


                                            {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showVideo && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                                <Button onClick={() => this.addButtonHandler('showVideo', idx)}
                                                        className={'btn btn-primary'}><i
                                                    className={'fa fa-plus'} />&nbsp;&nbsp; Add Video</Button>
                                            }
                                            {this.state.link.itemList[idx].showVideo && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start'
                                                }}>
                                                    <Button onClick={() => {
                                                        this.addButtonHandler('showVideo', idx)
                                                        this.removeItem(idx)
                                                    }}
                                                            className={'btn'}
                                                            style={{ backgroundColor: 'red', color: 'white' }}><i
                                                        className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                    {this.state.link.itemList[idx].addButton &&
                                                        <Button onClick={() => this.addItem(idx)}
                                                                className={'btn'}><i
                                                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                    }
                                                </div>

                                            }

                                            {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showTable &&
                                                <Button onClick={() => this.addButtonHandler('showButton', idx)}
                                                        className={'btn btn-primary'}><i
                                                    className={'fa fa-plus'} />&nbsp;&nbsp; Add Button</Button>
                                            }
                                            {this.state.link.itemList[idx].showButton && !this.state?.link?.itemList[idx]?.showQuiz && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start'
                                                }}>
                                                    <Button onClick={() => {
                                                        this.addButtonHandler('showButton', idx)
                                                        this.removeItem(idx)
                                                    }}
                                                            className={'btn'}
                                                            style={{ backgroundColor: 'red', color: 'white' }}><i
                                                        className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                    {this.state.link.itemList[idx].addButton &&
                                                        <Button onClick={() => this.addItem(idx)}
                                                                className={'btn'}><i
                                                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                    }
                                                </div>
                                            }

                                            {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                                <Button onClick={() => this.addButtonHandler('showTable', idx)}
                                                        className={'btn btn-primary'}><i
                                                    className={'fa fa-plus'} />&nbsp;&nbsp; Add Payment</Button>
                                            }
                                            {this.state.link.itemList[idx].showTable && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                                <div style={{
                                                    display: 'flex',
                                                    alignItems: 'flex-start'
                                                }}>
                                                    <Button onClick={() => {
                                                        this.addButtonHandler('showTable', idx)
                                                        this.removeItem(idx)
                                                    }}
                                                            className={'btn'}
                                                            style={{ backgroundColor: 'red', color: 'white' }}><i
                                                        className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>
                                                    {this.state.link.itemList[idx].addButton &&
                                                        <Button onClick={() => this.addItem(idx)}
                                                                className={'btn'}><i
                                                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                                                    }
                                                </div>

                                            }

                                            {/*{!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showTable &&*/}
                                            {/*    <Button onClick={() => this.addButtonHandler('showQuiz', idx)}*/}
                                            {/*            className={'btn btn-primary'}><i*/}
                                            {/*        className={'fa fa-plus'} />&nbsp;&nbsp; Add Quiz</Button>*/}
                                            {/*}*/}
                                            {/*{this.state.link.itemList[idx].showQuiz && !this.state.link.itemList[idx].showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&*/}
                                            {/*    <div style={{*/}
                                            {/*        display: 'flex',*/}
                                            {/*        alignItems: 'flex-start'*/}
                                            {/*    }}>*/}
                                            {/*        <Button onClick={() => {*/}
                                            {/*            this.addButtonHandler('showQuiz', idx)*/}
                                            {/*            this.removeItem(idx)*/}
                                            {/*        }}*/}
                                            {/*                className={'btn'}*/}
                                            {/*                style={{ backgroundColor: 'red', color: 'white' }}><i*/}
                                            {/*            className={'fa fa-minus'} />&nbsp;&nbsp; Close</Button>*/}
                                            {/*        {this.state.link.itemList[idx].addButton &&*/}
                                            {/*            <Button onClick={() => this.addItem(idx)}*/}
                                            {/*                    className={'btn'}><i*/}
                                            {/*                className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>*/}
                                            {/*        }*/}
                                            {/*    </div>*/}
                                            {/*}*/}

                                        </Col>
                                    </>
                                }
                            </Row>
                        </div>
                    ))}
                    <div className='pb-4'>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}


export default withSnackbar(NotificationForm)
