import React, {  useEffect, useMemo, useState } from 'react'
import { Button, Spinner } from 'reactstrap'
import Avatar from '../../assets/img/dummyUser.png'
import './referralDetails.scss'
import moment from 'moment'
import { ReferralDownlineTable } from './ReferralDownlineTable'
import { ReferralTree } from './ReferralTree'

const Services = require('../../RemoteServices/RemoteServices.js')


const UserProfilePage = (props) => {

    let [userDetails, setUserDetails] = useState({})
    let [referralListModal, setReferralListModal] = useState(false)
    let [followListModal, setFollowListModal] = useState(false)
    let [followTableTitle, setFollowTableTitle] = useState('')
    let [followList, setFollowList] = useState([])
    let [pageLoading, setPageLoading] = useState(true)
    let [detailResponse, setDetailResponse] = useState([])
    const [hierarchyList, setHierarchyList] = useState([])
    let { userId, oldId } = props
    const [chart, setChart] = useState(false)
    const [walletDetail, setWalletDetail] = useState([])


    const toggleReferralModal = () => {
        setReferralListModal(!referralListModal)
    }

    const toggleFollowModal = () => {
        setFollowListModal(!followListModal)
    }

    const getHierarchy = useMemo(() => (count) => {
        let requestData = {
            downline_count: count
        }
        Services.postHierarchy(oldId, count && requestData).then((response) => {
            setHierarchyList(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [oldId]);

    useEffect(() => {
        getUserDetails();
        getWalletDetails();
        getHierarchy();
    }, [])

    const getWalletDetails = () => {
        Services.getStudentWallet(oldId).then((response) => {
            setWalletDetail(response)
        })
    }


    const getUserDetails = () => {
        Services.getUserDetail(oldId).then((response) => {
            setUserDetails(response)
            setDetailResponse(response)
            setPageLoading(false)

        })
    }

    const getFollowers = () => {
        Services.getUserFollowers(userId).then((response) => {
            console.log(response.results)
            setFollowList(response.results)
        })
    }

    const getFollowing = () => {
        Services.getUserFollowing(userId).then((response) => {
            setFollowList(response.results)

        })
    }


    const handleGetFollowers = () => {
        getFollowers()
        toggleFollowModal()
        setFollowTableTitle('Followers')
    }

    const handleGetFollowing = () => {
        getFollowing()
        toggleFollowModal()
        setFollowTableTitle('Following')
    }

    let user = userDetails
    let data = userDetails
    console.log('refuser',user)
    console.log('refdata',data)


    if (pageLoading === true || detailResponse.length === 0) {
        return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '20px' }}>
            <Spinner /></div>)
    }

    return (
        <div style={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', padding: 10 }}>
            <div className='studentDetailsTopContainer'>
                <div className='studentDetailsImageContainer'>
                <a href={user.profile_picture}   target='_blank' rel='noopener noreferrer' style={{ position: 'relative', display: 'inline-block', width: 100, height: 100 }}> {/* Adjust width and height as needed */}
                    <img
                        className="studentDetailImage"
                        alt="student avatar"
                        src={user.profile_picture ? user.profile_picture : Avatar}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'fill',
                            borderRadius: '50%'
                        }}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            backgroundColor: user.is_online ? 'green' : 'lightgrey',
                            border: '2px solid white'
                        }}
                    />
                </a>
                {user?.is_online ? ''
                            :
                                <span style={{ color: 'white', fontSize: 14 }}>Offline: {moment(user?.last_online).fromNow()}</span>
                            }
                </div>
                <div className='studentDetailsTopInfoContainer'>
                    <div className='studentDetailsTopInfoInnerContainer1'>
                        <ul>
                            <li><i class='far fa-calendar'></i> Name: <span> {user.full_name||'N/A'} </span></li>
                            <li><i class='far fa-calendar'></i> Date of Birth: <span> {user.dob||'N/A'} </span></li>
                            <li><i class='fas fa-venus-mars'></i> Gender : <span> {user.gender||'N/A'} </span></li>
                            <li><i class='far fa-calendar-plus'></i> Joined
                                : <span> {moment(user.created_at).format('MM/DD/YYYY')} </span></li>
                            <li><i class='fas fa-home'></i> From : <span> {user.address||'N/A'} </span></li>

                        </ul>
                        <ul>
                            <li><i class='fas fa-phone-square-alt'></i> Phone : <span> {user.phone_number} </span></li>
                            <li><i class='fas fa-receipt'></i> Roll Number : <span> {data.id||'N/A'} </span></li>
                            <li> <i class="fas fa-envelope"></i> Email: <span> {user.email||'N/A'}
                                &nbsp;{user?.email_verified && <i style={{ color: 'white',fontSize:18 }} className={' fa fa-check-circle'}></i>}
                            </span> </li>                            <li><i class='fab fa-centercode'></i> Referral Code : <span> {user.referral_code||'N/A'} </span>
                            </li>
                            <li><i class='fas fa-id-card-alt'></i> Referred Count : <span> {user.referred_count||'N/A'} </span>
                            </li>

                        </ul>
                        <ul>
                            <li><i class='fas fa-money-check-alt'></i> Remaining Balance
                                : <span>Rs. {(walletDetail.total_earning - walletDetail.total_withdrawal).toFixed(2)||0.00} </span></li>
                            <li><i class='fas fa-money-check-alt'></i> Delivered amount
                                : <span> Rs. {walletDetail.total_withdrawal||0.00} </span></li>
                            <li><i class='fas fa-money-check-alt'></i> Earned Amount
                                : <span> Rs. {walletDetail.total_earning||0.00} </span></li>
                        </ul>
                    </div>
                    <div style={{ marginLeft: '35px', display: 'flex', flexDirection: 'row', width: '100%' }}>
                        <Button className={'p-1'} style={{ width: '100%', backgroundColor: 'lightgrey' }}>Referred By:
                            {'Backend Parameter Left'}</Button>
                    </div>


                </div>
            </div>
            <div style={{
                display: 'flex',
                flexDirection: 'column',
                borderRadius: '10px',
                backgroundColor: 'lightgrey',
                marginTop: '15px',
                padding: '15px',
                alignItems: 'center'
            }}>
                <div style={{
                    display: 'flex',
                    flexDirection: 'row',
                    justifyContent: 'flex-start',
                    borderRadius: '10px',
                    backgroundColor: 'lightgrey',
                    flexWrap: 'wrap',
                    gap: '32px'
                }}>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Total Earning</h3>
                        <span>Rs.{walletDetail.total_earning || 0}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Total Withdrawal</h3>
                        <span>Rs. {walletDetail.total_widthdrawal || 0}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Remaining Balance</h3>
                        <span>Rs.{walletDetail.total_earning - walletDetail.total_withdrawal}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Total Referred</h3>
                        <span>{hierarchyList?.downline_all_count || 0}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Paid Referral</h3>
                        <span>{hierarchyList.downline_all_paid_count}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Pending Referral</h3>
                        <span>{hierarchyList.downline_all_count - hierarchyList.downline_all_paid_count}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Total Downline</h3>
                        <span>{hierarchyList?.total_depth_of_downline || 0}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Referral Ranking</h3>
                        <span>{hierarchyList?.ranked || 'N/A'}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Downline 1 Earning</h3>
                        <span>Rs. {hierarchyList.downline_1_earning || 0}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Downline 2 Earning</h3>
                        <span>Rs. {hierarchyList.downline_2_earning || 0}</span>
                    </div>
                    <div className={'card font-weight-bold p-4 align-items-center'}>
                        <h3>Downline 3 Earning</h3>
                        <span>Rs. {hierarchyList.downline_3_earning || 0}</span>
                    </div>
                </div>
                {/*<div style={{*/}
                {/*    display: 'flex',*/}
                {/*    flexDirection: 'row',*/}
                {/*    width: '90%',*/}
                {/*    justifyContent: 'center',*/}
                {/*    backgroundColor: 'indigo',*/}
                {/*    borderRadius: '10px',*/}
                {/*    padding: '5px',*/}
                {/*    marginTop: '30px'*/}
                {/*}}>*/}
                {/*    <div style={{ color: 'white', display: 'flex', gap: '5px', alignItems: 'center' }}>*/}
                {/*        <span>User wants to withdraw his amount?</span>&nbsp;&nbsp;*/}
                {/*        <Button className={'btn btn-outline-primary'}*/}
                {/*                style={{ color: 'white', padding: '2px', width: '80px' }}>Approve</Button>*/}
                {/*        <Button className={'btn btn-outline-danger'}*/}
                {/*                style={{ color: 'white', padding: '2px', width: '80px' }}>Deny</Button>*/}
                {/*    </div>*/}
                {/*</div>*/}
            </div>

            <div className={'referral-tab'}>
                <button onClick={() => setChart(!chart)} className={`tab-button ${!chart ? 'active' : ''}`}>Downline
                </button>
                <button onClick={() => {
                    setChart(!chart)
                }} className={`tab-button ${chart ? 'active' : ''}`}>Chart
                </button>
            </div>
            {!chart ?
                <ReferralDownlineTable id={oldId} data={hierarchyList} />
                :
                <ReferralTree data={hierarchyList} />
            }


        </div>
    )
}

export default UserProfilePage