import React from 'react'
import {
    Button,
  
    FormGroup,
    Form,
    Input,
    InputGroupAddon,
    InputGroupText,
    InputGroup,
   
} from 'reactstrap'
import { withSnackbar } from 'notistack'


const Services = require('../../RemoteServices/RemoteServices.js')


class CoursesForm extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            title: '',
            description: ''
        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
    }

    handleSubmit() {
        let courseData = {
            title: this.state.title,
            description: this.state.description
        }
        Services.createCourse(courseData).then((response) => {
            this.props.enqueueSnackbar('Course Created Successfully', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })

            this.props.onSuccess()
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                            vertical: 'bottom',
                            horizontal: 'right'
                        }
                    }
                )
            })
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }

    render() {
        return (
            <>
                <Form role='form' style={{
                    paddingLeft: 20,
                    paddingRight: 20
                }}>
                    <div className={'d-flex flex-row-reverse'}>
                        {this.state.title.length > 0 && <span style={{ fontSize: 18 }}
                                                              className={'font-weight-bold'}>{this.state.title.length}/200</span>}
                    </div>
                    <FormGroup>
                        <InputGroup className='input-group-alternative mb-3'>
                            <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                </InputGroupText>
                            </InputGroupAddon>
                            <Input placeholder='Course Name' type='text' name='title' value={this.state.title}
                                   onChange={this.handleInputChange} />
                        </InputGroup>
                    </FormGroup>
                    <div className={'d-flex flex-row-reverse'}>
                        {this.state.description.length > 0 && <span style={{ fontSize: 18 }}
                                                                    className={'font-weight-bold'}>{this.state.description.length}/500</span>}
                    </div>
                    <FormGroup>
                        <InputGroup className='input-group-alternative'>
                            <Input
                                id='exampleFormControlTextarea1'
                                placeholder='Description / Notes'
                                rows='3'
                                type='textarea'
                                name='description'
                                value={this.state.description}
                                onChange={this.handleInputChange}
                            />
                        </InputGroup>
                    </FormGroup>
                    <div className='pb-4'>
                        <Button className='mt-4' color='primary' type='button' onClick={this.handleSubmit}>
                            Create
                        </Button>
                    </div>
                </Form>
            </>
        )
    }
}

export default withSnackbar(CoursesForm)