import React, { useState} from 'react'
import './CommentCard.css'
import Avatar from '../../assets/img/theme/avatar.png'
import moment from 'moment'
import { withSnackbar } from 'notistack'
import { Button, Modal } from 'reactstrap'
import { FormatImage, FormatThumbnailImage } from 'utils/ImageFormat'

const Services = require('../../RemoteServices/RemoteServices.js')

const CommentCard = (props) => {
    const { commentData, setCurrentCommentData, fetchComments, showComments } = props
    let [showReply, setShowReply] = useState(false)
    let [replyId, setReplyId] = useState('')
    let [confirmModal, setConfirmModal] = useState(false)
    //console.log(commentData)


    const handleApproveDeny = (type) => {
        setCurrentCommentData(commentData.id, commentData.message, type, commentData.user.id, commentData.lesson)
    }

    const handleModal = () => {
        setConfirmModal(!confirmModal)
    }

    const handleDeleteReply = () => {
        Services.deleteCommentReply(replyId).then((response) => {
            props.enqueueSnackbar('Reply deleted', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }
            )
            fetchComments(showComments === 'review' ? false : true)
            handleModal()

        }).catch((error) => {
            props.enqueueSnackbar('Something went wrong. Please try again.', {
                variant: 'error',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            }
            )
        })
    }


    if (commentData === undefined) {
        return ''
    }

    const convertDate = (date) => {
        const add = moment(date, 'YYYY-MM-DDTHH:mm:ss').format('YYYY-MM-DD')
        return add
    }

    return (
        <div className='commentCardContainer'>
            <div className='commentCardCommentInfoDiv'>
                <div className='commentCardContainerImageDiv'>
                    <img
                        src={commentData.user.profile_picture !== null ? FormatImage(commentData.user.profile_picture) : Avatar}
                        className='commentCardContainerImageDiv_image' alt=''></img>
                </div>
                <div className='commentCardCommentDetailsDiv'>
                    <div className='commentCardActionButtonDiv'><span
                        className='commentCardUserName'>{commentData.user.full_name}
                        &nbsp;
                        {commentData?.user?.email_verified && <i style={{ color: 'blue', fontSize: 18 }} className={' fa fa-check-circle'}></i>}
                        <span
                            className='commentDateSpan'>{convertDate(commentData.created_at)}</span>  </span>
                        <div className='commentCardButtons'><i onClick={() => handleApproveDeny('Publish')}
                            className={showComments === 'review' ? 'fas fa-check-circle' : 'fas fa-edit'}></i><i
                                onClick={() => handleApproveDeny('Delete')} class='fas fa-times-circle'></i></div>
                    </div>
                    <p className='commentCardCommentText'>{commentData.message} </p>
                    {commentData.reply.length > 0 &&
                        <div className='ratingReplyContainer'>
                            <span className='showCommentText'
                                onClick={() => setShowReply(!showReply)}> {showReply ? 'Hide Replies' : 'Show Replies'}</span>
                            {showReply && <div className='ratingReplyInnerContainer'>
                                {commentData.reply.map((data) => {
                                    return (<div>
                                        <span className='replyUserName'>{data.user.full_name}</span>
                                        <span className='ratingReplyText'>{data.message} <i class='fas fa-trash'
                                            onClick={() => {
                                                handleModal()
                                                setReplyId(data.id)
                                            }}></i> </span>
                                    </div>)
                                })}
                            </div>
                            }
                        </div>}
                </div>
            </div>
            <div className='commentCardLessonInfoDiv'>
                <div className='commentCardLessonInfoDivTexts'>
                    <span className='commentCardLessonInfoCourse'> Lesson Name : {commentData?.lesson_title}</span>
                    {commentData.course &&
                        <span className='commentCardLessonInfoLesson'> Course Name : {commentData?.course}</span>
                    }
                </div>
                <div>
                    <img className='commentCardLessonThumbnail'
                        src={commentData.lesson_thumbnail ? FormatThumbnailImage(commentData.lesson_thumbnail) : Avatar} alt='Thumbnail'>
                    </img>
                </div>
            </div>
            <Modal
                className='modal-dialog-centered'
                isOpen={confirmModal}
                toggle={handleModal}
            >
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Confirmation
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={handleModal}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <h4>Are you sure you want to delete this reply?</h4>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={handleModal}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button'
                        onClick={() => handleDeleteReply()}>
                        Delete
                    </Button>
                </div>
            </Modal>


        </div>
    )
}

export default withSnackbar(CommentCard) 