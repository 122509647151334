import React, { Component } from 'react';
import './Messanger.css';
import avatar1 from '../../assets/img/dummyUser.png'; 
import { Button, Container } from 'reactstrap';
import Header from 'components/Headers/Header';
import { withSnackbar } from 'notistack';
import moment from 'moment';
import addNotification from 'react-push-notification';
import messageSound from '../../assets/sound/sound.mp3';
import BulkMessage from 'components/Modal/BulkMessage';
import SetMessage from 'components/Modal/SetMessage';
import CallLink from 'components/Modal/CallLink';
import StudentDetails from 'components/Student/StudentDetails';
import ChatStudent from 'components/Student/ChatStudent';
import InfiniteScroll from 'react-infinite-scroll-component';
import { Oval } from 'react-loader-spinner';
import { StyleOutlined, StyleRounded } from '@material-ui/icons';
import { Avatar } from '@material-ui/core';
import { Link } from 'react-router-dom';
const Services = require('../../RemoteServices/RemoteServices.js');

class Messages extends Component {
  constructor(props) {
    super(props);
    this.state = {
      socket: this.props.socket,
      users: this.props.users,
      filteredUsers: this.props.filteredUsers,
      messages: this.props.messages,
      currentUserId: this.props.currentUserId,
      currentStudentId: this.props.currentStudentId,
      newMessage: "",
      searchQuery: "",
      showMessage: this.props.showMessage,
      userTyping: this.props.userTyping,
      userDetail: this.props.userDetail,
      fullName: this.props.fullName,
      bulkMessage: false,
      setMessage: false,
      callLink: false,
      saveChatList: [],
      count: true,
      loading: false,
      allMessagesLoaded: false,
      isScrolling: false,
      chatConfig: [],
      showTyping: false,
      detailsPanel: false,
      selectedRow: '',
      pageLoading: false,
      isScrolling: false,
      triggerRender: false,
      studentId: '',
      studentPaid:'',
      email_is_valid:false
    };
    this.textareaRef = React.createRef();
    this.messagesContainer = React.createRef(); // Initialize the ref
    this.isMountedFlag = false;
    this.scrollTimeout = null; // Initialize the scroll timeout
  }

  getChatConfig = async () => {
    await Services.getChatConfig().then((response) => {
      this.setState({ chatConfig: response })
    })
      .catch((error) => {
        throw error
      })

  }


  handleInputChange = (e) => {
    const textarea = this.textareaRef.current;

    // Adjust the height of the textarea to fit the content
    textarea.style.height = 'auto';
    textarea.style.height = `${textarea.scrollHeight}px`;

    if (e.nativeEvent.inputType !== 'insertLineBreak') {
      this.props.socket.send(
        JSON.stringify({
          action: 'typing',
          typing: true,
          user_id: this.props.currentUserId,
          me_id: JSON.parse(localStorage.getItem('user'))?.id,
          full_name: JSON.parse(localStorage.getItem('user'))?.full_name
        })
      );
      if (this.props.currentUserId) {
        setTimeout(() => {
          this.props.socket.send(
            JSON.stringify({
              action: 'typing',
              typing: false,
              user_id: this.props.currentUserId,
              me_id: JSON.parse(localStorage.getItem('user'))?.id,
              full_name: JSON.parse(localStorage.getItem('user'))?.full_name
            })
          );
        }, 5000);
      }
    }

    this.props.setDynamicState('newMessage', e.target.value);
  };

  handleKeyDown = (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault(); // Prevent default behavior of Enter key (new line)
      this.handleSendMessage(); // Call sendMessage function
    }
  };

  adjustTextareaHeight = () => {
    const textarea = this.textareaRef.current;
    if (textarea) {
      textarea.style.height = 'auto';
      textarea.style.height = `${textarea.scrollHeight}px`;
    }
  };
  componentDidMount() {
    this.isMountedFlag = true;
    this.props.toggleState('currentUserId', null)
    this.props.toggleState('showMessage', false)
    this.props.resetMessage()
    // Ensure the ref is not null before adding the event listener
    if (this.messagesContainer.current) {
      this.messagesContainer.current.addEventListener('scroll', this.handleScroll);
    }
    this.adjustTextareaHeight();
    this.interval = setInterval(() => {
      // Trigger a re-render by updating the state
      this.setState((prevState) => ({ triggerRender: !prevState.triggerRender }));
    }, 60000); // Update every minute

  }

  componentWillUnmount() {
    this.isMountedFlag = false;
    // Ensure the ref is not null before removing the event listener
    if (this.messagesContainer.current) {
      this.messagesContainer.current.removeEventListener('scroll', this.handleScroll);
    }
    clearInterval(this.interval);
  }

  handleScroll = () => {

    if (
      this.messagesContainer.current.scrollTop === 0 &&
      !this.props.loading &&
      !this.props.allMessagesLoaded
    ) {
      const prevHeight = this.messagesContainer.current.scrollHeight;

      if (this.isMountedFlag) {
        this.props.getNext().then(() => {
          this.messagesContainer.current.scrollTop = this.messagesContainer.current.scrollHeight - prevHeight;
        });
      }
      this.setState({ isScrolling: true });
      clearTimeout(this.scrollTimeout);
      this.scrollTimeout = setTimeout(() => {
        this.setState({ isScrolling: false });
      }, 20000); // Adjust the timeout as needed
    }

    if (
      this.messagesContainer.current.scrollTop ===
      this.messagesContainer.current.scrollHeight - this.messagesContainer.current.offsetHeight
    ) {
      this.setState({ isScrolling: false });
    }
  };


  getSaveChat = async () => {
    await Services.getSaveChat().then((response) => {
      this.setState({ saveChatList: response })
    })
      .catch((error) => {
        throw error
      })

  }



  toggleState = state => {
    this.setState({
      [state]: !this.state[state]
    })
  }

  componentDidUpdate(prevProps, prevState) {
    if (prevProps.userTyping !== this.props.userTyping) {
      if (this.props.userTyping) {
        this.setState({ showTyping: true });

        // Set timeout to hide the typing message after 3 seconds
        this.typingTimeout = setTimeout(() => {
          this.setState({ showTyping: false });
        }, 5000); // Adjust the timeout duration as needed
      } else {
        this.setState({ showTyping: false });
      }
    }

    const { messages } = this.props;

    // Check if messages have been updated
    if (!this.state.isScrolling) {
      if (prevState.messages !== messages) {
        this.scrollToBottom(); // Scroll to bottom when new messages are received
      }
    }
    if (prevProps.newMessage !== this.props.newMessage) {
      this.adjustTextareaHeight();
    }
  }

  filterMessagesByFullName = (messages) => {
    return messages.filter(message =>
      message.full_name === JSON.parse(localStorage.getItem('user')?.full_name) || message.full_name === this.state.userDetail.full_name
    );
  }

  scrollToBottom = () => {
    if (this.messagesContainer.current) {
      this.messagesContainer.current.scrollTop = this.messagesContainer.current.scrollHeight;
    }
  }

  handleMouseOver = (index) => {
    this.setState({ hoveredIndex: index });
  };

  handleMouseOut = () => {
    this.setState({ hoveredIndex: null });
  };

  isURL = (message) => {
    const urlPattern = new RegExp(
      '^(https?:\\/\\/)' +
      '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.?)+[a-z]{2,}|' +
      '((\\d{1,3}\\.){3}\\d{1,3}))' +
      '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*' +
      '(\\?[;&a-z\\d%_.~+=-]*)?' +
      '(\\#[-a-z\\d_]*)?$', 'i'
    );
    return urlPattern.test(message);
  };

  renderMessage = (message) => {
    // Split the message into paragraphs based on double newline characters
    const paragraphs = message.split('\n').filter(Boolean); // Filter out empty strings

    return paragraphs.map((paragraph, index) => {
      const words = paragraph.split(' ').map((word, wordIndex) => {
        if (this.isURL(word)) {
          return (
            <a
              key={wordIndex}
              style={{ color: 'blue', fontSize: 13 }}
              href={word}
              target="_blank"
              rel="noopener noreferrer"
            >
              {word}
            </a>
          );
        } else {
          return <span key={wordIndex} style={{ fontSize: 13 }}>{word} </span>;
        }
      });

      // Check if it's the last paragraph to avoid unnecessary margin
      const isLastParagraph = index === paragraphs.length - 1;

      return (
        <div key={index} style={{ marginBottom: isLastParagraph ? 0 : '3px' }}>
          {words}
        </div>
      );
    });
  };

  handleSendMessage = () => {
    const textarea = this.textareaRef.current;

    // Send the message here
    this.props.handleSendMessage();

    // Reset the textarea height
    textarea.style.height = 'auto';
    this.props.setDynamicState('newMessage', '');
  };
  handleNext = () => {
    if (this.state.isScrolling) return;
    this.setState({ isScrolling: true });
    this.props.nextChatUsers(this.props?.filteredUsers?.next)
      .finally(() => this.setState({ isScrolling: false }));
  };

  formattime = (time) => {
    const now = moment()
    const diff = moment.duration(now.diff(time))
    if (diff.asSeconds() < 60) {
      return `${Math.floor(diff.asSeconds())} sec`;
    } else if (diff.asMinutes() < 60) {
      return `${Math.floor(diff.asMinutes())} min`;
    } else if (diff.asHours() < 24) {
      return `${Math.floor(diff.asHours())} H`;
    } else if (diff.asDays() < 30) {
      return `${Math.floor(diff.asDays())} D`;
    } else if (diff.asMonths() < 12) {
      return `${Math.floor(diff.asMonths())} M`;
    } else {
      return `${Math.floor(diff.asYears())} Y`;
    }
  }

  handleCall = () =>  {
    this.props.history.push('/admin/call?user-id='+this.props.currentUserId);
  }


  render() {
    let base = window.config.base;
    const { filteredUsers, currentUserId, currentStudentId, resetUserTyping, handleUserClick,outsideTyping } = this.props;



    return (
      <>
        <Header page={'lesson'} />
        {!this.props.pageLoading ?
          <div className='mt-3 p-2' fluid="true">
            <div className='mb-2' style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', alignItems: 'baseline' }}>
              <div>
                {JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'SUPERUSER' || JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'ADMIN' ? <span style={{ fontWeight: 'bold', fontSize: 16 }}>Online: {this?.props?.onlineUsers?.length > 0 ? this?.props?.onlineUsers?.length : this.props.onlineCount || 0}</span> : ""}
              </div>
              {JSON.parse(localStorage.getItem('user'))?.role_type.toUpperCase() === 'SUPERUSER' &&
                <div>

                  <Button color='primary' onClick={() => {
                    this.toggleState('callLink');
                    this.getChatConfig();

                  }}>Call Link</Button>
                  <Button color='primary' onClick={() => {
                    this.toggleState('setMessage')
                    this.getSaveChat()
                  }}>Set Messages</Button>
                  <Button color='primary' onClick={() => this.toggleState('bulkMessage')}>Bulk Messages</Button>
                </div>
              }
            </div>
            <div className="messenger-container">
              {this.props.filteredUsers.results &&
                <div className='userss'>
                  <input className='mt-2 ml-2' type="text" placeholder="Search..." value={this.props.searchQuery} onChange={this.props.handleInputChange} />
              

                  <div id="scrollableDiv" style={{ height: '500px', overflow: 'auto' }}> {/* Set specific height and make it scrollable */}
                    <InfiniteScroll
                      className='user-list'
                      style={{ overflow: 'none' }}
                      dataLength={filteredUsers.results.length}
                      hasMore={filteredUsers.count > filteredUsers.results.length}
                      next={this.handleNext}
                      loader={
                        <div className='h-50 d-flex align-items-center justify-content-center'>
                          <Oval
                            height="20"
                            width="20"
                            radius="12"
                            color="blue"
                            ariaLabel="loading"
                            wrapperStyle={{}}
                            wrapperClass=""
                          />
                        </div>
                      }
                      endMessage={
                        <p style={{ textAlign: 'center' }}>
                          {/* <b>Yay! You have seen it all</b> */}
                        </p>
                      }
                      scrollableTarget="scrollableDiv"
                    >
                      <ul>
                        {filteredUsers?.results?.map(user => (
                          <React.Fragment key={user.id}>
                          
                            <li
                              style={{ cursor: 'pointer' }}
                              onClick={() => {
                                this.setState({ isScrolling: false,studentPaid:user?.is_paid
                                  ,email_is_valid:user?.is_email_verified
                                });
                                resetUserTyping();
                                handleUserClick(user);

                              }}
                              className={currentUserId === user.id ? "active" : ""}

                            >
                        
                              <div style={{
                                display: 'flex',
                                flexDirection: 'column',
                                position: 'relative',
                                top: '12px'
                              }}>
                                <img
                                  src={user.profile_picture_url ? base.concat('/media/'.concat(user.profile_picture_url)) : avatar1}
                                  alt="User Avatar"
                                  className="user-avatar"
                                />
                                <span style={{}}>

                                  {user?.is_online === true ? (
                                    <>
                                      <i className="fa fa-circle" aria-hidden="true" style={{
                                        color: 'green', fontSize: '10px', position: 'relative', bottom: '17px',
                                        left: '29px '
                                      }}></i>
                                    </>
                                  ) : (user.last_online !==null && <>
                                    <span style={{
                                      fontSize: '11px',
                                      fontWeight: '500',
                                      position: 'relative',
                                      background: 'rgb(211 211 211)',
                                      borderRadius: '14px',
                                      bottom: ' 14px',
                                      left: '12px',

                                    }}> { this.formattime(user.last_online)}</span>
                                  </>)}
                                </span>
                              </div>
                             
                              <div className="user-details" >

                                <span className="user-name" style={{ fontSize: 14 }}>{user.full_name}&nbsp;
                                  {/* condition remaing from backend */}
                               {
                                 user.is_email_verified &&    <i className="fa fa-check-circle" aria-hidden="true" style={{
                                  color: 'blue',
                                  fontSize: '10px'
                                }}></i>
                               }                                  &nbsp;
                                  {
                                   user.is_paid==="Paid"  && <i className="fa fa-money" aria-hidden="true" style={{
                                      color: 'green',
                                      fontSize: '10px'
                                    }}></i>
                                  }
                                </span>

                                <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                  <span
                                    style={{ fontSize: user?.delivery_type!=="seen" ? 13 : 12, color: user?.delivery_type!=="seen" ? 'rgb(94 114 228)' : 'black' }}
                                    className={user?.delivery_type!=="seen" ? 'font-bold-cusom-900' : ' font-weight-normal'}
                                  >
                                    {(outsideTyping?.typing===true && outsideTyping?.me_id===user?.id )?
                                      (  <div className="typing-container">
                                        <span style={{ fontSize: '10px', margin: '0', padding: '0' }} className="typing-text">typing.....</span>
                                      </div>) :
                                      (
                                    <>
                                      {/* condition remaing from backend */}
                                      {user?.to_user_id === 'admin' ? 'You :' : ''}
                                      {user?.latest_message?.length > 20 ?
                                        user?.latest_message?.substring(0, 20).concat('...') : user?.latest_message}

                                      <span style={{ color: 'black', fontWeight: '400', fontSize: 12 }} >
                                        {`.${this.formattime(user?.latest_timestamp)}`}</span>
                                    </>
                                    )}
                                  </span>
                                  {user?.delivery_type!=="seen" && (
                                    <i className="fa fa-circle" aria-hidden="true" style={{ color: 'blue', fontSize: '10px' }}></i>
                                  )}
                                </div>
                              </div>
                            </li>
                            <hr className='m-0 p-1' />
                          </React.Fragment>
                        ))}
                      </ul>
                    </InfiniteScroll>
                  </div>
                </div>
              }
              {this.props.loading ?
                <>
                  <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center', alignItems: 'center', height: '500px', width: '100%' }}>
                    <span>Loading.....</span>
                  </div>
                </>
                :
                <>
                  {this.props.showMessage ?
                    <>

                      {this.props.currentUserId && (
                        <div className="message-section">

                         <div style={{ display: 'flex', justifyContent:'space-between'}}>
                         <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }}>
                            <div style={{ cursor: 'pointer' }}>
                              <img onClick={() => {
                                this.setState({
                                  detailsPanel: true,
                                  selectedRow: currentStudentId
                                })
                              }} className="user-avatar" 
                              src={this.props.userDetail?.profile_picture_url ? base.concat('/media/'.concat(this.props.userDetail.profile_picture_url)) : avatar1} 
                              alt="Avatar" style={{ height: 60, width: 60 }} />
                            </div>
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                              <h3 style={{ cursor: 'pointer' }} onClick={() => {
                                this.setState({
                                  detailsPanel: true,
                                  selectedRow: currentStudentId
                                })
                              }} className='m-0 p-0'>
                                {this.props.userDetail.full_name}&nbsp;
                               {
                                this.state.email_is_valid===true &&  <i className="fa fa-check-circle" aria-hidden="true" style={{
                                  color: 'blue',
                                  fontSize: '10px'
                                }}>
                                </i>
                               } &nbsp;
                                {
                                  this.state.studentPaid==='Paid' && <i className="fa fa-money" aria-hidden="true" style={{
                                    color: 'green',
                                    fontSize: '10px'
                                  }}></i>
                                }
                                </h3>
                              <div style={{ display: 'flex', flexDirection: 'row', gap: 2, justifyContent: 'flex-start', alignItems: 'center' }}>
                                {this.props.userDetail.is_online} <i className='fa fa-circle' style={{ color: this.props.userDetail.is_online ? 'green' : 'lightgrey' }} />
                                <p style={{ fontSize: 12 }} className='m-0 p-0'>{this.props?.userDetail?.is_online ? `Online` : `Offline ${this.formattime(this.props.userDetail.last_online)} `}</p>
                              </div>
                            </div>                         
                          </div>
                          <div style={{ display: 'flex', flexDirection: 'row', gap: 10 }} >  
                              <i className="fa fa-phone" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.handleCall}></i>
                              <i className="fa fa-video-camera" style={{cursor:'pointer'}} aria-hidden="true" onClick={this.handleCall}></i>
                          </div>
                         </div>
                          <div className="message-box mt-2" ref={this.messagesContainer} onScroll={this.handleScroll}>
                           
                            {this.props.loader1 &&
                              <div style={{ width: '100%', display: 'flex', justifyContent: 'center' }}>
                                <span>Loading....</span>
                              </div>
                            }
                            {this.props.messages && this.props?.messages?.filter(obj => obj.user === this.props.currentUserId || obj.user === JSON.parse(localStorage.getItem('user')).id || obj.user === 50843 || obj.to_user_id === this.props.currentUserId || obj.to_user_id === 'user').map((item, index) => (
                              <div key={index} className={`message ${item.to_user_id === 'admin' ? 'user-message' : 'admin-message'}`}>
                                {item.to_user_id === 'admin' && (
                                  <img src={item.profile_picture_url ? base.concat(item.profile_picture_url) : avatar1} alt="Avatar" className="avatar" />
                                )}
                                <div className={`message ${item.to_user_id === 'admin' ? 'message-content-admin' : 'message-content'}`} >

                                  {this.renderMessage(item.message)}
                                  <div className="info-container">
                                    {item.to_user_id !== 'admin' &&
                                      <p style={{ fontSize: 11 }} className="m-0 p-0 sent-time">{item.full_name}&nbsp;</p>
                                    }
                                    <p style={{ fontSize: 11 }} className="m-0 p-0 sent-time">{moment(item.timestamp).fromNow()}</p>&nbsp;
                                    {item.to_user_id !== 'admin' &&
                                      <>
                                        {item.delivery_type === 'seen' ?
                                          (<i style={{ fontSize: 12 }} className="fa fa-check-circle" />
                                          ) : item.delivery_type === 'delivered' ? (
                                            <i style={{ fontSize: 12 }} className="fa fa-check-double" />
                                          ) : (
                                            <i style={{ fontSize: 12 }} className="fa fa-check" />
                                          )
                                        }
                                      </>
                                    }
                                  </div>
                                </div>
                              </div>
                            ))}
                            {this.state.showTyping ? (
                              <p className="typing">{this.props.userDetail.full_name} is typing...</p>
                            ) : (
                              ''
                            )}
                          </div>
                          {this.props.socket ?
                            <div className="compose-message" style={{ gap: 5 }}>
                              <div style={{ display: 'flex', flexDirection: 'column', width: '100%' }}>
                                <textarea
                                  ref={this.textareaRef}
                                  placeholder="Type a message..."
                                  value={this.props.newMessage}
                                  onChange={this.handleInputChange}
                                  onKeyDown={this.handleKeyDown}
                                  rows={2}
                                  style={{ width: '100%', resize: 'none', overflow: 'hidden' }}
                                />

                                {this.props.saveMessageList && this.props.saveMessageList.map((item, index) => (
                                  <div
                                    key={index}
                                    onMouseOver={() => this.handleMouseOver(index)}
                                    onMouseOut={this.handleMouseOut}
                                    style={{
                                      display: 'flex',
                                      flexDirection: 'row',
                                      padding: 10,
                                      boxShadow: '1px 1px 1px 1px lightgrey',
                                      marginTop: 10,
                                      width: `calc(100% - 20px)`,
                                      cursor: 'pointer',
                                      backgroundColor: this.state.hoveredIndex === index ? 'lightgrey' : 'white',
                                    }}
                                    onClick={() => {
                                      this.props.setDynamicState('newMessage', item.message)
                                      this.props.resetSaveChatList()

                                    }}
                                  >
                                    <div style={{ display: 'flex', flexDirection: 'column' }}>
                                      <span style={{ fontSize: 16, fontWeight: 'bold' }}>{item.tag}</span>
                                      <span>{item.message.substring(0, 100)}</span>
                                    </div>
                                  </div>
                                ))}
                              </div>
                              <button style={{ cursor: 'pointer' }} onClick={this.handleSendMessage}>
                                <i className="fa fa-paper-plane" />&nbsp;
                                Send</button>
                            </div> :
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'center' }}>
                              <span>Connecting....</span>
                            </div>
                          }

                        </div>
                      )}
                    </>
                    :
                    <div className="message-section1">
                      <h1>Please Select Message</h1>
                    </div>

                  }
                </>
              }
            </div>
            {this.state.bulkMessage &&
              <BulkMessage
                toggleState={this.toggleState}
                toggleTab={this.props.toggleState}
                open={this.state.bulkMessage}
                socket={this.props.socket}
                getChatUsersList={this.props.getChatUsersList}
              />
            }
            {this.state.setMessage &&
              <SetMessage
                toggleState={this.toggleState}
                open={this.state.setMessage}
                saveChatList={this.state.saveChatList}
                getSaveChat={this.getSaveChat}
              />
            }
            {this.state.callLink &&
              <CallLink
                toggleState={this.toggleState}
                open={this.state.callLink}
                getSaveChat={this.getSaveChat}
              />
            }

            {
              this.state.detailsPanel &&
              <ChatStudent studentData={this.state.selectedRow} closePanel={() => {
                this.setState({ detailsPanel: false })
              }}></ChatStudent>
            }
          </div> :
          <div style={{ height: '80vh', display: 'flex', flexDirection: 'row', alignItems: 'center', justifyContent: 'center' }}>
            <span>Loading.......</span>
          </div>
        }
      </>
    );
  }
}

export default withSnackbar(Messages);
