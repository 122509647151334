import React from "react";
import { withSnackbar } from 'notistack';
import Dialog from '@material-ui/core/Dialog';
import Divider from '@material-ui/core/Divider';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import {
  Button,
  Col,
  FormGroup,
  Input,
  InputGroup,
  InputGroupAddon,
  InputGroupText,
  Row,
  Modal, 
} from 'reactstrap'
import MaterialTable from 'material-table'
import Chip from '@material-ui/core/Chip';
import ListItemText from '@material-ui/core/ListItemText';
import ListItem from '@material-ui/core/ListItem';
import List from '@material-ui/core/List';
import Viewer from 'react-viewer'
import { EditorState,convertToRaw } from 'draft-js';
import { Editor } from "react-draft-wysiwyg"

import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import './studentDetails.css'
import ReactHtmlParser from 'react-html-parser';
import moment from 'moment'
import draftToHtml from "draftjs-to-html";

const Services = require('../../RemoteServices/RemoteServices.js');
// const useStyles = makeStyles((theme) => ({
//   appBar: {
//     position: 'relative',
//   },
//   title: {
//     marginLeft: theme.spacing(2),
//     flex: 1,
//   },
// }));
// const Transition = React.forwardRef(function Transition(props, ref) {
//   return <Slide direction="up" ref={ref} {...props} />;
// });


class StudentDetailsAssignment extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      open: true,
      showDelieveredAmount: false,
      showCurrentLessonUpdate: false,
      showAssignmentVisibilityUpdate: false,
      showCreateNotifications: false,
      studentDetails: '',
      historyData: [],
      remarks: this.props.remarks,
      approveModal: false,
      denyModal: false,
      disableSubmit: false,
      imagesModal: false,
      assignmentImages2: [],      
      showAllButton: true,
      showText: false,
      showImage: false,
      showVideo: false,
      video: '',
      notification: false,
      link: {
        tag: '',
        tags: '',
        lessonNo: '',
        title: '',
        description: '',
        videoLink: '',
        selectedOptionId: '',
        dropdownOptions: [],
        editorState: EditorState.createEmpty(),
        editorStateCopied: EditorState.createEmpty(),
        editorContentHtmlCopied: '',
        imagePreview: '',
        image: '',
        remarks: [],
        uniqueId: '',
        editorContentHtml: '',
        video: '',
        itemList: [{
          showEditorCode: false,
          editorState: EditorState.createEmpty(),
          editorStateCopied: EditorState.createEmpty(),
          editorContentHtmlCopied: '',
          imagePreview: '',
          editorContentHtml: '',
          image: '',
          description: '',
          thumbnail: '',
          video: '',
          order: '',
          orientation: '',
          buttonLink: '',
          buttonName: '',
          subTitle: '',
          imageBlob: '',
          showAllButton: true,
          addButton: true,
          showText: false,
          showImage: false,
          showVideo: false,
          showTable: false,
          showSubTitle: false,
          showQuiz: false,
          quizData: '',
          rawStr: '',
          data: [],
          tableArray: '',
          notification: '',
          paymentCategory: false
        }]
      },
    };
    this.openImagesInNewTab = this.openImagesInNewTab.bind(this)
    this.handleRemarks = this.handleRemarks.bind(this)
  }

  handlePasteImage = (event) => {
    const items = (event.clipboardData || event.originalEvent.clipboardData).items;
    for (let i = 0; i < items.length; i++) {
      if (items[i].type.indexOf('image') !== -1) {
        const blob = items[i].getAsFile();
        let reader = new FileReader()
        reader.onload = () => {
          let binaryData = reader.result
          let base64String = btoa(binaryData)
          this.setState({ image: base64String })
        }
        reader.readAsBinaryString(blob)
        this.setState({ imagePreview: URL.createObjectURL(blob), image: blob });
        // If you want to upload the image, you can use a function like this.uploadImage(blob)
        // this.uploadImage(blob);
      }
    }
  };

  handleTableChange = (e, idx, rowIndex, colIndex) => {
    const newData = [...this.state.link.itemList]
    newData[idx]['tableArray'][rowIndex][colIndex] = e.target.value
    //console.log('asdfasfd', ...newData)
    this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...newData]
      }
    }))
  }

  tableRow = (data, idx) => (
    <table className={'mt-2 mb-2 table table-bordered table-responsive'}>
      {data.map((row, i) => (
        <tr style={{ width: 'auto' }} key={i}>
          {row.map((col, j) => (
            <td key={j}><input
              style={{ border: 'none' }}
              type='text'
              value={col ? col : ''}
              onChange={(e) => this.handleTableChange(e, idx, i, j)}
            /></td>
          ))}
        </tr>
      ))}
    </table>
  )

  countQuotes = (str) => {
    return str.split('"').length - 1
  }

  parseClip = (str) => {
    let r,
      rlen,
      rows,
      arr = [],
      a = 0,
      c,
      clen,
      multiline,
      last
    rows = str
      .split('\r\n')
      .reduce((acc, item) => acc.concat(item.split('\n')), [])
      .reduce((acc, item) => acc.concat(item.split('\r')), [])
    if (rows.length > 1 && rows[rows.length - 1] === '') {
      rows.pop()
    }
    for (r = 0, rlen = rows.length; r < rlen; r += 1) {
      rows[r] = rows[r].split('\t')
      for (c = 0, clen = rows[r].length; c < clen; c += 1) {
        if (!arr[a]) {
          arr[a] = []
        }
        if (multiline && c === 0) {
          last = arr[a].length - 1
          arr[a][last] = arr[a][last] + '\n' + rows[r][0]
          if (multiline && this.countQuotes(rows[r][0]) & 1) {
            //& 1 is a bitwise way of performing mod 2
            multiline = false
            arr[a][last] = arr[a][last]
              .substring(0, arr[a][last].length - 1)
              .replace(/""/g, '"')
          }
        } else {
          if (
            c === clen - 1 &&
            rows[r][c].indexOf('"') === 0 &&
            this.countQuotes(rows[r][c]) & 1
          ) {
            arr[a].push(rows[r][c].substring(1).replace(/""/g, '"'))
            multiline = true
          } else {
            arr[a].push(rows[r][c].replace(/""/g, '"'))
            multiline = false
          }
        }
      }
      if (!multiline) {
        a += 1
      }
    }
    return arr
  }
  getExtension = (filename) => {
    var parts = filename.split('.')
    return parts[parts.length - 1]
  }

  isImage = (filename) => {
    var ext = this.getExtension(filename)
    switch (ext.toLowerCase()) {
      case 'jpg':
      case 'gif':
      case 'bmp':
      case 'png':
      case 'jpeg':
        //etc
        return true
    }
    return false
  }
  handleImageSelect = async (e, idx) => {
    let file = e.target.files[0]
    let filename = e.target.files[0].name
    if (this.isImage(filename)) {
      let itemTransaction = [...this.state.link.itemList]
      // itemTransaction[idx]['image'] = e.target.files[0]
      // itemTransaction[idx]['imagePreview'] = URL.createObjectURL(e.target.files[0])
      // this.setState(prevState => ({
      //     ...prevState,
      //     link: {
      //         itemList: [...itemTransaction]
      //     }
      // }))
      // let reader = new FileReader()
      // reader.onloadend = function() {
      //     let data = (reader.result).split(',')[1]
      //     let binaryBlob = atob(data)
      //     itemTransaction[idx]['imageBlob'] = binaryBlob
      //     this.setState(prevState => ({
      //         ...prevState,
      //         link: {
      //             itemList: [...itemTransaction]
      //         }
      //     }))
      // }.bind(this)
      // reader.readAsDataURL(file)
      let token = localStorage.getItem('token')
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest()
          const serverUrl = window.config.apiUrl + 'common/image-upload'
          xhr.open('POST', serverUrl)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          const data = new FormData()
          data.append('image', file)
          xhr.send(data)
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText)
            resolve(response)
            itemTransaction[idx]['image'] = response.data.link
            itemTransaction[idx]['imagePreview'] = response.data.link
            this.setState(prevState => ({
              ...prevState,
              link: {
                itemList: [...itemTransaction]
              }
            }))
          })
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText)
            reject(error)
          })
        }
      )

    } else {
      this.props.enqueueSnackbar('Please choose a correct image format', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }

  }

  handleImageRemove = async (e, idx) => {
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx]['imagePreview'] = ''
    itemTransaction[idx]['image'] = ''
    await this.setState(prevState => ({
      ...prevState,
      link: {
        itemList: [...itemTransaction]
      }
    }))
  }

  changeState = (state) => {
    this.setState((prevState) => ({
      ...prevState,
      [state]: !this.state[state]

    }))
  }


  addButtonHandler = (state, idx) => {
    let item = [...this.state.link.itemList]
    item[idx][state] = !this.state.link.itemList[idx][state]
    this.setState((prevState) => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...item]
      }
    }))

  }

  editTable = async (event, idx) => {
    if (event) {
      //console.log('asdfasdf', event)
      // let itemTransaction = [...this.state.link.itemList]
      // itemTransaction[idx]['tableData'] = event
      // await this.setState(prevState => ({
      //     ...prevState,
      //     link: {
      //         ...prevState.link,
      //         itemList: [...itemTransaction]
      //     }
      // }))
    }

  }

  addItem = async (idx) => {
    const { link } = this.state
    let item = {
      showEditorCode: false,
      editorState: EditorState.createEmpty(),
      editorStateCopied: EditorState.createEmpty(),
      editorContentHtmlCopied: '',
      imagePreview: '',
      editorContentHtml: '',
      image: '',
      description: '',
      video: '',
      order: '',
      orientation: '',
      buttonLink: '',
      buttonName: '',
      subTitle: '',
      thumbnail: '',
      imageBlob: '',
      paymentButtonName: '',
      paymentCategory: false,
      paymentAmount: '',
      showAllButton: true,
      addButton: true,
      showText: false,
      showImage: false,
      showTable: false,
      showVideo: false,
      showButton: false,
      showSubTitle: false,
      showQuiz: false,
      quizData: '',
      rawStr: '',
      table: {
        rows: [],
        columns: []
      },
      data: '',
      tableArray: ''
    }
    await this.setState({
      showAllButton: true,
      link: {
        ...link,
        itemList: [...link.itemList, item]
      }

    })
    let itemUpdated = [...this.state.link.itemList]
    itemUpdated[idx]['addButton'] = false
    await this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...itemUpdated]
      }
    }))

  }
  editItem = (event, idx, type) => {

    let name = event.target.name
    let value = event.target.value
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx][name] = value
    if (type === 'paymentCategory') {
      itemTransaction[idx]['paymentCategory'] = event.target.checked
      itemTransaction[idx]['paymentAmount'] = ''
      this.setState({
        itemList: [...itemTransaction]
      })
    }
    if (type === 'table') {
      itemTransaction[idx]['rawStr'] = event.target.value
      itemTransaction[idx]['data'] = this.parseClip(event.target.value)
      itemTransaction[idx]['tableArray'] = this.parseClip(event.target.value)
      this.setState({
        itemList: [...itemTransaction]
      })
    }
    this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...itemTransaction]
      }
    }))

  }
  removeItem = async (idx) => {
    if (idx > 0) {
      let item = [...this.state.link.itemList]
      item.splice(idx, 1)
      await this.setState(prevState => ({
        ...prevState,
        link: {
          ...prevState.link,
          itemList: item
        }
      }))
      let itemUpdated = [...this.state.link.itemList]
      itemUpdated[idx - 1]['addButton'] = true
      await this.setState(prevState => ({
        ...prevState,
        link: {
          ...prevState.link,
          itemList: [...itemUpdated]
        }
      }))
    } else if (idx === 0 && this.state.link.itemList.length >= 2) {
      let item = [...this.state.link.itemList]
      item.splice(idx, 1)
      await this.setState(prevState => ({
        ...prevState,
        showAllButton: true,
        link: {
          ...prevState.link,
          itemList: item
        }
      }))
    } else {
      let item = [...this.state.link.itemList]
      item.splice(idx, 1)
      await this.setState(prevState => ({
        ...prevState,
        showAllButton: true,
        link: {
          ...prevState.link,
          itemList: [{
            image: '',
            description: '',
            video: '',
            order: '',
            orientation: '',
            thumbnail: '',
            showAllButton: true,
            addButton: true,
            tableData: '',
            buttonLink: '',
            buttonName: '',
            subTitle: ''
          }]
        }
      }))
    }
  }


  addTable = async (idx) => {
    const { itemList } = this.state.link
    let item = {
      rows: [],
      columns: []
    }
    await this.setState({
      showAllButton: true,
      itemList: {
        ...itemList,
        tableArray: [...itemList.tableArray, item]
      }

    })
  }
  editTable = (event, idx, type) => {
    let name = event.target.name
    let value = event.target.value
    let itemTransaction = [...this.state.link.itemList.tableArray]
    itemTransaction[idx][name] = value
    this.setState(prevState => ({
      ...prevState,
      link: {
        ...prevState.link,
        itemList: [...itemTransaction]
      }
    }))

  }
  removeTable = async (idx) => {
    let item = [...this.state.link.itemList.tableArray]
    item.splice(idx, 1)
    await this.setState(prevState => ({
      ...prevState,
      showAllButton: true,
      itemList: {
        ...prevState.itemList,
        tableArray: [{
          rows: [],
          column: []
        }]
      }
    }))
  }

  handleYoutubeImageSelect = async (e, idx) => {
    let file = e.target.files[0]
    let filename = e.target.files[0].name
    if (this.isImage(filename)) {
      let itemTransaction = [...this.state.link.itemList]
      let token = localStorage.getItem('token')
      return new Promise(
        (resolve, reject) => {
          const xhr = new XMLHttpRequest()
          const serverUrl = window.config.apiUrl + 'common/image-upload'
          xhr.open('POST', serverUrl)
          xhr.setRequestHeader('Authorization', `Bearer ${token}`)
          const data = new FormData()
          data.append('image', file)
          xhr.send(data)
          xhr.addEventListener('load', () => {
            const response = JSON.parse(xhr.responseText)
            resolve(response)
            itemTransaction[idx]['thumbnail'] = response.data.link
            this.setState(prevState => ({
              ...prevState,
              link: {
                itemList: [...itemTransaction]
              }
            }))
          })
          xhr.addEventListener('error', () => {
            const error = JSON.parse(xhr.responseText)
            reject(error)
          })
        }
      )

    } else {
      this.props.enqueueSnackbar('Please choose a correct image format', {
        variant: 'warning',
        anchorOrigin: {
          vertical: 'bottom',
          horizontal: 'right'
        }
      })
    }

  }
  handleYoutubeImageRemove = async (e, idx) => {
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx]['thumbnail'] = ''
    await this.setState(prevState => ({
      ...prevState,
      link: {
        itemList: [...itemTransaction]
      }
    }))
  }

  onLinkEditorStateChange = async (editorState, idx) => {
    const rawContentState = convertToRaw(editorState.getCurrentContent())
    let itemTransaction = [...this.state.link.itemList]
    itemTransaction[idx]['editorContentHtml'] = draftToHtml(rawContentState)
    itemTransaction[idx]['editorState'] = editorState
    await this.setState(prevState => ({
      ...prevState,
      link: {
        itemList: [...itemTransaction]
      }
    }))

  }

  onEditorStateChange = (remarks) => {
    const rawContentState = convertToRaw(remarks.getCurrentContent());
    this.setState({
      remarks,
      editorContentHtml: draftToHtml(rawContentState)
    });
  };

  uploadImageCallBack = (file) => {
    let token = localStorage.getItem('token')

    return new Promise(
      (resolve, reject) => {
        const xhr = new XMLHttpRequest();
        const serverUrl = window.config.apiUrl + 'common/image-upload'
        xhr.open('POST', serverUrl);
        xhr.setRequestHeader('Authorization', `Bearer ${token}`);
        const data = new FormData();
        data.append('image', file);
        xhr.send(data);
        xhr.addEventListener('load', () => {
          const response = JSON.parse(xhr.responseText);
          resolve(response);
        });
        xhr.addEventListener('error', () => {
          const error = JSON.parse(xhr.responseText);
          reject(error);
        });
      }
    );
  }




  async componentDidMount() {
    await this.getAssignmentHistory()
    await this.getDetails()
  }

  getAssignmentHistory = async () => {
    const userId = this.props?.studentData?.student_id
    Services.getAssignmentListHistory(userId).then((response) => {
      this.setState({ historyData: response.results.reverse() })
    })
    await this.getDetails()
  }

  async getDetails() {
    const userId = this.props?.studentData?.student_id
    await Services.getStudentsDetails(userId).then((response) => {
      this.setState({ studentDetails: response })
    })
  }


  async openImagesInNewTab() {
    const images = []
    if (this.state.selectedRow) {
      let userAssignmentImages = this.state.selectedRow.images
      userAssignmentImages.forEach((image) => {
        images.push({ src: image.image, alt: '' })
        // window.open(image.image, "_blank")
      })
      await this.setState({ assignmentImages2: images })
      await this.setState({ imagesModal: true })
    }
  }

  async setRowData(rowData) {
    await this.setState({ selectedRow: rowData })
    await this.openImagesInNewTab()
  }

  toggleState(state) {
    this.setState({
      [state]: !this.state[state]
    })
  }

  handleRemarks(val) {
    this.props.handleSetRemarks(val)
  }

  handleObjects = () => {
    return this.state.link.itemList.map((item, index) => {
      if (item.editorContentHtml !== '' && item.quizData === '' && item.video === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          remark_text: item.editorContentHtml,
          remark_type: 'text'
        };
      } else if (item.video !== '' && item.editorContentHtml === '' && item.quizData === '' && item.image === '' && item.buttonLink === '' && item.tableArray === '' && item.subTitle == '') {
        return {
          video_link: item.video,
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          thumbnail_image: item.thumbnail ? item.thumbnail : null,
          remark_type: 'video'
        };
      } else if (item.image !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.buttonLink === '' && item.tableArray === '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          image: item.image,
          remark_type: 'image'
        };
      } else if (item.buttonLink !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.image === '' && item.tableArray === '' && item.subTitle == '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          button_link: item.buttonLink,
          button_name: item.buttonName,
          remark_type: 'button'
        };
      } else if (item.tableArray !== '' && item.editorContentHtml === '' && item.quizData === '' && item.video === '' && item.image === '' && item.subTitle == '') {
        return {
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          table_data: item.tableArray,
          remark_type: 'table'
        };
      } else if (item.subTitle !== '') {
        return {
          link: '',
          order: item.order || index + 1,
          orientation: item.orientation || 'left',
          title: item.subTitle
        };
      } else {
        // If none of the conditions match, return an empty array
        //console.log('asdfasdfasdfdsf', null)
        return null;

      }
    })
  }

  render() {
    let studentDetails = this.state.studentDetails
    let user = this.state.studentDetails
    //console.log("images", this.state.assignmentImages2)
    return (
      <>
        <div>

          <Dialog style={{ zIndex: 800 }} fullScreen open={this.state.open} onClose={() => {
            this.props.closePanel()
          }} TransitionComponent={this.Transition}>
            <Toolbar>
              <IconButton edge="start" color="inherit" onClick={() => {
                this.props.closePanel()
              }} aria-label="close">
                <CloseIcon />
              </IconButton>
              <Typography variant="h6" style={{
                marginLeft: 20,
                flex: 1
              }}>
                <h2>User Details</h2>
              </Typography>
            </Toolbar>
            <Divider />
            <Row style={{ padding: 30 }}>
              <Col left lg="3" xl="3" style={{ text: 'left' }}>
                <List>
                  {/* <Divider /> */}
                  <ListItem >
                    <ListItemText primary="Full name" secondary={user.full_name} />
                  </ListItem>
                  <ListItem >
                    <ListItemText primary="Roll No" secondary={user.id} />
                  </ListItem>
                  <ListItem >
                    <ListItemText primary="Referral Code" secondary={user?.user?.referral_code} />
                  </ListItem>

                </List>
              </Col>
              <Col lg="3" xl="3">
                <List>
                  {/* <Divider /> */}
                  <ListItem >
                    <div class="MuiListItemText-root MuiListItemText-multiline">
                      <span class="MuiTypography-root MuiListItemText-primary MuiTypography-body1 MuiTypography-displayBlock">Email</span>
                      <p class="MuiTypography-root MuiListItemText-secondary MuiTypography-body2 MuiTypography-colorTextSecondary MuiTypography-displayBlock">
                        {user?.user?.email}
                        &nbsp;{user?.user?.email_verified && <i style={{ color: 'blue',fontSize:18 }} className={' fa fa-check-circle'}></i>}

                      </p>
                    </div>
                  </ListItem>
                  <ListItem >
                    <ListItemText primary="Gender" secondary={user?.user?.gender || 'N/A'} />
                  </ListItem>

                  <ListItem >
                    <ListItemText primary="Referred Count" secondary={user?.user?.referred_count} />
                  </ListItem>

                </List>
              </Col>
              <Col lg="3" xl="3">
                <List>
                  <ListItem >
                    <ListItemText primary="Phone" secondary={user?.user?.phone_number} />
                  </ListItem>
                  <ListItem >
                    <ListItemText primary="Current Lesson" secondary={studentDetails?.current_lesson?.title} />
                  </ListItem>

                </List>


              </Col>
              <Col lg="3" xl="3">
                <List>
                  <ListItem >
                    <ListItemText primary="Date Of Birth" secondary={user.dob || 'N/A'} />
                  </ListItem>
                  <ListItem >
                    <ListItemText primary="Payment Status" secondary={user.payment_status} />
                  </ListItem>

                  {/* <Divider /> */}

                </List>
              </Col>

            </Row>
            {/* <div style={{paddingLeft: 40, paddingRight: 40, paddingTop:10, paddingBottom: 15}}>
            {
                this.state.studentDetails.wallet !== null ?
                <>
                 <Alert className="alert-default">
                 Full Name: {this.state.studentDetails.wallet.full_name} <br/>
                    Wallet Type: {this.state.studentDetails.wallet.type ? this.state.studentDetails.wallet.type : 'Not Available'} <br/>
                    Bank Account / ID : {this.state.studentDetails.wallet.number ? this.state.studentDetails.wallet.number : 'Not Available'} <br/>
                    Bank Name : {this.state.studentDetails.wallet.bank_name? this.state.studentDetails.wallet.bank_name : 'Not Available'} <br/>
                </Alert>
                </>
                :
                <>
                 <Alert color="warning">
                    <strong>Alert!</strong> This user has not yet submitted wallet details.
                </Alert>
                </>
            }
        </div> */}
            <Divider />
            <Row>
              <Col xs={8}>
                <div style={{ paddingLeft: 20, paddingTop: 10, marginTop: 10, height: '680px' }} id="viewerContainer">
                  <h3>Current Assignment Images</h3>
                  <Viewer
                    zoomSpeed={0.8}
                    visible={true}
                    onClose={() => {
                      this.setState({ visible: false });
                    }}
                    images={this?.props?.images}
                    container={document.getElementById("viewerContainer")}
                  />

                </div>
              </Col>

              <Col xs={4} >
                <div className="studentAssignmentDetails_editor_container" style={{ paddingRight: 20 }}>
                  <div style={{ width: '100%', display: 'flex', flexDirection: 'column' }}>
                    {/* <h3>Write your remarks</h3> */}
                    {this.props.lessonRemarks.length > 0 &&
                      <>
                        <h4> Remarks Options:</h4>
                        <div className="assignmentRemarksOptionContainer">

                          {this.props.lessonRemarks.map((val) => {
                            return (
                              <div className="assignmentRemarksOptionDiv" onClick={() => this.handleRemarks(val.text)}>
                                {ReactHtmlParser(val.text)}
                              </div>
                            )
                          })}
                        </div>
                      </>
                    }
                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row', justifyContent: 'flex-end' }}>
                      <Editor
                        editorStyle={{
                          border: '1px solid black',
                          padding: '5px',
                          borderRadius: '2px',
                          height: '400px',
                          width: '100%',
                        }}
                        placeholder="Description / Notes  [NEW Layout]"
                        editorState={this.state.remarks}
                        wrapperClassName="demo-wrapper"
                        editorClassName="demo-editor"
                        onEditorStateChange={this.onEditorStateChange}
                        toolbar={{
                          inline: { inDropdown: true },
                          list: { inDropdown: true },
                          textAlign: { inDropdown: true },
                          link: { inDropdown: true },
                          history: { inDropdown: true },
                          image: {
                            uploadCallback: this.uploadImageCallBack,
                            previewImage: true,
                            defaultSize: { width: '100%', height: 'auto' }
                          },
                        }}
                      />
                    </div>
                  </div>
                  <br />

                  {!this.state.showAllButton &&
                    <Row>
                      <Col xl={12}>
                        <Button onClick={() => this.addItem()}
                          className={'mb-2 btn'}><i
                            className={'fa fa-plus'} />&nbsp;&nbsp;Add </Button>
                      </Col>
                    </Row>
                  }
                  {this.state.link.itemList && this.state.link.itemList.map((item, idx) => (
                    <div>
                      {this.state.link.itemList[idx].showText &&
                        <div className={'mt-3'}>
                          <h4>Text</h4>
                          <Editor
                            key={idx}
                            handlePastedText={() => false}
                            editorStyle={{
                              border: '1px solid black',
                              padding: '5px',
                              borderRadius: '2px',
                              minHeight: '300px',
                              height: 'auto',
                              width: '100%',
                              fontSize: '18px'
                            }}
                            placeholder='Description / Notes  [NEW Layout]'
                            editorState={item.editorState}
                            wrapperClassName='demo-wrapper'
                            editorClassName='demo-editor'
                            onEditorStateChange={(editorState) => this.onLinkEditorStateChange(editorState, idx)}
                            toolbar={{
                              inline: { inDropdown: true },
                              list: { inDropdown: true },
                              textAlign: { inDropdown: true },
                              link: { inDropdown: true },
                              history: { inDropdown: true },
                              image: {
                                uploadCallback: (file) => this.uploadImageCallBack(file),
                                previewImage: true,
                                defaultSize: { width: '100%', height: 'auto' }
                              }
                            }}
                          />
                          <FormGroup className={'mt-1'} style={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-between'
                          }}>
                            <InputGroup className='input-group-alternative mb-3'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-hat-3' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder='Order' type='text' name='order' value={item.order}
                                onChange={(event) => this.editItem(event, idx)} />
                            </InputGroup>
                            <Input className={'ml-2'} type={'select'} name={'orientation'}
                              value={item.orientation} onChange={(event) => this.editItem(event, idx)}>
                              <option>Select Orientation</option>
                              <option value={'left'}>Left</option>
                              <option value={'center'}>Center</option>
                              <option value={'right'}>Right</option>
                            </Input>

                          </FormGroup>

                        </div>
                      }
                      {this.state?.link?.itemList[idx].showImage &&
                        <>
                          <div>
                            <FormGroup className={'mb-0'}>
                              <h4>Upload Image</h4>
                              <input className='thumbnail_upload_button' accept='png/jpeg' type='file'
                                onChange={(e) => this.handleImageSelect(e, idx)} />
                              <br />
                              {item.imagePreview && <div className='thumbnailContainerAssignment'><i
                                onClick={(e) => this.handleImageRemove(e, idx)}
                                class='far fa-times-circle'></i> <img style={
                                  {
                                    width: '70%',
                                    height: '100%',
                                    objectFit: 'contain'
                                  }}
                                  className='lessonFormThumbnailImage' src={item.imagePreview} alt="" />
                              </div>}

                            </FormGroup>
                            <FormGroup style={{
                              display: 'flex',
                              width: '50%',
                              justifyContent: 'space-between'
                            }}>
                              <InputGroup className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                  <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Order' type='text' name='order'
                                  value={item.order}
                                  onChange={(event) => this.editItem(event, idx)} />
                              </InputGroup>
                            </FormGroup>
                          </div>
                        </>
                      }

                      {this.state?.link?.itemList[idx]?.showVideo &&
                        <>
                          <FormGroup className={'mt-3 mb-0'}
                            style={{ display: 'flex', justifyContent: 'space-between' }}>
                            <InputGroup className='input-group-alternative mb-3'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-hat-3' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder='Video Link' type='text' name='video'
                                value={item.video}
                                onChange={(event) => this.editItem(event, idx)} />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup>
                            <h4>Upload Thumbnail Image</h4>
                            <input className='thumbnail_upload_button' accept='png/jpeg'
                              type='file'
                              onChange={(e) => this.handleYoutubeImageSelect(e, idx)} />
                            <br />
                            {item.thumbnail &&
                              <div className='thumbnailContainerAssignment'>
                                <i
                                  onClick={(e) => this.handleYoutubeImageRemove(e, idx)}
                                  class='far fa-times-circle'></i>
                                <img style={{
                                  width: '70%',
                                  height: '100%',
                                  objectFit: 'contain'
                                }}
                                  className='lessonFormThumbnailImage'
                                  src={item.thumbnail}  alt=""/>
                              </div>}

                          </FormGroup>


                          <FormGroup style={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-between',
                            marginTop: '0px'
                          }}>
                            <InputGroup className='input-group-alternative mb-3'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-hat-3' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder='Order' type='text' name='order' value={item.order}
                                onChange={(event) => this.editItem(event, idx)} />
                            </InputGroup>
                          </FormGroup>
                        </>
                      }

                      {this.state?.link?.itemList[idx]?.showButton &&
                        <>

                          <div className={'d-flex justify-content-between'}>
                            <h4>Button Link </h4>
                            {item.buttonName.length > 0 && <span style={{ fontSize: 18 }}
                              className={'font-weight-bold'}>{item.buttonName.length}/100</span>}
                          </div>
                          <FormGroup className={'mt-3 mb-0'}
                            style={{
                              width: '100%',
                              display: 'flex',
                              justifyContent: 'space-between',
                              gap: '10px'
                            }}>
                            <div style={{ display: 'flex', width: '50%' }}>
                              <InputGroup
                                className='input-group-alternative mb-3'>
                                <InputGroupAddon addonType='prepend'>
                                  <InputGroupText>
                                    <i className='ni ni-hat-3' />
                                  </InputGroupText>
                                </InputGroupAddon>
                                <Input placeholder='Button Name'
                                  type='text'
                                  name='buttonName'
                                  value={item.buttonName}
                                  onChange={(event) => this.editItem(event, idx)} />
                              </InputGroup>
                            </div>
                            <InputGroup
                              className='input-group-alternative mb-3'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-hat-3' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder='Button Link' type='text'
                                name='buttonLink'
                                value={item.buttonLink}
                                onChange={(event) => this.editItem(event, idx)} />
                            </InputGroup>
                          </FormGroup>

                          <FormGroup style={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-between',
                            marginTop: '0px',
                            gap: '10px'
                          }}>
                            <InputGroup
                              className='input-group-alternative mb-3'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-hat-3' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder='Order' type='text'
                                name='order' value={item.order}
                                onChange={(event) => this.editItem(event, idx)} />

                            </InputGroup>
                            <Input type={'select'}
                              name={'orientation'}
                              value={item.orientation}
                              onChange={(event) => this.editItem(event, idx)}>
                              <option>Select Orientation</option>
                              <option value={'left'}>Left</option>
                              <option value={'center'}>Center</option>
                              <option value={'right'}>Right</option>
                            </Input>

                          </FormGroup>
                        </>
                      }
                      {this.state?.link?.itemList[idx]?.showTable &&
                        <>
                          <h4>Table Data</h4>

                          <div style={{ display: 'flex', flexDirection: 'column' }}>
                            <textarea
                              rows='3'
                              placeholder='Paste your excel form data here...'
                              // onPaste={this.handlePaste}
                              onChange={(event) => this.editItem(event, idx, 'table')}
                              value={this.state.link.itemList[idx]?.rawStr}
                            />
                            {this.state.link.itemList[idx]?.tableArray && this.tableRow(this.state.link.itemList[idx]?.tableArray, idx)}

                          </div>

                          <FormGroup style={{
                            display: 'flex',
                            width: '50%',
                            justifyContent: 'space-between',
                            marginTop: '0px'
                          }}>
                            <InputGroup className='input-group-alternative mb-3'>
                              <InputGroupAddon addonType='prepend'>
                                <InputGroupText>
                                  <i className='ni ni-hat-3' />
                                </InputGroupText>
                              </InputGroupAddon>
                              <Input placeholder='Order' type='text' name='order' value={item.order}
                                onChange={(event) => this.editItem(event, idx)} />
                            </InputGroup>
                          </FormGroup>
                        </>
                      }

                      <Row key={idx}>
                        {this.state?.link?.itemList[idx]?.showAllButton &&
                          <>
                            <Col xl={12} className={'mb-2'}>

                              {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showText && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                <Button onClick={() => this.addButtonHandler('showText', idx)}
                                  className={'btn btn-primary'}><i
                                    className={'fa fa-clipboard'} /></Button>
                              }
                              {this.state.link.itemList[idx].showText && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'flex-start'
                                }}>
                                  {!this.state.link.itemList.length <= 1 &&
                                    <>
                                      <Button onClick={() => {
                                        this.addButtonHandler('showText', idx)
                                        this.removeItem(idx)
                                      }}
                                        style={{ backgroundColor: 'red', color: 'white' }}
                                        className={'btn'}><i
                                          className={'fa fa-minus'} /></Button>
                                    </>
                                  }
                                  {this.state.link.itemList[idx].addButton &&
                                    <Button onClick={() => this.addItem(idx)}
                                      className={'btn'}><i
                                        className={'fa fa-plus'} /></Button>
                                  }
                                </div>
                              }

                              {!this.state?.link?.itemList[idx]?.showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showVideo &&
                                <Button onClick={() => this.addButtonHandler('showImage', idx)}
                                  className={'btn btn-primary'}><i
                                    className={'fa fa-image'} /></Button>

                              }
                              {this.state.link.itemList[idx].showImage && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showVideo &&
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'flex-start'
                                }}>
                                  <Button onClick={() => {
                                    this.addButtonHandler('showImage', idx)
                                    this.removeItem(idx)
                                  }}
                                    className={'btn'}
                                    style={{ backgroundColor: 'red', color: 'white' }}><i
                                      className={'fa fa-minus'} /></Button>
                                  {this.state.link.itemList[idx].addButton &&
                                    <Button onClick={() => this.addItem(idx)}
                                      className={'btn'}><i
                                        className={'fa fa-plus'} /></Button>
                                  }
                                </div>
                              }


                              {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showVideo && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                <Button onClick={() => this.addButtonHandler('showVideo', idx)}
                                  className={'btn btn-primary'}><i
                                    className={'fa fa-video'} /></Button>
                              }
                              {this.state.link.itemList[idx].showVideo && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'flex-start'
                                }}>
                                  <Button onClick={() => {
                                    this.addButtonHandler('showVideo', idx)
                                    this.removeItem(idx)
                                  }}
                                    className={'btn'}
                                    style={{ backgroundColor: 'red', color: 'white' }}><i
                                      className={'fa fa-minus'} /></Button>
                                  {this.state.link.itemList[idx].addButton &&
                                    <Button onClick={() => this.addItem(idx)}
                                      className={'btn'}><i
                                        className={'fa fa-plus'} /></Button>
                                  }
                                </div>

                              }

                              {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showTable && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage &&
                                <Button onClick={() => this.addButtonHandler('showTable', idx)}
                                  className={'btn btn-primary'}><i
                                    className={'fa fa-table'} /></Button>
                              }
                              {this.state.link.itemList[idx].showTable && !this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'flex-start'
                                }}>
                                  <Button onClick={() => {
                                    this.addButtonHandler('showTable', idx)
                                    this.removeItem(idx)
                                  }}
                                    className={'btn'}
                                    style={{ backgroundColor: 'red', color: 'white' }}><i
                                      className={'fa fa-minus'} /></Button>
                                  {this.state.link.itemList[idx].addButton &&
                                    <Button onClick={() => this.addItem(idx)}
                                      className={'btn'}><i
                                        className={'fa fa-plus'} /></Button>
                                  }
                                </div>

                              }

                              {!this.state?.link?.itemList[idx]?.showQuiz && !this.state?.link?.itemList[idx]?.showButton && !this.state?.link?.itemList[idx]?.showSubTitle && !this.state.link.itemList[idx].showVideo && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showTable &&
                                <Button onClick={() => this.addButtonHandler('showButton', idx)}
                                  className={'btn btn-primary'}><i
                                    className={'fa fa-tablet'} /></Button>
                              }
                              {this.state.link.itemList[idx].showButton && !this.state?.link?.itemList[idx]?.showQuiz && !this.state.link.itemList[idx].showTable && !this.state.link.itemList[idx].showText && !this.state.link.itemList[idx].showImage && !this.state.link.itemList[idx].showVideo &&
                                <div style={{
                                  display: 'flex',
                                  alignItems: 'flex-start'
                                }}>
                                  <Button onClick={() => {
                                    this.addButtonHandler('showButton', idx)
                                    this.removeItem(idx)
                                  }}
                                    className={'btn'}
                                    style={{ backgroundColor: 'red', color: 'white' }}><i
                                      className={'fa fa-minus'} /></Button>
                                  {this.state.link.itemList[idx].addButton &&
                                    <Button onClick={() => this.addItem(idx)}
                                      className={'btn'}><i
                                        className={'fa fa-plus'} /></Button>
                                  }
                                </div>
                              }


                            </Col>
                          </>
                        }
                      </Row>
                    </div>
                  ))}
                  <div className="approveDenyButtonsDiv">
                    <Button color="primary" type="button" onClick={() => this.toggleState("approveModal")}>
                      Approve
                    </Button>
                    <Button color="warning" type="button" onClick={() => this.toggleState("denyModal")}>
                      Deny
                    </Button>
                  </div>
                </div>
              </Col>
            </Row>
            <Row>
              <div style={{ width: '100%', marginTop: '20px', paddingLeft: 30, paddingRight: 30 }}>
                <MaterialTable
                  title="Assignment History"
                  columns={[
                    { title: 'Coure Name', field: 'course' },
                    { title: 'Lesson Number', field: 'lesson.order' },
                    { title: 'Lesson Name', field: 'lesson_name' },
                    { title: 'Status', field: 'status' },
                    {
                      title: 'File', field: 'image', render: rowData =>
                        <>
                          <Button
                            onClick={() => {
                              this.setRowData(rowData)
                            }
                            }
                            class="btn btn-primary btn-sm">
                            <i class="ni ni-zoom-split-in"></i>
                          </Button>
                          <Chip label={rowData.images.length} style={{ marginRight: 10, marginLeft: 10 }} />
                        </>
                    },
                    { title: 'Remarks', field: 'remarks', render: rowData => rowData.remarks ? <> {ReactHtmlParser(rowData.remarks.substring(0, 150))} </> : 'Not Available' },
                    { title: 'Date', field: 'modified_at', render: rowData => <> {moment(rowData.modified_at).format("dddd, MMMM Do YYYY, h:mm:ss a")} </> },

                  ]}
                  options={{
                    columnsButton: true,
                    pageSize: 5,
                    actionsColumnIndex: -1,
                    headerStyle: {
                      backgroundColor: '#6772E5',
                      color: '#FFF'
                    }
                  }}
                  data={this.state.historyData}
                // actions={[
                //     {
                //     icon: 'save',
                //     tooltip: 'Save User',
                //     onClick: (event, rowData) => alert("You saved " + rowData.name)
                //     }
                // ]}
                />
              </div>
            </Row>

          </Dialog>
          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.approveModal}
            toggle={() => this.toggleState("approveModal")}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirmation
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleState("approveModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <h4>Are you sure you want to approve this assignment?</h4>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleState("approveModal")}
              >
                Cancel
              </Button>
              <Button color="primary" type="button"
                onClick={() => {
                  this.setState({ disableSubmit: true })
                  let data = {
                    remarks: this.state.editorContentHtml,
                    status: 'Approved',
                    objects: this.handleObjects()?.[0] === null ? [] : this.handleObjects()

                  }
                  this.setState({ disableSubmit: false })
                  Services.updateAssignment(this.props.selectedRow.id, data).then((response) => {
                    // this.setState({data: response})
                    this.toggleState("approveModal")
                    this.props.closePanel();
                    this.props.getPendingAssignment('students/assigment/?status=Pending');
                    this.setState({ remarks: '', detailsPanel: false })
                    this.setState({ disableSubmit: false })
                    this.props.enqueueSnackbar('Successfully Submitted', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      }
                    }
                    )
                  })
                    .catch((error) => {
                      this.setState({ disableSubmit: false })
                      this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                      )
                    })

                }} disabled={this.state.disableSubmit}>
                Yes
              </Button>
            </div>
          </Modal>




          <Modal
            className="modal-dialog-centered"
            isOpen={this.state.denyModal}
            toggle={() => this.toggleState("denyModal")}
          >
            <div className="modal-header">
              <h5 className="modal-title" id="exampleModalLabel">
                Confirmation
              </h5>
              <button
                aria-label="Close"
                className="close"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleState("denyModal")}
              >
                <span aria-hidden={true}>×</span>
              </button>
            </div>
            <div className="modal-body">
              <h4>Are you sure you want to deny this assignment?</h4>
            </div>
            <div className="modal-footer">
              <Button
                color="secondary"
                data-dismiss="modal"
                type="button"
                onClick={() => this.toggleState("denyModal")}
              >
                Cancel
              </Button>
              <Button color="primary" type="button"
                onClick={() => {
                  this.setState({ disableSubmit: true })
                  let data = {
                    remarks: this.state.editorContentHtml,
                    status: 'Denied',
                    objects: this.handleObjects()?.[0] === null ? [] : this.handleObjects()
                  }
                  Services.updateAssignment(this.props.selectedRow.id, data).then((response) => {
                    // this.setState({data: response})
                    this.setState({ remarks: '', detailsPanel: false })
                    this.toggleState("denyModal");
                    this.props.closePanel();
                    this.props.getPendingAssignment('students/assigment/?status=Pending');
                    this.setState({ disableSubmit: false })
                    this.props.enqueueSnackbar('Successfully Submitted', {
                      variant: 'success',
                      anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right',
                      }
                    }
                    )
                  })
                    .catch((error) => {
                      this.setState({ disableSubmit: false })
                      this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                        variant: 'warning',
                        anchorOrigin: {
                          vertical: 'bottom',
                          horizontal: 'right',
                        }
                      }
                      )
                    })
                }}
                disabled={this.state.disableSubmit}>
                Yes
              </Button>
            </div>
          </Modal>
        </div >

        <Viewer
          zoomSpeed={0.8}
          visible={this.state.imagesModal}
          onClose={() => this.setState({ imagesModal: false })}
          images={this.state.assignmentImages2}
        />


      </>
    )
  }
}

export default withSnackbar(StudentDetailsAssignment)