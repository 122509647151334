import { Box } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogTitle from '@material-ui/core/DialogTitle'
import IconButton from '@material-ui/core/IconButton'
import CloseIcon from '@material-ui/icons/Close'
import { ContentState, EditorState, convertFromHTML, convertToRaw } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import { withSnackbar } from 'notistack'
import React from 'react'
import { Editor } from 'react-draft-wysiwyg'
import {
    Button,
    FormGroup,
    Input,
    InputGroup,
    InputGroupAddon,
    InputGroupText,
    Label
} from 'reactstrap'


const Services = require('../../RemoteServices/RemoteServices.js')




class SendNotifications extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            amount: '',
            exampleModal: true,
            title: '',
            description: '',
            shortDescription: '',
            smsMarketing: false,
            emailMarketing: false,
            viberMarketing: false,
            whatsappMarketing: false,
            notificationMarketing: false,
            callMarketing: true,
            checkbox: null,
            type: 'Notification',
            editorState: EditorState.createEmpty(),
            editorContentHtml: '',
            editorState1: this.handleInitialEditorText(this.props.studentData.student_leads.filter(item => item.stage_no === this.props.stage)?.[0]?.overall_comment) || EditorState.createEmpty(),
            editorContentHtml1: '',
            showClearModal: false,
            tab: '',
            changedTab: '',
            stageData: '',
            disabled: false,
            filterData: this.props.studentData.student_leads.filter(item => item.stage_no === this.props.stage)?.[0]


        }
        this.handleInputChange = this.handleInputChange.bind(this)
        this.handleSubmit = this.handleSubmit.bind(this)
        this.chooseFilters = this.chooseFilters.bind(this)
    }

    handleCheckboxChange = (event) => {
        const { name, value } = event.target;
        this.setState({ [name]: value }, () => {
        });
    };
    resetHandler = () => {
        this.setState({
            tab: this.state.changedTab,
            changedTab: '',
            title: '',
            description: '',
            shortDescription: '',
            editorState: EditorState.createEmpty(),
            editorContentHtml: '',
            editorState1: EditorState.createEmpty(),
            editorContentHtml1: '',
        })

    }
    openModal = (tab) => {
        this.setState({
            showClearModal: true,
            changedTab: tab,
            // tab:tab
        })
    }

    changeTab = () => {
        this.setState({
            tab: this.state.changedTab,
            showClearModal: false,
            changedTab: '',
            checkbox: null,
            title: '',
            description: '',
            shortDescription: '',
            editorState: EditorState.createEmpty(),
            editorContentHtml: '',
            editorState1: this.handleInitialEditorText(this.props.studentData?.student_leads?.filter(item => item.stage_no === this.props.stage)?.[0]?.overall_comment) || EditorState.createEmpty(),
            editorContentHtml1: '',
        })
    }

    completeStage = () => {
        let leadsData = {
            user_id: this.props.studentData.id,
            stage_no: Number(this.props.stage) + 1,
            leads_content: [],
            overall_comment: null,
        }

        Services.sendLeadsPost(leadsData).then(async (response) => {
            await this.props.getLeadList(20, Number(this.props.stage));
            await this.props.closePanel();

        })
            .catch((error) => {
                // this.props.getDetails();
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })

    }

    submtiLeadsDetail = () => {
        let leadsData = {
            user_id: this.props.studentData.id,
            stage_no: this.props.stage === 0 ? 1 : this.props.stage,
            leads_content: this.state.tab !== 'call' ? [{
                title: this.state.title.length > 0 ? this.state.title : null,
                short_description: this.state.shortDescription.length > 0 ? this.state.shortDescription : null,
                type: this.state.tab === 'viber' ? 'Viber' : this.state.tab === 'whatsapp' ? 'Whatapp' : this.state.tab === 'email' ? 'Email' : this.state.tab === 'sms' ? 'SMS' : this.state.tab === 'notification' ? 'Notification' : '',
                content: this.state.editorContentHtml || this.state.description
            }] : [],
            overall_comment: this.state.editorContentHtml1,

        }
        if (this.state.tab === 'call') {
            leadsData.is_call = this.state.checkbox;
        } else if (this.state.tab === 'viber') {
            leadsData.is_viber = this.state.checkbox;
        } else if (this.state.tab === 'whatsapp') {
            leadsData.is_whatapp = this.state.checkbox;
        } else if (this.state.tab === 'email') {
            leadsData.is_email = this.state.checkbox;
        } else if (this.state.tab === 'notification') {
            leadsData.is_notification = this.state.checkbox;
        } else if (this.state.tab === 'sms') {
            leadsData.is_sms = this.state.checkbox;
        }
        this.setState({ disabled: true })
        Services.sendLeadsPost(leadsData).then(async (response) => {
            this.props.enqueueSnackbar(`Successfully Added ${this.state.tab.toUpperCase()} `, {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            await this.props.getDetails();
            if (this.props.stage === 0) {
                this.props.setStage(1);
                await this.props.getLeadList(20, 1);
            }
            this.setState({
                disabled: false,
                open: true,
                amount: '',
                exampleModal: true,
                title: '',
                description: '',
                shortDescription: '',
                smsMarketing: false,
                emailMarketing: false,
                viberMarketing: false,
                whatsappMarketing: false,
                notificationMarketing: false,
                callMarketing: true,
                checkbox: null,
                type: 'Notification',
                editorState: EditorState.createEmpty(),
                editorContentHtml: '',
                editorState1: this.handleInitialEditorText(this.props.studentData.student_leads.filter(item => item.stage_no === this.props.stage)?.[0]?.overall_comment) || EditorState.createEmpty(),
                editorContentHtml1: '',
                showClearModal: false,
                tab: '',
                changedTab: '',
                stageData: '',
                filterData: this.props.studentData.student_leads.filter(item => item.stage_no === this.props.stage)?.[0]
            })

        })
            .catch((error) => {
                // this.props.getDetails();
                this.setState({ disabled: false })
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })

    }

    onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    }
    onEditorStateChange1 = (editorState1) => {
        const rawContentState = convertToRaw(editorState1.getCurrentContent())
        this.setState({
            editorState1,
            editorContentHtml1: draftToHtml(rawContentState)
        })
    }


    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')

        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }


    handleInputChange(event) {
        const target = event.target
        const value = target.value
        const name = target.name

        this.setState({
            [name]: value
        })
    }
    returnHtmlBlock(text) {
        const processedHTML = convertFromHTML(text)
        return ContentState.createFromBlockArray(processedHTML)

    }
    handleInitialEditorText = (text) => {
        let editorState
        if (text && text !== null) {
            if (text.trim() !== '' || text.trim() !== null) {
                editorState = EditorState.createWithContent(this.returnHtmlBlock(text))
            } else {
                editorState = EditorState.createEmpty()
            }
            return editorState
        }
    }

    handleSubmit = () => {
        let leadsData = {
            student_lead_id: this.props.studentData.id,
            content_type: this.state.type,
            title: this.state.title,
            content: this.state.notificationMarketing ? this.state.editorContentHtml : this.state.description,
            short_description: this.state.shortDescription,
            // delete_after: null
        }
        Services.sendLeadsNotification(leadsData).then((response) => {
            this.props.enqueueSnackbar('Succesfully Added', {
                variant: 'success',
                anchorOrigin: {
                    vertical: 'bottom',
                    horizontal: 'right'
                }
            })
            this.props.onSuccess();
            this.setState({
                open: true,
                amount: '',
                exampleModal: true,
                title: '',
                description: '',
                shortDescription: '',
                smsMarketing: false,
                emailMarketing: false,
                viberMarketing: false,
                whatsappMarketing: false,
                notificationMarketing: false,
                callMarketing: true,
                checkbox: null,
                type: 'Notification',
                editorState: EditorState.createEmpty(),
                editorContentHtml: '',
                editorState1: this.handleInitialEditorText(this.props.studentData.student_leads.filter(item => item.stage_no === this.props.stage)?.[0]?.overall_comment),
                editorContentHtml1: '',
                showClearModal: false,
                tab: '',
                changedTab: '',
                stageData: '',
                filterData: this.props.studentData.student_leads.filter(item => item.stage_no === this.props.stage)?.[0]
            })
        })
            .catch((error) => {
                this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
                    variant: 'warning',
                    anchorOrigin: {
                        vertical: 'bottom',
                        horizontal: 'right'
                    }
                }
                )
            })

    }

    toggleModal = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    handleConfirm = (state) => {


    }

    chooseFilters = async (event) => {
        this.setState({ tab: event })
    }


    render() {
      

        const { checkbox, filterData } = this.state
        return (
            <>
                <div className='mt-1'>
                    <div
                        style={{
                            display: 'flex',
                            flexDirection: 'row',
                            gap: '20px',
                            height: '50px',
                            marginBottom: '10px'
                        }}>
                        <div style={{
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '33.33%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.tab === 'call' ? '2px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer',
                        }}
                            onClick={() => {
                                if (filterData?.is_call === null || filterData == null || filterData === undefined) {
                                    if (this.state.tab !== '' && this.state.tab !== 'call') {
                                        this.openModal('call');
                                    } else {
                                        this.chooseFilters('call')
                                    }
                                }
                            }
                            }
                        >
                            <span style={{ fontSize: 14, color: 'grey' }}
                                className={'font-weight-bold'}>Call</span>
                            <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                {filterData?.is_call && filterData?.is_call !== null &&
                                    <i className="fas fa-check" style={{ color: 'green', fontSize: 18 }}></i>}
                            </div>
                        </div>
                        <div style={{
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '33.33%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.tab === 'notification' ? '2px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                if (filterData?.is_notification === null || filterData === null || filterData === undefined) {
                                    if (this.state.tab !== '' && this.state.tab !== 'notification') {
                                        this.openModal('notification');
                                    } else {
                                        this.chooseFilters('notification')
                                    }
                                }
                            }}
                        >
                            <span style={{ fontSize: 14, color: 'grey' }} className={'font-weight-bold'}>Notification</span>
                            <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                {filterData?.is_notification && filterData?.is_notification !== null &&
                                    <i className="fas fa-check" style={{ color: 'green', fontSize: 18 }}></i>}
                            </div>
                        </div>
                        <div style={{
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '33.33%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.tab === 'email' ? '2px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                if (filterData?.is_email === null || filterData == null || filterData === undefined) {
                                    if (this.state.tab !== '' && this.state.tab !== 'email') {
                                        this.openModal('email');
                                    } else {
                                        this.chooseFilters('email')
                                    }
                                }
                            }}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }}
                                className={'font-weight-bold'}>Email</span>
                            <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                {filterData?.is_email && filterData?.is_email !== null &&
                                    <i className="fas fa-check" style={{ color: 'green', fontSize: 18 }}></i>}
                            </div>
                        </div>
                        <div style={{
                            position: 'relative',

                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '33.33%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.tab === 'sms' ? '2px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                if (filterData?.is_sms === null || filterData == null || filterData === undefined) {
                                    if (this.state.tab !== '' && this.state.tab !== 'sms') {
                                        this.openModal('sms');
                                    } else {
                                        this.chooseFilters('sms')
                                    }
                                }
                            }}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }}
                                className={'font-weight-bold'}>SMS</span>
                            <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                {filterData?.is_sms && filterData?.is_sms !== null &&
                                    <i className="fas fa-check" style={{ color: 'green', fontSize: 18 }}></i>}
                            </div>
                        </div>
                        <div style={{
                            position: 'relative',

                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '33.33%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.tab === 'viber' ? '2px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                if (filterData?.is_viber === null || filterData == null || filterData === undefined) {
                                    if (this.state.tab !== '' && this.state.tab !== 'viber') {
                                        this.openModal('viber');
                                    } else {
                                        this.chooseFilters('viber')
                                    }
                                }
                            }}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }}
                                className={'font-weight-bold'}>Viber</span>
                            <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                {filterData?.is_viber && filterData?.is_viber !== null &&
                                    <i className="fas fa-check" style={{ color: 'green', fontSize: 18 }}></i>}
                            </div>
                        </div>
                        <div style={{
                            position: 'relative',
                            justifyContent: 'center',
                            alignItems: 'center',
                            width: '33.33%',
                            height: '100%',
                            borderRadius: 5,
                            backgroundColor: 'white',
                            boxShadow: '2px 2px 2px 0px lightgrey',
                            display: 'flex',
                            border: this.state.tab === 'whatsapp' ? '2px solid blue' : '1px solid lightgrey',
                            cursor: 'pointer'
                        }}
                            onClick={() => {
                                if (filterData?.is_whatapp === null || filterData == null || filterData === undefined) {
                                    if (this.state.tab !== '' && this.state.tab !== 'whatsapp') {
                                        this.openModal('whatsapp');
                                    } else {
                                        this.chooseFilters('whatsapp')
                                    }
                                }
                            }}
                        >
                            <span style={{ fontSize: 18, color: 'grey' }}
                                className={'font-weight-bold'}>Whatsapp</span>
                            <div style={{ position: 'absolute', top: 5, right: 5 }}>
                                {filterData?.is_whatapp && filterData?.is_whatapp !== null &&
                                    <i className="fas fa-check" style={{ color: 'green', fontSize: 18 }}></i>}
                            </div>
                        </div>
                    </div>

                    {this.state.tab !== '' ?
                        <>
                            <br />
                            <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <Label style={{ fontWeight: 'bold', fontSize: 20 }}>Status</Label>
                                <label style={{ fontSize: 18 }}>
                                    <input
                                        style={{ height: '15px', width: '15px' }}
                                        type="checkbox"
                                        name="checkbox"
                                        value="Yes"
                                        checked={checkbox === 'Yes'}
                                        onChange={this.handleCheckboxChange}
                                    /> &nbsp;Yes
                                </label>
                                <label style={{ fontSize: 18 }}>
                                    <input
                                        style={{ height: '15px', width: '15px' }}
                                        type="checkbox"
                                        name="checkbox"
                                        value="No"
                                        checked={checkbox === 'No'}
                                        onChange={this.handleCheckboxChange}
                                    /> &nbsp; No
                                </label>
                                <label style={{ fontSize: 18 }}>
                                    <input
                                        style={{ height: '15px', width: '15px' }}
                                        type="checkbox"
                                        name="checkbox"
                                        value="Invalid"
                                        checked={checkbox === 'Invalid'}
                                        onChange={this.handleCheckboxChange}
                                    />&nbsp; Invalid
                                </label>
                            </div>
                            <br />

                            {(checkbox !== null) &&
                                <>
                                    {this.state.tab !== 'call' ?
                                        <>
                                            {(this.state.tab === 'notification' || this.state.tab === 'email') ?
                                                <FormGroup>
                                                    <InputGroup className='input-group-alternative mb-3'>
                                                        <InputGroupAddon addonType='prepend'>
                                                            <InputGroupText>
                                                                <i className='ni ni-hat-3' />
                                                            </InputGroupText>
                                                        </InputGroupAddon>
                                                        <Input placeholder={this.state.tab === 'notification' ? 'Title' : 'Subject'} type='text' name='title' value={this.state.title}
                                                            onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </FormGroup>
                                                :
                                                ''
                                            }
                                            {this.state.tab === 'notification' ?
                                                <FormGroup>
                                                    <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between' }}>
                                                        <Label>Short Description</Label>
                                                        {this.state.shortDescription.length > 0 && <span style={{ fontSize: 22 }} className={'font-weight-bold'}>{this.state.shortDescription.length}</span>}
                                                    </div>
                                                    <InputGroup className='input-group-alternative mb-3'>
                                                        <Input rows={4} placeholder='Short Description' type='textarea' name='shortDescription' value={this.state.shortDescription}
                                                            onChange={this.handleInputChange} />
                                                    </InputGroup>
                                                </FormGroup>
                                                :
                                                ''
                                            }

                                            <FormGroup>
                                                {this.state.tab === 'viber' || this.state.tab === 'whatsapp' || this.state.tab === 'sms' ?
                                                    <>
                                                        <div style={{ display: 'flex', width: '100%', justifyContent: 'flex-end' }}>
                                                            {this.state.tab === 'sms' && this.state.description.length > 0 && <span style={{ fontSize: 22 }} className={'font-weight-bold'}>{this.state.description.length}/130</span>}
                                                        </div>

                                                        <InputGroup className='input-group-alternative'>

                                                            <Input
                                                                id='exampleFormControlTextarea1'
                                                                placeholder='Description / Message'
                                                                rows={10}
                                                                type='textarea'
                                                                name='description'
                                                                value={this.state.description}
                                                                onChange={this.handleInputChange}
                                                            />
                                                        </InputGroup>
                                                    </>
                                                    :
                                                    <Editor
                                                        editorStyle={{
                                                            border: '1px solid black',
                                                            padding: '5px',
                                                            borderRadius: '2px',
                                                            height: '310px',
                                                            width: '100%'
                                                        }}
                                                        placeholder='Description'
                                                        editorState={this.state.editorState}
                                                        wrapperClassName='demo-wrapper'
                                                        editorClassName='demo-editor'
                                                        onEditorStateChange={this.onEditorStateChange.bind(this)}
                                                        toolbar={{
                                                            inline: { inDropdown: true },
                                                            list: { inDropdown: true },
                                                            textAlign: { inDropdown: true },
                                                            link: { inDropdown: true },
                                                            history: { inDropdown: true },
                                                            image: {
                                                                uploadCallback: this.uploadImageCallBack,
                                                                previewImage: true,
                                                                defaultSize: { width: '100%', height: 'auto' }
                                                            }
                                                        }}
                                                    />
                                                }
                                            </FormGroup>
                                            <br />
                                            <Label style={{ fontSize: 18, fontWeight: 'bold' }}> Main Comment</Label>
                                            <Editor
                                                editorStyle={{
                                                    border: '1px solid black',
                                                    padding: '5px',
                                                    borderRadius: '2px',
                                                    height: '310px',
                                                    width: '100%'
                                                }}
                                                placeholder='Description'
                                                editorState={this.state.editorState1}
                                                wrapperClassName='demo-wrapper'
                                                editorClassName='demo-editor'
                                                onEditorStateChange={this.onEditorStateChange1.bind(this)}
                                                toolbar={{
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                    image: {
                                                        uploadCallback: this.uploadImageCallBack,
                                                        previewImage: true,
                                                        defaultSize: { width: '100%', height: 'auto' }
                                                    }
                                                }}
                                            />
                                            <br />
                                        </> :
                                        <>
                                            <Label style={{ fontSize: 18, fontWeight: 'bold' }}> Main Comment</Label>
                                            <Editor
                                                editorStyle={{
                                                    border: '1px solid black',
                                                    padding: '5px',
                                                    borderRadius: '2px',
                                                    height: '310px',
                                                    width: '100%'
                                                }}
                                                placeholder='Description'
                                                editorState={this.state.editorState1}
                                                wrapperClassName='demo-wrapper'
                                                editorClassName='demo-editor'
                                                onEditorStateChange={this.onEditorStateChange1.bind(this)}
                                                toolbar={{
                                                    inline: { inDropdown: true },
                                                    list: { inDropdown: true },
                                                    textAlign: { inDropdown: true },
                                                    link: { inDropdown: true },
                                                    history: { inDropdown: true },
                                                    image: {
                                                        uploadCallback: this.uploadImageCallBack,
                                                        previewImage: true,
                                                        defaultSize: { width: '100%', height: 'auto' }
                                                    }
                                                }}
                                            />
                                            <br />
                                        </>

                                    }


                                    {filterData && (filterData.is_call === null || filterData.is_sms === null || filterData.is_whatapp === null || filterData.is_notification === null || filterData.is_viber === null || filterData.is_email === null) ?
                                        <>
                                            {checkbox !== null && this.state.editorContentHtml1 !== '' &&
                                                <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                                                    <Button disabled={this.state.disabled} style={{ width: '600px', height: '50px' }} color='primary' onClick={this.submtiLeadsDetail}>
                                                        Submit
                                                    </Button>
                                                </div>
                                            }
                                        </> :
                                        <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                                            <Button disabled={this.state.disabled} style={{ width: '600px', height: '50px' }} color='primary' onClick={this.submtiLeadsDetail}>
                                                Submit
                                            </Button>
                                        </div>

                                    }
                                </>
                            }


                        </> :
                        ''
                    }
                    {filterData &&
                        filterData.is_call !== null &&
                        filterData.is_sms !== null &&
                        filterData.is_whatapp !== null &&
                        filterData.is_notification !== null &&
                        filterData.is_viber !== null &&
                        filterData.is_email !== null &&
                        <>
                            <br />
                            <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                                <Button style={{ width: '600px', height: '50px' }} color='primary' onClick={() => this.completeStage()}>
                                    Submit Stage Complete
                                </Button>
                            </div>
                        </>
                    }

                </div>

                <Dialog fullWidth
                    maxWidth="sm" open={this.state.showClearModal} onClose={() => this.setState({ showClearModal: false })}>
                    <DialogTitle className='m-0 p-2'>
                        <Box display="flex" alignItems="center">
                            <Box flexGrow={1} >Confirmation</Box>
                            <Box>
                                <IconButton onClick={() => this.setState({ showClearModal: false })}>
                                    <CloseIcon />
                                </IconButton>
                            </Box>
                        </Box>
                    </DialogTitle>
                    <DialogContent className='m-0 p-2'>
                        <span>Your previous data will be deleted if you change to another tab are you sure about it? </span>
                    </DialogContent>
                    <DialogActions>
                        <Button color='danger' onClick={() => this.setState({ showClearModal: false })}>Cancel</Button>
                        <Button color='success' onClick={() => this.changeTab()}>Sure</Button>
                    </DialogActions>
                </Dialog>
            </>
        )
    }
}

export default withSnackbar(SendNotifications)