/* eslint-disable react/jsx-no-target-blank */
/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable no-use-before-define */
import React, { useState, useEffect } from 'react'
import {  Modal, Button } from "reactstrap";
import Avatar from "../../assets/img/dummyUser.png";
import MaterialTable from 'material-table'
import './studentDetails.css'
import moment from 'moment'
const Services = require('../../RemoteServices/RemoteServices.js');


const UserProfilePage = (props) => {

    let [userDetails, setUserDetails] = useState(props.userId)
    let [referralListModal, setReferralListModal] = useState(false)
    let [followListModal, setFollowListModal] = useState(false)
    let [followTableTitle, setFollowTableTitle] = useState('')
    let [followList, setFollowList] = useState([])
    let [pageLoading, setPageLoading] = useState(false)
    let [detailResponse, setDetailResponse] = useState([])
    let { userId } = props

    const toggleReferralModal = () => {
        setReferralListModal(!referralListModal)
    }

    const toggleFollowModal = () => {
        setFollowListModal(!followListModal)
    }

    useEffect(() => {
        // getUserDetails()
    }, [])


    const getUserDetails = () => {
        Services.getStudentDetailsNew(userId).then((response) => {

            setUserDetails(response.results[0])
            setDetailResponse(response.results)
            setPageLoading(false)

        })
    }

    const getFollowers = () => {
        Services.getUserFollowers(userId).then((response) => {
           //console.log(response.results)
            setFollowList(response.results)
        })
    }

    const getFollowing = () => {
        Services.getUserFollowing(userId).then((response) => {
            setFollowList(response.results)

        })
    }


    const handleGetFollowers = () => {
        getFollowers()
        toggleFollowModal()
        setFollowTableTitle('Followers')
    }

    const handleGetFollowing = () => {
        getFollowing()
        toggleFollowModal()
        setFollowTableTitle('Following')
    }

   //console.log('detail response here', detailResponse)

    let user = userDetails
    let data = userDetails


    // if (pageLoading === true || detailResponse.length === 0) {
    //     return (<div style={{ display: 'flex', justifyContent: 'center', marginTop: '40px', marginBottom: '20px' }}> <Spinner /> </div>)
    // }

    return (
        <div className="leadsDetailsTopContainer">
            <div className="studentDetailsImageContainer">
                {console.log('adfasdf', user)}
                <span>{user.full_name}'s Profile </span>
                <a href={user.profile_picture} target="_blank" style={{ position: 'relative', display: 'inline-block', width: 100, height: 100 }}> {/* Adjust width and height as needed */}
                    <img
                        className="studentDetailImage"
                        alt="student avatar"
                        src={user.profile_picture ? user.profile_picture : Avatar}
                        style={{
                            width: '100%',
                            height: '100%',
                            objectFit: 'fill',
                            borderRadius: '50%'
                        }}
                    />
                    <span
                        style={{
                            position: 'absolute',
                            bottom: 0,
                            right: 0,
                            width: 16,
                            height: 16,
                            borderRadius: '50%',
                            backgroundColor: user.is_online ? 'green' : 'lightgrey',
                            border: '2px solid white'
                        }}
                    />
                </a>
                {/* <button className="referralListButton" onClick={toggleReferralModal}> Referral List</button> */}
            </div>
            <div className="studentDetailsTopInfoContainer">
                <div className="studentDetailsInfoTopButtons">
                    <div style={{ backgroundColor: 'indigo' }}>
                        <div className="userIdInfoDiv" style={{ color: 'white' }}>
                            <span style={{color:'white'}}> User ID - {user.id}</span>
                            {user?.is_online ? "" :
                                <span style={{ color: 'white', fontSize: 14 }}>Offline: {moment(user?.last_online).fromNow()}</span>
                            }
                        </div>
                    </div>
                    {/* <div className="studentTopButtonsDiv">
                        <button onClick={handleGetFollowers}>Followers: {user.followers_count}</button>
                        <button onClick={handleGetFollowing}>Following: {user.following_count}</button>
                        <button>Post Count: {user.post_count}</button>
                        <button>Post Likes: {user.posts_like_count} </button>
                    </div> */}

                </div>
                <div className="studentDetailsTopInfoInnerContainer1">
                    <ul>
                        <li> <i class="fas fa-envelope"></i> Email: <span> {user.email||'N/A'}
                            &nbsp;{user?.email_verified && <i style={{ color: 'white', fontSize: 18 }} className={' fa fa-check-circle'}></i>}
                        </span> </li>
                        {/* <li> <i class="fas fa-venus-mars"></i> Gender : <span> {user.gender} </span> </li> */}

                    </ul>
                    <ul>


                        <li> <i class="fas fa-phone-square-alt"></i> Phone : <span> {user.phone_number||'N/A'} </span> </li>
                        {/* <li> <i class="fas fa-receipt"></i> Roll Number : <span> {data.id} </span> </li> */}

                    </ul>
                    <ul>
                        {/* <li> <i class="far fa-calendar"></i> Date of Birth: <span> {user.dob} </span> </li> */}
                        {/* <li> <i class="fas fa-home"></i> From : <span> {user.address} </span> </li> */}
                    </ul>
                </div>



            </div>

            <Modal
                className="modal-dialog-centered"
                isOpen={referralListModal}
                toggle={toggleReferralModal}
            >
                <div className="modal-header">
                    {/* <h4 className="modal-title" id="modal-title-default"> 
                  Referrals List
                </h4> */}
                    <Button
                        size="sm"
                        className="ml-auto"
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleReferralModal}
                    >
                        Close
                    </Button>
                </div>
                {/* <div className="modal-body"> */}
                <MaterialTable
                    columns={[
                        { title: 'Full name', field: 'full_name' },
                        { title: 'Email', field: 'email' },
                    ]}
                    data={user.referrals}
                    title="Referrals List"
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#6772E5',
                            color: '#FFF'
                        }
                    }}
                />
                {/* </div> */}

            </Modal>

            <Modal
                className="modal-dialog-centered"
                isOpen={followListModal}
                toggle={toggleFollowModal}
            >
                <div className="modal-header">
                    {/* <h4 className="modal-title" id="modal-title-default"> 
                  Referrals List
                </h4> */}
                    <Button
                        size="sm"
                        className="ml-auto"
                        color="primary"
                        data-dismiss="modal"
                        type="button"
                        onClick={toggleFollowModal}
                    >
                        Close
                    </Button>
                </div>
                {/* <div className="modal-body"> */}
                <MaterialTable
                    columns={[
                        { title: 'Avatar', field: 'imageUrl', render: (rowData) => <img alt="User Avatar" src={followTableTitle === 'Followers' ? rowData?.followers?.profile_picture : rowData?.following?.profile_picture} style={{ width: 40, borderRadius: '50%' }} /> },
                        { title: 'Full name', field: followTableTitle === 'Followers' ? 'followers.full_name' : 'following.full_name' },

                    ]}
                    data={followList}
                    title={followTableTitle}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#6772E5',
                            color: '#FFF'
                        }
                    }}
                />
                {/* </div> */}

            </Modal>
        </div>
    )
}

export default UserProfilePage