import React from 'react'
import { withSnackbar } from 'notistack'

import { Row, Col, Alert, Modal, Label } from 'reactstrap'

import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import ListItemText from '@material-ui/core/ListItemText'
import ListItem from '@material-ui/core/ListItem'
import List from '@material-ui/core/List'
import Divider from '@material-ui/core/Divider'
import AppBar from '@material-ui/core/AppBar'
import Toolbar from '@material-ui/core/Toolbar'
import IconButton from '@material-ui/core/IconButton'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'

import AlarmIcon from '@material-ui/icons/Create'
import MaterialTable from 'material-table'
import UpdateAmount from 'components/Student/components/UpdateAmount'
import UpdateCurrentLesson from 'components/Student/components/UpdateCurrentLesson'
import SendNotifications from 'components/Student/components/SendNotifications'
import UpdateAssignmentVisibility from 'components/Student/components/UpdateAssignmentVisibility'
import Viewer from 'react-viewer'
import ReactHtmlParser from 'react-html-parser'
import Chip from '@material-ui/core/Chip'
import UserProfilePage from './UserProfilePage'
import { AddCircle,} from '@material-ui/icons'
import PaymentStatus from '../Modal/PaymentStatus'
import moment from 'moment'
import StudentHistoryDetail from '../Modal/StudentHistoryDetail'
import PromoCode from '../Modal/PromoCode'
import Icon from '@material-ui/core/Icon'
import AssignmentDescription from './AssignmnetDescription'

import LeadsDetail from './components/LeadsDetail'

const Services = require('../../RemoteServices/RemoteServices.js')


class StudentDetails extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            open: true,
            showDelieveredAmount: false,
            showCurrentLessonUpdate: false,
            showAssignmentVisibilityUpdate: false,
            showCreateNotifications: false,
            historyData: [],
            studentDetails: {
                current_lesson: {
                    title: ''
                }
            },
            studentDetailsNew: {},
            studentId: this.props?.studentData?.user?.id,
            assignmentImages: [],
            imagesModal: false,
            referralListModal: false,
            followListModal: false,
            followList: [],
            followListNext: '',
            followListPrevious: '',
            followTableTitle: '',
            pageLoading: true,
            noteDetail: [],
            paymentHistory: false,
            historyDetail: false,
            studentHistoryData: '',
            promoCode: '',
            promoCodeDetails: [],
            showRemarks: false,
            assignmentRemarks: ''
        }
        this.openImagesInNewTab = this.openImagesInNewTab.bind(this)
    }


    async componentDidMount() {
        await this.getAssignmentHistory()
        await this.getDetails()
        await this.getStudentNotesDetail()
        await this.getPromoCodeDetail()
    }




    async getAssignmentHistory() {
        const userId = this.props.studentData.id
        //console.log(userId)
        Services.getAssignmentListHistory(userId).then((response) => {
            this.setState({ historyData: response.results.reverse() })
        })
        await this.getDetails()
    }

    async getDetails() {
        const userId = this.props.studentData.id
        //console.log(this.props.studentData)
        await Services.getStudentsDetails(userId).then((response) => {
            this.setState({ studentDetails: response, pageLoading: false })
        })
    }

    getStudentNotesDetail = async (id) => {
        const userId = id ? id : this.props.studentData.id
        await Services.getStudentNotes(userId).then((response) => {
            this.setState({ noteDetail: response.results })
        })
    }

    getPromoCodeDetail = async (id) => {
        const userId = id ? id : this.props.studentData.user.id
        await Services.getPromoCode(userId).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                promoCodeDetails: response
            }))
        }).catch((e) => {
            console.log('asdf', e)
        })
    }

    async openImagesInNewTab() {
        //console.log('reached here', this.state.selectedRow)
        const images = []
        if (this.state.selectedRow) {
            let userAssignmentImages = this.state.selectedRow.images
            userAssignmentImages.forEach((image) => {
                images.push({ src: image.image, alt: '' })
                // window.open(image.image, "_blank")
            })
            this.setState({ assignmentImages: images })
            this.setState({ imagesModal: true })
            //console.log(this.state.assignmentImages)
        }
    }

    async setRowData(rowData) {
        await this.setState({ selectedRow: rowData })
        await this.openImagesInNewTab()
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }

    render() {
        const data = this.state.studentDetails
        const user = data.user
        // const course = data.current_course ? data.current_course : 'NA'
        let currentLesson = data.current_lesson !== null ? data.current_lesson.title : 'NA'
        let currentLessonNumber = data.current_lesson !== null ? data.current_lesson.order : 'NA'
        return (this.state.pageLoading ?
            <> Loading </> :
            <>
                <div>

                    <Dialog style={{ zIndex: 800 }} fullScreen open={this.state.open} onClose={() => {
                        this.props.closePanel()
                    }} TransitionComponent={this.Transition}>

                        <AppBar color='info' style={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton edge='start' color='inherit' onClick={() => {
                                    this.props.closePanel()
                                }} aria-label='close'>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant='h6' style={{
                                    marginLeft: 20,
                                    flex: 1
                                }}>
                                    <h2>User Details</h2>
                                </Typography>
                                <Button color='primary' type='button' onClick={() => {
                                    // this.props.closePanel()
                                    this.setState({ showCreateNotifications: true })
                                }}>
                                    SEND SMS / NOTIFICATION / E-MAIL
                                </Button>
                            </Toolbar>
                        </AppBar>


                        <UserProfilePage userId={this.props.studentData.new_id ? this.props.studentData.new_id : this.props.studentData.id} />

                        <Row style={{ padding: 30 }}>
                            <Col left lg='3' xl='3' style={{ text: 'left' }}>
                                <List>
                                    <ListItem>
                                        <ListItemText primary='Roll. No : Name'
                                            secondary={data.id + ' : ' + user.full_name} />
                                    </ListItem>


                                </List>
                            </Col>
                            <Col left lg='3' xl='3' style={{ text: 'left' }}>
                                <List>


                                    {/* <ListItem >
                                        <ListItemText primary="Earned Amount" secondary={data.paid_referrals * 1000} />
                                    </ListItem> */}
                                    <ListItem>
                                        {
                                            this.state.showDelieveredAmount ?
                                                <>
                                                    <UpdateAmount studentData={data} onclose={() => {
                                                        this.getDetails()
                                                        this.setState({ showDelieveredAmount: false })
                                                    }}></UpdateAmount>
                                                </>
                                                :
                                                <>
                                                    <ListItemText primary='Delivered Amount'
                                                        secondary={data.delivered_amount} />
                                                    <IconButton color='primary' aria-label='add an alarm'
                                                        onClick={() => {
                                                            this.setState({ showDelieveredAmount: true })
                                                        }}>
                                                        <AlarmIcon />
                                                    </IconButton>
                                                </>
                                        }

                                    </ListItem>

                                </List>
                            </Col>
                            <Col lg='3' xl='3'>
                                <List>
                                    <ListItem>
                                        {
                                            this.state.showCurrentLessonUpdate ?
                                                <>
                                                    <UpdateCurrentLesson
                                                        studentData={data}
                                                        onclose={() => {
                                                            this.setState({ showCurrentLessonUpdate: false })
                                                            this.getDetails()
                                                        }}
                                                        onAmountUpdate={() => {
                                                            this.getDetails()
                                                        }}
                                                    ></UpdateCurrentLesson>
                                                </>
                                                :
                                                <>
                                                    <ListItemText primary='Current Lesson No : Name'
                                                        secondary={currentLessonNumber + ' : ' + currentLesson} />
                                                    <IconButton color='primary' aria-label='add an alarm'
                                                        onClick={() => {
                                                            this.setState({ showCurrentLessonUpdate: true })
                                                        }}>
                                                        <AlarmIcon />
                                                    </IconButton>
                                                </>
                                        }
                                    </ListItem>


                                </List>
                            </Col>
                            <Col left lg='3' xl='3' style={{ text: 'left' }}>
                                <List>
                                    <ListItem>
                                        {
                                            this.state.showAssignmentVisibilityUpdate ?
                                                <>
                                                    <UpdateAssignmentVisibility studentData={data} onclose={() => {
                                                        this.getDetails()
                                                        this.getAssignmentHistory()
                                                        this.setState({ showAssignmentVisibilityUpdate: false })
                                                    }}></UpdateAssignmentVisibility>
                                                </>
                                                :
                                                <>
                                                    <ListItemText primary='Assignment History Visibility'
                                                        secondary={data.hide_assignment ? 'Hidden' : 'Visible'} />
                                                    <IconButton color='primary' aria-label='add an alarm'
                                                        onClick={() => {
                                                            this.setState({ showAssignmentVisibilityUpdate: true })
                                                        }}>
                                                        <AlarmIcon />
                                                    </IconButton>
                                                </>
                                        }
                                    </ListItem>
                                </List>

                            </Col>
                        </Row>

                        {JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'SUPERUSER' || JSON.parse(localStorage.getItem('user')).role_type.toUpperCase() === 'ADMIN' ?
                            <>
                                <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10, paddingBottom: 15 }}>
                                    <Label style={{ fontSize: '20px' }}>Promo Code</Label>
                                    <IconButton style={{ marginTop: '-8px' }} color='primary' aria-label='add an alarm'
                                        onClick={() => {
                                            this.toggleState('promoCode')
                                        }}>
                                        <AddCircle />
                                    </IconButton>
                                    {this.state.promoCodeDetails.length > 0 &&
                                        <MaterialTable
                                            title='Promo Code'
                                            columns={[
                                                {
                                                    title: 'Created At',
                                                    field: 'created_at',
                                                    render: rowData =>
                                                        <span>{moment(rowData.created_at).format('DD-MM-YYYY')}</span>
                                                },
                                                {
                                                    title: 'Code',
                                                    field: 'code',
                                                    render: rowData => <span>{rowData.code}</span>
                                                },
                                                {
                                                    title: 'Discount',
                                                    field: 'discount'
                                                },
                                                {
                                                    title: 'Total Used Count',
                                                    field: 'used_count'
                                                },
                                                {
                                                    title: 'Status',
                                                    field: 'status',
                                                    render: rowData =>
                                                        <>
                                                            {rowData.status === true &&
                                                                <Icon style={{ color: 'green' }}>check</Icon>
                                                            }
                                                            {
                                                                rowData.status === false &&
                                                                <Icon style={{ color: 'red' }}>cancel</Icon>
                                                            }
                                                        </>,
                                                    lookup: { true: "Active", false: 'Inactive' }
                                                },
                                                {
                                                    title: 'Modified At',
                                                    field: 'modified_at',
                                                    render: rowData =>
                                                        <span>{moment(rowData.modified_at).format('DD-MM-YYYY')}</span>
                                                },
                                            ]}
                                            options={{
                                                pageSize: 3,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF'
                                                }
                                            }}
                                            data={this.state.promoCodeDetails}
                                            // actions={[
                                            //     {
                                            //         icon: 'info',
                                            //         tooltip: 'Details',
                                            //         isFreeAction: false,
                                            //         onClick: (event, rowData) => {
                                            //             this.toggleState('historyDetail')
                                            //             this.setState({ studentHistoryDetail: rowData })
                                            //         }
                                            //     }
                                            // ]}
                                            editable={{
                                                onRowUpdate: (newData, oldData) =>
                                                    new Promise((resolve, reject) => {
                                                        setTimeout(() => {
                                                            Services.updatePromoCode(oldData.id, {
                                                                status: newData.status,
                                                                code: newData.code.toUpperCase(),
                                                                discount: Number(newData.discount),
                                                                user: newData.user,
                                                                modified_at: new Date()
                                                            }).then((response) => {
                                                                this.props.enqueueSnackbar('Promo Code Updated Successfully', {
                                                                    variant: 'success',
                                                                    anchorOrigin: {
                                                                        vertical: 'bottom',
                                                                        horizontal: 'right'
                                                                    }
                                                                })
                                                                this.getPromoCodeDetail();
                                                            })
                                                                .catch((error) => {
                                                                    this.props.enqueueSnackbar(error.message, {
                                                                        variant: 'warning',
                                                                        anchorOrigin: {
                                                                            vertical: 'bottom',
                                                                            horizontal: 'right'
                                                                        }
                                                                    })
                                                                })
                                                            resolve()
                                                        }, 300)
                                                    })
                                            }}
                                        />
                                    }
                                </div>
                                <Divider />

                                <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10, paddingBottom: 15 }}>
                                    <Label style={{ fontSize: '20px' }}>Student Notes</Label>
                                    <IconButton style={{ marginTop: '-8px' }} color='primary' aria-label='add an alarm'
                                        onClick={() => {
                                            this.toggleState('paymentHistory')
                                        }}>
                                        <AddCircle />
                                    </IconButton>
                                    {this.state.noteDetail.length > 0 &&
                                        <MaterialTable
                                            title='Student History'
                                            columns={[
                                                {
                                                    title: 'Created At',
                                                    field: 'created_at',
                                                    render: rowData =>
                                                        <span>{moment(rowData.created_at).format('DD-MM-YYYY')}</span>
                                                },
                                                {
                                                    title: 'Comment',
                                                    field: 'comment',
                                                    render: rowData =>
                                                        <span>{rowData.comment.substring(0, 30)}
                                                        </span>
                                                },
                                                {
                                                    title: 'Image', field: 'image', render: rowData =>
                                                        <>
                                                            <a href={rowData.payment_screenshot}   target='_blank' rel='noopener noreferrer'
                                                                className='btn btn-primary btn-sm'>
                                                                <i className='ni ni-zoom-split-in'></i>
                                                            </a>
                                                        </>
                                                }
                                            ]}
                                            options={{
                                                pageSize: 3,
                                                actionsColumnIndex: -1,
                                                headerStyle: {
                                                    backgroundColor: '#6772E5',
                                                    color: '#FFF'
                                                }
                                            }}
                                            data={this.state.noteDetail}
                                            actions={[
                                                {
                                                    icon: 'info',
                                                    tooltip: 'Details',
                                                    isFreeAction: false,
                                                    onClick: (event, rowData) => {
                                                        this.toggleState('historyDetail')
                                                        this.setState({ studentHistoryDetail: rowData })
                                                    }
                                                }
                                            ]}
                                        />
                                    }
                                </div>
                                <Divider />
                            </> :
                            <></>
                        }


                        <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10, paddingBottom: 15 }}>
                            {this.state.studentDetails.wallet && this.state.studentDetails?.wallet !== null ?
                                <>
                                    <Alert className='alert-default'>
                                        Full Name: {this.state.studentDetails.wallet?.full_name || 'Not Available'}
                                        <br />
                                        Wallet
                                        Type: {this.state.studentDetails.wallet?.type ? this.state.studentDetails?.wallet?.type : 'Not Available'}
                                        <br />
                                        Bank Account / ID
                                        : {this.state.studentDetails?.wallet?.number ? this.state.studentDetails?.wallet?.number : 'Not Available'}
                                        <br />
                                        Bank Name
                                        : {this.state.studentDetails.wallet?.bank_name ? this.state.studentDetails?.wallet?.bank_name : 'Not Available'}
                                        <br />
                                    </Alert>
                                </>
                                :
                                <>
                                    <Alert color='warning'>
                                        <strong>Alert!</strong> This user has not yet submitted wallet details.
                                    </Alert>
                                </>
                            }
                        </div>
                        <Divider />
                        <div style={{ paddingLeft: 40, paddingRight: 40, paddingTop: 10 }}>
                            <MaterialTable
                                title='Assignment History'
                                columns={[
                                    { title: 'Coure Name', field: 'course' },
                                    { title: 'Lesson Number', field: 'lesson.order' },
                                    { title: 'Lesson Name', field: 'lesson_name' },
                                    { title: 'Status', field: 'status' },
                                    {
                                        title: 'File', field: 'image', render: rowData =>
                                            <>
                                                <Button
                                                    onClick={() => {
                                                        this.setRowData(rowData)
                                                    }
                                                    }
                                                    class='btn btn-primary btn-sm'>
                                                    <i class='ni ni-zoom-split-in'></i>
                                                </Button>
                                                <Chip label={rowData.images.length}
                                                    style={{ marginRight: 10, marginLeft: 10 }} />
                                            </>
                                    },
                                    {
                                        title: 'Remarks',
                                        field: 'remarks',
                                        render: rowData => <>
                                            {rowData.remarks.length > 0 ? rowData.remarks.length >= 100 ? ReactHtmlParser(rowData.remarks.substring(0, 100)) : ReactHtmlParser(rowData.remarks) : 'Not Available'}
                                            <span onClick={() => {
                                                this.setState({ assignmentRemarks: rowData })
                                                this.toggleState('showRemarks')
                                            }
                                            } style={{ border: '2px solid blue', borderRadius: 5, padding: 5, cursor: 'pointer' }}><i className='fa fa-eye'></i>&nbsp;Open Remarks</span>
                                        </>
                                    },
                                    { title: 'Date', field: 'modified_at', render: rowData => moment(rowData.modified_at).format('lll') }

                                ]}
                                options={{
                                    pageSize: 5,
                                    actionsColumnIndex: -1,
                                    headerStyle: {
                                        backgroundColor: '#6772E5',
                                        color: '#FFF'
                                    }
                                }}
                                data={this.state.historyData}
                         
                            />
                        </div>
                        <br />
                        <Divider />
                        <div style={{ paddingLeft: 30, paddingRight: 30, paddingTop: 10 }}>
                            <LeadsDetail studentData={this.props.studentData} />
                        </div>

                    </Dialog>
                </div>
                <Viewer
                    zoomSpeed={0.8}
                    visible={this.state.imagesModal}
                    onClose={() => this.setState({ imagesModal: false })}
                    images={this.state.assignmentImages}
                />
                {
                    this.state.showCreateNotifications &&
                    <SendNotifications studentData={data}
                        onSuccess={() => {
                            this.setState({ showCreateNotifications: false })
                        }}
                        closeNotificationModal={() => {
                            this.setState({ showCreateNotifications: false })
                        }}></SendNotifications>
                }

                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.referralListModal}
                    toggle={() => this.toggleState('referralListModal')}
                >
                    <div className='modal-header'>
                        {/* <h4 className="modal-title" id="modal-title-default">
                  Referrals List
                </h4> */}
                        <Button
                            size='sm'
                            className='ml-auto'
                            color='primary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('referralListModal')}
                        >
                            Close
                        </Button>
                    </div>
                    {/* <div className="modal-body"> */}
                    <MaterialTable
                        columns={[
                            { title: 'Full name', field: 'full_name' },
                            { title: 'Email', field: 'email' }
                        ]}
                        data={user.referrals}
                        title='Referrals List'
                        options={{
                            actionsColumnIndex: -1,
                            headerStyle: {
                                backgroundColor: '#6772E5',
                                color: '#FFF'
                            }
                        }}
                    />
                    {/* </div> */}

                </Modal>

                <Modal
                    className='modal-dialog-centered'
                    isOpen={this.state.followListModal}
                    toggle={() => this.toggleState('followListModal')}
                >
                    <div className='modal-header'>
                        {/* <h4 className="modal-title" id="modal-title-default">
                  Referrals List
                </h4> */}
                        <Button
                            size='sm'
                            className='ml-auto'
                            color='primary'
                            data-dismiss='modal'
                            type='button'
                            onClick={() => this.toggleState('followListModal')}
                        >
                            Close
                        </Button>
                    </div>
                    {/* <div className="modal-body"> */}
                    <MaterialTable
                        columns={[
                            { title: 'Full name', field: 'following.full_name' }
                        ]}
                        data={this.state.followList}
                        title={this.state.followTableTitle}
                        options={{
                            actionsColumnIndex: -1,
                            headerStyle: {
                                backgroundColor: '#6772E5',
                                color: '#FFF'
                            }
                        }}
                    />
                    {/* </div> */}

                </Modal>

                {this.state.paymentHistory &&
                    <PaymentStatus
                        paymentStatus={this.state.paymentHistory}
                        toggleState={this.toggleState}
                        studentData={this.props.studentData}
                        getNoteDetail={this.getStudentNotesDetail}
                    />
                }
                {this.state.historyDetail &&
                    <StudentHistoryDetail
                        historyDetail={this.state.historyDetail}
                        toggleState={this.toggleState}
                        studentData={this.state.studentHistoryDetail}
                        getNoteDetail={this.getStudentNotesDetail}
                    />
                }

                {this.state.promoCode &&
                    <PromoCode
                        promoCode={this.state.promoCode}
                        toggleState={this.toggleState}
                        studentData={this.props.studentData}
                        getPromoCodeDetail={this.getPromoCodeDetail}
                    />
                }
                {this.state.showRemarks &&
                    <AssignmentDescription
                        toggleState={this.toggleState}
                        showRemarks={this.state.showRemarks}
                        arrayObject={this.state.assignmentRemarks}
                    />
                }
            </>
        )
    }
}

export default withSnackbar(StudentDetails)