import React, { useRef, useEffect } from 'react'
import { select } from 'd3-selection'
import { tree, hierarchy } from 'd3-hierarchy'

import './referralDetails.scss'
import * as d3 from 'd3'

export const ReferralTree = ({ data }) => {
    const svgRef = useRef(null)
    let i = 0 // Define i here

    useEffect(() => {
        const width = 928
        const height = width
        const cx = width * 0.5
        const cy = height * 0.59
        const radius = Math.min(width, height) / 2 - 10

        const radialTree = tree()
            .size([2 * Math.PI, radius])
            .separation((a, b) => (a.parent == b.parent ? 1 : 2) / a.depth)

        const root = radialTree(
            hierarchy(data).sort((a, b) => a.data.full_name.localeCompare(b.data.full_name))
        )

        const svg = select(svgRef.current)
            .attr('width', width)
            .attr('height', height)
            .attr('viewBox', [-cx, -cy, width, height])
            .attr('style', 'width: 100%; height: auto; font: 10px sans-serif;')

        function update(source) {
            const nodes = radialTree(root).descendants()
            const links = radialTree(root).links()

            nodes.forEach((d) => {
                d.y = d.depth * 80
            })

            const link = svg
                .selectAll('path.link')
                .data(links, (d) => d.target.id)

            link.enter()
                .insert('path', 'g')
                .attr('class', 'link')
                .attr('d', d3.linkRadial()
                    .angle(d => d.x)
                    .radius(d => d.y)
                )

            const node = svg
                .selectAll('g.node')
                .data(nodes, (d) => d.id || (d.id = ++i))

            const nodeEnter = node
                .enter()
                .append('g')
                .attr('class', 'node')
                .attr(
                    'transform',
                    (d) => `rotate(${(d.x * 180) / Math.PI - 90})translate(${d.y},0)`
                )
                .on('click', click)

            nodeEnter
                .append('circle')
                .attr('r', 4.5)
                .style('fill', (d) => (d._children ? 'lightsteelblue' : '#fff'))

            const anchor = nodeEnter
                .append('a')  // Add anchor tag
                .attr('href', (d) => `${window.location.origin}/mlm/referralIds?id=${d.data.id || 1}`)  // Set the URL dynamically
                .attr('target', '_blank')  // Open link in a new tab/window

            anchor
                .append('text')
                .attr('dy', '.31em')
                .attr('text-anchor', (d) => (d.x < Math.PI ? 'start' : 'end'))
                .attr('transform', (d) =>
                    d.x < Math.PI
                        ? 'translate(8)'
                        : `rotate(180)translate(-8)`
                )
                .text((d) => d.data.full_name)
                .attr('fill', (d) => (d.data.payment_status === 'Paid' ? 'purple' : 'black'))

            node.select('circle').attr('r', 4.5).style('fill', (d) =>
                d._children ? 'lightsteelblue' : '#fff'
            )

            node.select('text').style('fill-opacity', 1)

            // const nodeExit = node.exit().remove()

            // const linkExit = link.exit().remove()

            function click(event, d) {
                if (d.children) {
                    d._children = d.children
                    d.children = null
                } else {
                    d.children = d._children
                    d._children = null
                }
                update(d)
            }
        }

        update(root)
    }, [])

    return <svg ref={svgRef}></svg>
}
