import AppBar from '@material-ui/core/AppBar'
import Dialog from '@material-ui/core/Dialog'
import IconButton from '@material-ui/core/IconButton'
import Toolbar from '@material-ui/core/Toolbar'
import Typography from '@material-ui/core/Typography'
import CloseIcon from '@material-ui/icons/Close'
import MaterialTable from 'material-table'
import { withSnackbar } from 'notistack'
import React from 'react'
import { Label } from 'reactstrap'
import SendNotifications from './SendNotifications'
import UserProfilePage from './UserProfilePage'
import { convertToRaw, EditorState } from 'draft-js'
import draftToHtml from 'draftjs-to-html'
import LeadsStageDetail from './LeadsStageDetail'



const Services = require('../../RemoteServices/RemoteServices.js')


class LeadsStudentDetials extends React.Component {
    constructor(props) {
        super(props)
        this.state = {
            states: [
                { name: 'Call', options: ['Yes', 'No', 'Invalid'], selectedOption: null },
                { name: 'Notification', options: ['Yes', 'No', 'Invalid'], selectedOption: null },
                { name: 'Email', options: ['Yes', 'No', 'Invalid'], selectedOption: null },
                { name: 'SMS', options: ['Yes', 'No', 'Invalid'], selectedOption: null },
                { name: 'Whatsapp', options: ['Yes', 'No', 'Invalid'], selectedOption: null },
                { name: 'Viber', options: ['Yes', 'No', 'Invalid'], selectedOption: null },
                // Add more states as needed
            ],
            open: true,
            showDelieveredAmount: false,
            showCurrentLessonUpdate: false,
            showAssignmentVisibilityUpdate: false,
            showCreateNotifications: false,
            historyData: [],
            studentDetails: {
                current_lesson: {
                    title: ''
                }
            },
            studentDetailsNew: {},
            studentId: this.props?.studentData?.user?.id,
            assignmentImages: [],
            imagesModal: false,
            referralListModal: false,
            followListModal: false,
            followList: [],
            followListNext: '',
            followListPrevious: '',
            followTableTitle: '',
            pageLoading: true,
            noteDetail: [],
            paymentHistory: false,
            historyDetail: false,
            studentHistoryData: '',
            promoCode: '',
            promoCodeDetails: [],
            editorState: EditorState.createEmpty(),
            editorContentHtml: '',
            detailsPanel: false,
            selectedRow: ''
        }
        this.openImagesInNewTab = this.openImagesInNewTab.bind(this)
    }

    // submitOverallSteps = () => {
    //     let leadsData = {
    //         user_id: this.props.studentData.id,
    //         is_call: this.state.states[0].selectedOption,
    //         is_viber: this.state.states[5].selectedOption,
    //         is_whatapp: this.state.states[4].selectedOption,
    //         is_email: this.state.states[2].selectedOption,
    //         is_notification: this.state.states[1].selectedOption,
    //         is_sms: this.state.states[3].selectedOption,
    //         stage_no: 1,
    //         overall_comment: this.state.editorContentHtml
    //     }
    //     Services.sendLeadsPost(leadsData).then((response) => {
    //         this.props.enqueueSnackbar('Notifications Successfully Send', {
    //             variant: 'success',
    //             anchorOrigin: {
    //                 vertical: 'bottom',
    //                 horizontal: 'right'
    //             }
    //         })
    //         this.getDetails()

    //     })
    //         .catch((error) => {
    //             this.props.enqueueSnackbar('Error! Please try again later or contact administrator', {
    //                 variant: 'warning',
    //                 anchorOrigin: {
    //                     vertical: 'bottom',
    //                     horizontal: 'right'
    //                 }
    //             }
    //             )
    //         })

    // }



    async componentDidMount() {
        // await this.getAssignmentHistory()
        await this.getDetails()
        // await this.getStudentNotesDetail()
        // await this.getPromoCodeDetail()
    }

    onEditorStateChange = (editorState) => {
        const rawContentState = convertToRaw(editorState.getCurrentContent())
        this.setState({
            editorState,
            editorContentHtml: draftToHtml(rawContentState)
        })
    }


    uploadImageCallBack = (file) => {
        let token = localStorage.getItem('token')

        return new Promise(
            (resolve, reject) => {
                const xhr = new XMLHttpRequest()
                const serverUrl = window.config.apiUrl + 'common/image-upload'
                xhr.open('POST', serverUrl)
                xhr.setRequestHeader('Authorization', `Bearer ${token}`)
                const data = new FormData()
                data.append('image', file)
                xhr.send(data)
                xhr.addEventListener('load', () => {
                    const response = JSON.parse(xhr.responseText)
                    resolve(response)
                })
                xhr.addEventListener('error', () => {
                    const error = JSON.parse(xhr.responseText)
                    reject(error)
                })
            }
        )
    }


    async getAssignmentHistory() {
        const userId = this.props.studentData.id
        Services.getAssignmentListHistory(userId).then((response) => {
            this.setState({ historyData: response.results.reverse() })
        })
        await this.getDetails()
    }

    getDetails = async () => {
        const userId = this.props.studentData.id
        await Services.getLeadDetail(userId).then((response) => {
            this.setState({ studentDetails: response.data, pageLoading: false })
        })
    }

    getStudentNotesDetail = async (id) => {
        const userId = id ? id : this.props.studentData.id
        await Services.getStudentNotes(userId).then((response) => {
            this.setState({ noteDetail: response.results })
        })
    }

    getPromoCodeDetail = async (id) => {
        const userId = id ? id : this.props.studentData.user.id
        await Services.getPromoCode(userId).then((response) => {
            this.setState((prevState) => ({
                ...prevState,
                promoCodeDetails: response
            }))
        }).catch((e) => {
           console.log('asdf', e)
        })
    }

    async openImagesInNewTab() {
       //console.log('reached here', this.state.selectedRow)
        const images = []
        if (this.state.selectedRow) {
            let userAssignmentImages = this.state.selectedRow.images
            userAssignmentImages.forEach((image) => {
                images.push({ src: image.image, alt: '' })
                // window.open(image.image, "_blank")
            })
            this.setState({ assignmentImages: images })
            this.setState({ imagesModal: true })
           //console.log(this.state.assignmentImages)
        }
    }

    async setRowData(rowData) {
        await this.setState({ selectedRow: rowData })
        await this.openImagesInNewTab()
    }

    toggleState = state => {
        this.setState({
            [state]: !this.state[state]
        })
    }
    handleCheckbox = (stateIndex, option) => {
        const { states } = this.state;
        const updatedStates = [...states];
        updatedStates[stateIndex].selectedOption = option;
        this.setState({ states: updatedStates });
    };

    isSubmitDisabled = () => {
        const { states } = this.state;
        return states.some(state => state.selectedOption === null) || this.state.editorContentHtml.trim() === '';
    };

    render() {
 
       //console.log('asdfasdf', this.state.states[0].selectedOption)
        const data = this.state.studentDetails
     
        return (this.state.pageLoading ?
            <> Loading </> :
            <>
                <div>

                    <Dialog fullScreen open={this.state.open} onClose={() => {
                        this.props.closePanel()
                    }} TransitionComponent={this.Transition}>

                        <AppBar color='info' style={{ position: 'relative' }}>
                            <Toolbar>
                                <IconButton edge='start' color='inherit' onClick={() => {
                                    this.props.closePanel()
                                }} aria-label='close'>
                                    <CloseIcon />
                                </IconButton>
                                <Typography variant='h6' style={{
                                    marginLeft: 20,
                                    flex: 1
                                }}>
                                    <h2>User Details</h2>
                                </Typography>
                                {/* <Button color='primary' type='button' onClick={() => {
                                    // this.props.closePanel()
                                    this.setState({ showCreateNotifications: true })
                                }}>
                                    SEND MARKETING MESSAGE
                                </Button> */}
                            </Toolbar>
                        </AppBar>
                        <div style={{ width: '100%' }}>
                            <UserProfilePage userId={this.props.studentData} />
                        </div>
                        {this.state.studentDetails.student_leads.length > 0 &&
                            <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'flex-start', width: '100%', paddingLeft: '10px', paddingRight: '10px' }}>
                                <MaterialTable
                                    style={{ width: '100%' }}
                                    columns={[
                                        { title: 'Stage', field: 'stage_no' },
                                        {
                                            title: 'Call', field: 'is_call',
                                            render:
                                                rowData =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                                        <span>{this.state.studentDetails.phone_number || 'Number'}</span>
                                                        {rowData.is_call &&
                                                            <>
                                                                {rowData.is_call === 'Yes' ?
                                                                    <i className='fa fa-check' style={{ color: 'green' }} /> :
                                                                    rowData.is_call === 'No' ?
                                                                        <i className='fa fa-times' style={{ color: 'red' }} /> :
                                                                        <i className='fa fa-ban' style={{ color: 'red' }} />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                        },
                                        {
                                            title: 'Email', field: 'is_email',
                                            render:
                                                rowData =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                                        <span>{this.state.studentDetails.email || 'Number'}</span>
                                                        {rowData.is_email &&
                                                            <>
                                                                {rowData.is_email === 'Yes' ?
                                                                    <i className='fa fa-check' style={{ color: 'green' }} /> :
                                                                    rowData.is_email === 'No' ?
                                                                        <i className='fa fa-times' style={{ color: 'red' }} /> :
                                                                        <i className='fa fa-ban' style={{ color: 'red' }} />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                        },
                                        {
                                            title: 'Notification', field: 'is_notification',
                                            render:
                                                rowData =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                                        <span>{'App/Web'}</span>
                                                        {rowData.is_notification &&
                                                            <>

                                                                {rowData.is_notification === 'Yes' ?
                                                                    <i className='fa fa-check' style={{ color: 'green' }} /> :
                                                                    rowData.is_notification === 'No' ?
                                                                        <i className='fa fa-times' style={{ color: 'red' }} /> :
                                                                        <i className='fa fa-ban' style={{ color: 'red' }} />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                        },
                                        {
                                            title: 'SMS',
                                            field: 'is_sms',
                                            render:
                                                rowData =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                                        <span>{this.state.studentDetails.phone_number}</span>
                                                        {rowData.is_sms &&
                                                            <>
                                                                {rowData.is_sms === 'Yes' ?
                                                                    <i className='fa fa-check' style={{ color: 'green' }} /> :
                                                                    rowData.is_sms === 'No' ?
                                                                        <i className='fa fa-times' style={{ color: 'red' }} /> :
                                                                        <i className='fa fa-ban' style={{ color: 'red' }} />
                                                                }
                                                            </>

                                                        }
                                                    </div>
                                        },
                                        {
                                            title: 'Viber',
                                            field: 'is_viber',
                                            render:
                                                rowData =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                                        <span>{this.state.studentDetails.phone_number || 'Number'}</span>
                                                        {rowData.is_viber &&
                                                            <>
                                                                {rowData.is_viber === 'Yes' ?
                                                                    <i className='fa fa-check' style={{ color: 'green' }} /> :
                                                                    rowData.is_viber === 'No' ?
                                                                        <i className='fa fa-times' style={{ color: 'red' }} /> :
                                                                        <i className='fa fa-ban' style={{ color: 'red' }} />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                        },
                                        {
                                            title: 'Whatsapp', field: 'is_whatapp',
                                            render:
                                                rowData =>
                                                    <div style={{ display: 'flex', flexDirection: 'row', gap: '5px', alignItems: 'center' }}>
                                                        <span>{this.state.studentDetails.phone_number || 'Number'}</span>
                                                        {rowData.is_whatapp &&
                                                            <>
                                                                {rowData.is_whatapp === 'Yes' ?
                                                                    <i className='fa fa-check' style={{ color: 'green' }} /> :
                                                                    rowData.is_whatapp === 'No' ?
                                                                        <i className='fa fa-times' style={{ color: 'red' }} /> :
                                                                        <i className='fa fa-ban' style={{ color: 'red' }} />
                                                                }
                                                            </>
                                                        }
                                                    </div>
                                        },



                                    ]}
                                    data={this.state.studentDetails.student_leads.filter(obj => obj.stage_no !== 7)}
                                    // title={'Stages Followup'}
                                    actions={[
                                        {
                                            icon: 'visibility',
                                            tooltip: 'View ',
                                            onClick: async (event, rowData) => {
                                                await this.setState({
                                                    detailsPanel: true,
                                                    selectedRow: rowData
                                                })
                                            }
                                        },
                                    ]}
                                    options={{
                                        // exportButton: true,
                                        // exportAllData: true,
                                        // exportCsv: (columns, data) => {
                                        //   alert('You should develop a code to export ' + data.length + ' rows');
                                        // },
                                        search: false,
                                        pageSize: this?.state.studentDetails?.student_leads?.length,
                                        rowsPerPage:  this?.state.studentDetails?.student_leads?.length,
                                        pageSizeOptions: [6],
                                        // columnsButton: true,
                                        showTitle: false,
                                        toolbar: false,
                                        actionsColumnIndex: -1,
                                        headerStyle: {
                                            backgroundColor: 'white',
                                            color: 'black'
                                        }
                                    }}
                                />
                            </div>
                        }
                        <div style={{ display: 'flex', flexDirection: 'row', justifyContent: 'space-between', marginBottom: '30px', padding: 10 }}>
                            {/* <div className='card shadow' style={{ width: '49.5%', padding: '10px' }}>
                                <Label style={{ fontSize: 22, fontWeight: 'bold' }}>Followup (Stage-1) </Label>
                                <br />
                                <div style={{ display: 'flex', flexDirection: 'row', border: '1px solid black', borderRadius: 10, padding: '15px', marginBottom: '20px', width: '100%' }}>
                                    <div style={{ display: 'flex', flexDirection: 'row', width: '100%' }}>
                                        <div style={{ width: '70%', display: 'flex', flexDirection: 'column', gap: '20px' }}>
                                            <div style={{ width: '100%', display: 'flex', flexDirection: 'column', widht: '100%', alignItems: 'center', gap: '10px' }}>
                                                {states.map((state, index) => (
                                                    <div style={{ width: '100%', display: 'flex', flexDirection: 'row' }} key={state.name}>
                                                        <div style={{ width: '40%' }}>
                                                            <h3>{state.name}</h3>
                                                        </div>
                                                        <div style={{ width: '70%', display: 'flex', flexDirection: 'row', gap: '15px', alignItems: 'center' }}>
                                                            {state.options.map(option => (
                                                                <label key={option}>
                                                                    <input
                                                                        type="checkbox"
                                                                        checked={state.selectedOption === option}
                                                                        onChange={() => this.handleCheckbox(index, option)}
                                                                    />&nbsp;
                                                                    {option}
                                                                </label>
                                                            ))}
                                                        </div>
                                                        <br />
                                                    </div>
                                                ))}
                                            </div>
                                        </div>


                                    </div>
                                </div>
                                <Label style={{ fontSize: 18, fontWeight: 'bold' }}>Stage Comment</Label>
                                <Editor
                                    editorStyle={{
                                        border: '1px solid black',
                                        padding: '5px',
                                        borderRadius: '2px',
                                        height: '310px',
                                        width: '100%'
                                    }}
                                    placeholder='Description'
                                    editorState={this.state.editorState}
                                    wrapperClassName='demo-wrapper'
                                    editorClassName='demo-editor'
                                    onEditorStateChange={this.onEditorStateChange.bind(this)}
                                    toolbar={{
                                        inline: { inDropdown: true },
                                        list: { inDropdown: true },
                                        textAlign: { inDropdown: true },
                                        link: { inDropdown: true },
                                        history: { inDropdown: true },
                                        image: {
                                            uploadCallback: this.uploadImageCallBack,
                                            previewImage: true,
                                            defaultSize: { width: '100%', height: 'auto' }
                                        }
                                    }}
                                />
                                <br />
                                <div style={{ width: '100%', justifyContent: 'center', display: 'flex', flexDirection: 'row' }}>
                                    <Button onClick={() => this.submitOverallSteps()} style={{ width: '600px', height: '50px' }} color='primary' disabled={this.isSubmitDisabled()}>
                                        Submit Overall Steps
                                    </Button>
                                </div>
                            </div> */}
                            {this.props.stage !== 7 &&
                                <div className='card shadow' style={{ width: '100%', padding: '10px' }}>
                                    <Label style={{ fontSize: 22, fontWeight: 'bold' }}>Send Followup Message (Stage-{this.props.stage})</Label>
                                    <br />
                                    <SendNotifications studentData={data}
                                        stage={this.props.stage}
                                        getDetails={() => this.getDetails()}
                                        setStage={this.props.setStage}
                                        closePanel={this.props.closePanel}
                                        getLeadList={this.props.getLeadList}
                                    ></SendNotifications>
                                </div>
                            }
                        </div>
                        {this.state.detailsPanel &&
                            <LeadsStageDetail
                                open={this.state.detailsPanel}
                                toggleState={this.toggleState}
                                leadsDetail={this.state.selectedRow}
                            />
                        }

                    </Dialog>
                </div>

            </>
        )
    }
}

export default withSnackbar(LeadsStudentDetials)