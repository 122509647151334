/* eslint-disable react/jsx-no-duplicate-props */
/* eslint-disable react-hooks/exhaustive-deps */

import React, { useState, useEffect } from 'react'
import { Button, Modal, Form, FormGroup, Input } from 'reactstrap'
import LinkQuizForm from './LinkQuizForm'
import { withSnackbar } from 'notistack'
const Services = require('../../../RemoteServices/RemoteServices.js')

const LinkQuizTabs = (props) => {

    let [alertDeleteSet, setAlertDeleteSet] = useState(false)
    let [targetkey, setTargetKey] = useState(null)
    let [masterPassword, setMasterPassword] = useState('')

    const { lessonQuiz } = props
   

    let questions = [
        {
            questionText: '',
            link_answerOptions: [
                { answerText: '', isCorrect: false },
                { answerText: '', isCorrect: false }
            ]
        }

    ]


    const initialPanes = [
        {
            title: 'Set 1',
            key: 1,
            number: 1,
            quizData:lessonQuiz&&lessonQuiz.length>0?lessonQuiz:questions
            // closable: false
        }
    ]

    const [states, setStates] = useState({
        activeKey: lessonQuiz && lessonQuiz.length > 0 ? lessonQuiz[0].key || 1 : initialPanes[0].key,
        panes: initialPanes
    })


    //Changing array quiz data

    const handleQuizSetChange = (number, quizData) => {
        //console.log('Quiz data tab', quizData)
        setStates(prevState => ({
            ...prevState, panes: prevState.panes.map((val) => {
                    if (val.number === number) { return { ...val, quizData } }
                    else return val
                }
            )
        }))

    }

    useEffect(() => {
        //console.log('states.panes',states)
        //console.log('lessonQuiz',lessonQuiz)
        props.handleQuizArray(states.panes);
    }, [states])


    // Math.floor(Math.random() * 10).toString();

    // const add = () => {
    //     const { panes } = states
    //     const { number } = panes[states.panes.length - 1]
    //     const activePane = number + 1
    //     const newPanes = [...panes]
    //     newPanes.push({
    //         title: `Set ${activePane}`,
    //         key: activePane,
    //         number: activePane,
    //         quizData: questions
    //     })
    //     setStates({
    //         panes: newPanes,
    //         activeKey: activePane
    //     })

    // }

    // const onChange = (key) => {
    //     setStates({ ...states, activeKey: key })
    // }

    // const handleCrossClick = (targetkey) => {
    //     setAlertDeleteSet(true)
    //     setTargetKey(targetkey)
    // }


    const remove = (targetKey) => {
        const { panes, activeKey } = states
        let newActiveKey = activeKey
        let lastIndex
        panes.forEach((pane, i) => {
            if (pane.key === Number(targetKey)) {
                lastIndex = i - 1
            }
        })
        const newPanes = panes.filter(
            (pane) => pane.key !== Number(targetKey)
        ).map(pane => {
            if (pane.key > targetKey) {
                return { ...pane, title: `Set ${pane.key - 1}`, key: pane.key - 1, number: pane.number - 1 }
            } else return pane
        })

        if (newPanes.length && newActiveKey === targetKey) {
            if (lastIndex >= 0) {
                newActiveKey = newPanes[lastIndex].key
            } else {
                newActiveKey = newPanes[0].key
            }
        } else {
            newActiveKey = newActiveKey - 1
        }
        setStates({
            panes: newPanes,
            activeKey: newActiveKey
        })
    }

    // const onEdit = (targetKey, action) => {
    //     action === 'add' ? add() : handleCrossClick(targetKey)
    // }

    const { panes } = states

    return (
        <div>
            {panes.map((pane, index) => (
                    <LinkQuizForm questionsData={pane.quizData} number={pane.number} handleQuizSetChange={handleQuizSetChange} />
            ))}
            <Modal className='modal-dialog-centered' isOpen={alertDeleteSet} toggle={() => {
                setAlertDeleteSet(!alertDeleteSet)
            }}>
                <div className='modal-header'>
                    <h5 className='modal-title' id='exampleModalLabel'>
                        Confirmation
                    </h5>
                    <button
                        aria-label='Close'
                        className='close'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => {
                            setAlertDeleteSet(false)
                        }}
                    >
                        <span aria-hidden={true}>×</span>
                    </button>
                </div>
                <div className='modal-body'>
                    <h4>Are you sure you want to delete this quiz set?</h4>
                    <Form>
                        <FormGroup>
                            <Input type='password' onChange={(e) => {
                                setMasterPassword(e.target.value)
                            }} placeholder='Enter master password'></Input>
                        </FormGroup>
                    </Form>
                </div>
                <div className='modal-footer'>
                    <Button
                        color='secondary'
                        data-dismiss='modal'
                        type='button'
                        onClick={() => {
                            setAlertDeleteSet(false)
                        }}
                    >
                        Cancel
                    </Button>
                    <Button color='primary' type='button'
                            onClick={() => {
                                Services.verifyMasterPassword({ password: masterPassword }).then((response) => {
                                    remove(targetkey)
                                    setAlertDeleteSet(false)
                                    setMasterPassword('')
                                }).catch(() => {
                                    props.enqueueSnackbar('Sorry, your master password did not match.', {
                                            variant: 'warning',
                                            anchorOrigin: {
                                                vertical: 'bottom',
                                                horizontal: 'right'
                                            }
                                        }
                                    )
                                })
                            }}
                    >
                        Yes
                    </Button>
                </div>
            </Modal>
        </div>
    )
}

export default withSnackbar(LinkQuizTabs)
