import React, { useEffect, useMemo, useState } from 'react'
import avatar from '../../assets/img/dummyUser.png'

import MaterialTable from 'material-table'

const Services = require('../../RemoteServices/RemoteServices.js')


export const ReferralDownlineTable = ({ data, id }) => {
    const [downline2, setDownline2] = useState(false)
    const [downline3, setDownline3] = useState(false)
    const [downline1List, setdownline1List] = useState([])
    const [downline2List, setdownline2List] = useState([])
    const [downline3List, setdownline3List] = useState([])


    const fetchDownline1 = useMemo(() => (count) => {
        let requestData = {
            downline_count: 1
        }
        Services.postHierarchy(id, requestData).then((response) => {
            setdownline1List(response.data)
        }).catch((error) => {
            console.log(error)
        })
    }, [id]);
    const fetchDownline2 = async (id) => {
        let requestData = {
            downline_count: 1
        }
        Services.postHierarchy(id, requestData).then((response) => {
            setdownline2List(response.data)
            if (response.data.children.length > 0) {
                setDownline2(true)
            }
        }).catch((error) => {
            console.log(error)
        })

    }

    useEffect(() => {
        fetchDownline1()
    }, [])
    const fetchDownline3 = (id) => {
        let requestData = {
            downline_count: 1
        }
        Services.postHierarchy(id, requestData).then((response) => {
            setdownline3List(response.data)
            if (response.data.children.length > 0) {
                setDownline3(true)
            }
        }).catch((error) => {
            console.log(error)
        })

    }
    let base = window.config.base
    return (
        <>
            <div className={'mt-4 mb-2'}>
                <MaterialTable
                    title={data?.full_name + ' (' + data.downline_1_count + ')'}
                    columns={[
                        {
                            title: 'Avatar',
                            field: 'imageUrl',
                            render: rowData => <img
                                src={rowData?.children?.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                style={{ width: 40, borderRadius: '50%' }} 
                                alt=''
                                />
                        },
                        {
                            title: 'Full Name',
                            field: 'full_name',
                            render: rowData => <a
                                href={`${window.location.origin}/mlm/referralIds?id=${rowData.id}`}
                                 target='_blank' rel='noopener noreferrer'
                                style={{ cursor: 'pointer',color:'black' }}>{rowData.full_name}</a>
                        },
                        { title: 'Email', field: 'email' },
                        {
                            title: 'Payment Status', field: 'payment_status', width: '30%', render: rowData => <>
                                <div
                                    className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                    style={{
                                        display: 'flex',
                                        justifyContent: 'center',
                                        width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                    }}>
                                    <span style={{ fontSize: '12px' }}>{rowData?.payment_status}</span>
                                </div>
                            </>
                        },
                        {
                            title: 'Referred Count',
                            field: 'downline_all_count',
                            render: rowData => <span>{rowData.total_referrer || 0}</span>
                        }
                    ]}
                    data={downline1List.children}
                    actions={[
                        {
                            icon: 'visibility',
                            tooltip: 'View',
                            onClick: (event, rowData) => fetchDownline2(rowData.id)
                        }
                    ]}
                    options={{
                        actionsColumnIndex: -1,
                        headerStyle: {
                            backgroundColor: '#6772E5',
                            color: '#FFF'
                        }
                    }}
                />
            </div>
            {downline2 &&
                <>
                    <div className={'mt-2 mb-2'}>
                        <MaterialTable
                            title={downline2List?.full_name + ' (' + downline2List.downline_1_count + ')'}
                            columns={[
                                {
                                    title: 'Avatar',
                                    field: 'imageUrl',
                                    render: rowData => <img
                                        src={rowData?.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                        style={{ width: 40, borderRadius: '50%' }} alt='' />
                                },
                                {
                                    title: 'Full Name',
                                    field: 'full_name',
                                    render: rowData => <a
                                        href={`${window.location.origin}/mlm/referralIds?id=${rowData.id}`}
                                        target='_blank' rel='noopener noreferrer' 
                                        style={{ cursor: 'pointer',color:'black' }}>{rowData.full_name}</a>
                                },
                                { title: 'Email', field: 'email' },
                                {
                                    title: 'Payment Status',
                                    field: 'payment_status',
                                    width: '30%',
                                    render: rowData => <>
                                        <div
                                            className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                            }}>
                                            <span style={{ fontSize: '12px' }}>{rowData?.payment_status}</span>
                                        </div>
                                    </>
                                },
                                {
                                    title: 'Referred Count',
                                    field: 'downline_all_count',
                                    render: rowData => <span>{rowData.total_referrer}</span>
                                }
                                // {
                                //     title: 'Referred Count',
                                //     field: 'downline_all_count',
                                //     render: rowData => <span>{rowData.children.length||0}</span>
                                // }
                            ]}
                            data={downline2List.children}
                            actions={[
                                {
                                    icon: 'visibility',
                                    tooltip: 'View',
                                    onClick: (event, data) => fetchDownline3(data.id)

                                }
                            ]}
                            options={{
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: '#6772E5',
                                    color: '#FFF'
                                }
                            }}
                        />
                    </div>
                </>
            }
            {downline3 &&
                <>
                    <div className={'mt-2 mb-2'}>
                        <MaterialTable
                            title={downline3List?.full_name + ' (' + downline3List.downline_1_count + ')'}
                            columns={[
                                {
                                    title: 'Avatar',
                                    field: 'imageUrl',
                                    render: rowData => <img
                                        src={rowData?.profile_picture ? base.concat('/media/').concat(rowData.profile_picture) : avatar}
                                        style={{ width: 40, borderRadius: '50%' }}  alt=''/>
                                },
                                {
                                    title: 'Full Name',
                                    field: 'full_name',
                                    render: rowData => <a
                                        href={`${window.location.origin}/mlm/referralIds?id=${rowData.id}`}
                                        target='_blank' rel='noopener noreferrer'
                                        style={{ cursor: 'pointer',color:'black' }}>{rowData.full_name}</a>
                                },
                                { title: 'Email', field: 'email' },
                                {
                                    title: 'Payment Status',
                                    field: 'payment_status',
                                    width: '30%',
                                    render: rowData => <>
                                        <div
                                            className={rowData?.payment_status === 'Paid' ? 'badge-success' : 'badge-danger'}
                                            style={{
                                                display: 'flex',
                                                justifyContent: 'center',
                                                width: '60px', padding: '2px', borderRadius: '10px', cursor: 'pointer'
                                            }}>
                                            <span style={{ fontSize: '12px' }}>{rowData?.payment_status}</span>
                                        </div>
                                    </>
                                },
                                {
                                    title: 'Referred Count',
                                    field: 'downline_all_count',
                                    render: rowData => <span>{rowData.total_referrer}</span>

                                }
                            ]}
                            data={downline3List.children}
                            actions={[]}
                            options={{
                                actionsColumnIndex: -1,
                                headerStyle: {
                                    backgroundColor: '#6772E5',
                                    color: '#FFF'
                                }
                            }}
                        />
                    </div>
                </>
            }
        </>
    )
}
