const remote = {
    address: process.env.REACT_APP_BASE,
    address1:`${process.env.REACT_APP_BASE}/media/`
}

export const FormatImage = (link) => {
    return `${remote.address + link}`
}
export const FormatThumbnailImage=(link)=>{
    return `${remote.address1 + link}`
}
