/*!

=========================================================
* Argon Dashboard React - v1.1.0
=========================================================

* Product Page: https://www.creative-tim.com/product/argon-dashboard-react
* Copyright 2019 Creative Tim (https://www.creative-tim.com)
* Licensed under MIT (https://github.com/creativetimofficial/argon-dashboard-react/blob/master/LICENSE.md)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/
import React from "react";
import { Route, Switch, Redirect } from "react-router-dom";
// reactstrap components
import { Container } from "reactstrap";
// core components
import AdminNavbar from "components/Navbars/AdminNavbar.js";
import AdminFooter from "components/Footers/AdminFooter.js";
import Sidebar from "components/Sidebar/Sidebar.js";

import routes from "routes.js";
import { Notifications } from 'react-push-notification';
import messageSound from '../assets/sound/sound.mp3'
import debounce from 'lodash.debounce'; // Import debounce from lodash library
import { SocketContext } from "./Socket";
const Services = require('../RemoteServices/RemoteServices');


class Admin extends React.Component {
  static contextType = SocketContext
  constructor(props) {
    super(props)
    this.state = {
      message: [],
      onlineUsers: [],
      users: [],
      filteredUsers: '',
      messages: [],
      currentUserId: null,
      currentStudentId: null,
      newMessage: "",
      searchQuery: "",
      showMessage: false,
      userTyping: false,
      userDetail: '',
      userTyping: false,
      fullName: '',
      bulkMessage: false,
      setMessage: false,
      saveMessageList: [],
      loading: false,
      allMessagesLoaded: false,
      nextUrl: '',
      loader1: false,
      onlineUsers: [],
      pageLoading: false,
      onlineCount:'',
      outsideTyping:{},
      opencall:false,   
      incomingAudio:true,
      incomingvideo:true,

      incommingCallUserId:null, 
      incommingCallProfile:'',
      incommingCallUserName:'', 
      

    }
    this.debouncedGetChatUsersList = debounce(this.getChatUsersList, 800);
  }

  resetMessage = () => {
    this.setState({ message: [] })
  }
  resetUserTyping = () => {
    this.setState({ userTyping: false })
  }

  getOnlineCount = async () => {
    await Services.getOnlineCount().then((response) => {
      console.log('aaa',response.total_online_count)
      this.setState({ 
        onlineCount:response.total_online_count
       })
    })
      .catch((error) => {
        throw error
      })
  }


  getChatMessage = async (memberId) => {
    this.setState({ loading: true })
    await Services.getChatMessage(memberId).then((response) => {
      this.setState({ messages: response.results.reverse(), showMessage: true, loading: false, nextUrl: response.next })
      let delivery;
      response.results.filter(item => item.to_user_id === 'admin' && (item.delivery_type === 'sent' || item.delivery_type === 'delivered')).map((item) => {
        delivery = {
          action: 'delivery_status',
          id: item.id,
          delivery_type: 'seen',
          me_id: JSON.parse(localStorage.getItem('user'))?.id,
          user_id: memberId
        }
        return this.context.socket.send(JSON.stringify(delivery));
      })

    })
      .catch((error) => {
        throw error
      })
  }

  getNext = async () => {
    this.setState({ loader1: true });
    try {
      if (this.state.nextUrl) {
        const response = await Services.getLinkCustom(this.state.nextUrl);
        this.setState((prevState) => ({
          messages: [...response.results.reverse(), ...prevState.messages,],
          loader1: false,
          nextUrl: response.next,
          allMessagesLoaded: !response.next,
        }));
      } else {
        this.setState({ loader1: false })
      }
    } catch (error) {
      this.setState({ loading: false });
      console.error(error);
    }
  };

  handleUserClick = async (user) => {
    await this.getChatMessage(user.id);
    const updatedUsers = this.state.filteredUsers.results.map((u) => {
      if (u.id === user.id) {
        return { ...u, unread_msg_count: 0,delivery_type:'seen' };
      }
      return u;
    });

    this.setState((prevState) => ({
      currentUserId: user.id,
      currentStudentId: user.student_id,
      userDetail: user,
      filteredUsers: {
        ...prevState.filteredUsers,
        results: updatedUsers,
      }
    }));
  }

  updateOnlineStatus = (idsToCheck, mainArray) => {
    this.setState({ onlineUsers: idsToCheck })
    const idSet = new Set(idsToCheck); // Create a Set for fast lookup

    const updatedMainArray = mainArray.map(user => {
      if (idSet.has(user.id)) {
        return { ...user, is_online: true };
      } else {
        return { ...user, is_online: false };
      }
    });

    let updatedUserDetail = { ...this.state.userDetail };

    if (this.state.userDetail && idSet.has(this.state.userDetail.id)) {
      updatedUserDetail = {
        ...this.state.userDetail,
        is_online: true,
        last_online: this.getFormattedCurrentDateTime()
      };
    } else if (this.state.userDetail) {
      updatedUserDetail = {
        ...this.state.userDetail,
        is_online: false,
        last_online: this.getFormattedCurrentDateTime()
      };
    }
    this.setState((prevState) => ({
      filteredUsers: {
        ...prevState.filteredUsers,
        results: updatedMainArray,
        userDetail: updatedUserDetail
      }
    }));
  }


  getFormattedCurrentDateTime = () => {
    const now = new Date();

    // Get individual components of the date
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, '0'); // Months are 0-based
    const day = String(now.getDate()).padStart(2, '0');
    const hours = String(now.getHours()).padStart(2, '0');
    const minutes = String(now.getMinutes()).padStart(2, '0');
    const seconds = String(now.getSeconds()).padStart(2, '0');
    const milliseconds = String(now.getMilliseconds()).padStart(3, '0');

    // Simulate microseconds by generating three additional random digits
    const microseconds = String(Math.floor(Math.random() * 1000)).padStart(3, '0');

    // Get timezone offset in minutes and convert to hours and minutes
    const offset = now.getTimezoneOffset();
    const absOffset = Math.abs(offset);
    const offsetHours = String(Math.floor(absOffset / 60)).padStart(2, '0');
    const offsetMinutes = String(absOffset % 60).padStart(2, '0');
    const offsetSign = offset <= 0 ? '+' : '-';

    const formattedDateTime = `${year}-${month}-${day}T${hours}:${minutes}:${seconds}.${milliseconds}${microseconds}${offsetSign}${offsetHours}:${offsetMinutes}`;

    return formattedDateTime;
  }


  updateMessagesArray = (incomingMessage, messages) => {
    const { full_name, message, timestamp, phone_number, user, to_user_id, delivery_type, userImage } = incomingMessage;

    let userIndex;

    if (to_user_id === 'admin') {
      userIndex = messages.findIndex(userObj => userObj.id === user);

    } else {
      // Find the index of the user in the messages array using to_user_id
      userIndex = messages.findIndex(userObj => userObj.id === to_user_id);
    }
    if (userIndex !== -1) {
      // User found, update message details and move to the 0th index
      let updatedUser = {
        ...messages[userIndex],
        unread_msg_count: this.props.location.pathname === '/admin/messages' ?
                        (    this.state.currentUserId === to_user_id ? 0 :
                             (this.state.currentUserId === user ? 0 : messages[userIndex].unread_msg_count + 1) )
                             : messages[userIndex].unread_msg_count + 1,
        latest_message: message,
        latest_timestamp: timestamp,
        is_online: messages[userIndex].is_online ,
    
        delivery_type:this.props.location.pathname === '/admin/messages' ?
        (    this.state.currentUserId === to_user_id ? 'seen' :
             (this.state.currentUserId === user ? 'seen' : delivery_type) )
             : delivery_type,
      };
      // Remove the user from the current position
      messages.splice(userIndex, 1);
      // Add the updated user to the 0th index
      messages.unshift(updatedUser);
    } else {
      // User not found, add new user object to the 0th index
      messages.unshift({
        profile_picture_url: userImage?.replace("/media/", ""),
        full_name: full_name,
        phone_number: phone_number || null, // Add phone number if available
        latest_message: message,
        latest_timestamp: timestamp,
        last_online: timestamp,
        is_online: true,
        id: user,
        unread_msg_count: 1,

        
        delivery_type: delivery_type
        // Use `user` from the incoming message
      });

    }

   

    // Set the new state with the updated messages array
    this.setState((prevState) => ({
      filteredUsers: {
        ...prevState.filteredUsers,
        results: [...messages]
      }
    }));
    
  }
 

  updateDeliveryStatus = (incomingMessage) => {
    this.setState((prevState) => {
      const updatedMessages = prevState.messages.map((msg) =>
        msg.id === incomingMessage.id ? { ...msg, delivery_type: incomingMessage.delivery_type } : msg
      );
      return { messages: updatedMessages };
    });
  }


  getChatUsersList = async (data) => {
    try {
      let response;
      if (data) {
        response = await Services.getChatUser(data);
      } else {
        response = await Services.getChatUser();
      }

      // Update state based on API response
      this.setState({
        users: response.results,
        filteredUsers: {
          results: response.results, // Update results with API response
          count: response.count,     // Update other necessary properties
          next: response.next
          // Update other necessary properties
        },
      });
    } catch (error) {
      console.error('Error fetching chat users:', error);
      // Handle error appropriately
      this.setState({
        // users: [],  // Clear users data on error
        filteredUsers: {
          results: [], // Clear results on error
          count: 0,    // Clear other necessary properties
          // Clear other necessary properties
        },
      });
    }
  };

  nextChatUsers = async (nextUrl) => {
    try {
      const response = await Services.getNextChatUsers(nextUrl);
      await this.setState((prevState) => ({
        filteredUsers: {
          ...prevState.filteredUsers,
          count: response.count,     // Update other necessary properties
          next: response.next,
          results: [
            ...prevState.filteredUsers.results,
            ...response.results
          ]
        }
      }))
    } catch (e) {

    }
  }

  handleInputChange = (e) => {
    const searchQuery = e.target.value;
    this.setState({ searchQuery });

    // Call debouncedGetChatUsersList instead of getChatUsersList directly
    this.debouncedGetChatUsersList(searchQuery);

    // Filter users based on searchQuery immediately
    const filteredUsers = this.state.users.filter(user =>
      user.full_name.includes(searchQuery)
    );
    this.setState((prevState) => ({
      filteredUsers: {
        ...prevState.filteredUsers,
        results: filteredUsers,
      },
    }));

    // Trigger API call when searchQuery is empty
    if (searchQuery === '') {
      this.debouncedGetChatUsersList.cancel(); // Cancel debounce to force immediate API call
      this.getChatUsersList(); // Call API without parameters immediately
    }
  };


  listenWebsocket = () => {
    this.context.socket.onmessage = (event) => {
      const data = JSON.parse(event.data);
      console.log('mess-bulk',data)
      if(data?.type==='chat_message' && data?.message?.action==='typing'){
        this.setState({outsideTyping:data?.message.data})   
      }

      if(data?.message?.action==='bulk_message'){
  
      }

      if (data?.message?.action === 'message') {
        this.updateMessagesArray(data.message.data, this.state.filteredUsers.results,data.message.unread_count);
        if (data.message?.data.to_user_id === 'admin') {
          this.setState((prevMessage) => ({
            messages: [...prevMessage.messages, data.message.data],
            message: [...prevMessage.message, data.message.data]
          }));
            if (this.state.currentUserId === data.message.data.user) {
              if (this.props.history.location.pathname === '/admin/messages') {
              const delivery = {
                action: 'delivery_status',
                id: data.message.data.id,
                delivery_type: 'seen',
                me_id: JSON.parse(localStorage.getItem('user'))?.id,
                user_id: data.message.data.user
              };
              this.context.socket.send(JSON.stringify(delivery));
            }
          } else {
              const delivery1 = {
                action: 'delivery_status',
                id: data.message.data.id,
                delivery_type: 'delivered',
                me_id: JSON.parse(localStorage.getItem('user'))?.id,
                user_id: data.message.data.user
              };
              this.context.socket.send(JSON.stringify(delivery1));
            }
          if (data.message.data.to_user_id === 'admin') {
            const sound = new Audio(messageSound);
            sound.play().catch(error => console.error('Error playing sound:', error));
          }
        }else if(data.message.data.to_user_id === this.state.currentUserId){
          this.setState((prevMessage) => ({
            messages: [...prevMessage.messages, data.message.data],
            message: [...prevMessage.message, data.message.data]
          }));
        }
      } else if (data.message.action === 'online_users') {
        this.updateOnlineStatus(data.message.users, this.state.filteredUsers.results);
      } else if (data.message.action === 'typing') {
        if (data.message.data.me_id === this.state.userDetail.id) {
          this.setState({ userTyping: data.message.data.typing, fullName: data.message.data.full_name });
        }
      } else if (data.message.action === 'delivery_status') {      
        this.updateDeliveryStatus(data.message.data);
      }
    };

  };



  onAccept=()=>{
    const callMesage={
      action: "call",
      action_type: "accept_call",
      calling_to: 'user',
      user_id: this.state.incommingCallUserId,
      me_id: JSON.parse(localStorage.getItem("user"))?.id,
      full_name: JSON.parse(localStorage.getItem("user"))?.full_name,
    }
    this.context.socket.send(JSON.stringify(callMesage))
    this.setState({opencall:false})
   const url=`/udplayot/videocall?audio=${this.state.incomingAudio}&video=${this.state.incomingvideo}&user-id=${this.state.incommingCallUserId}&mode=incomming`
    window.open(url,'_blank')
 
}
onReject=()=>{
    const callMesage={
      action: "call",
      action_type: "reject_call",
      calling_to:'user',
      user_id: this.state.incommingCallUserId,
      me_id: JSON.parse(localStorage.getItem("user"))?.id,
      full_name: JSON.parse(localStorage.getItem("user"))?.full_name,
      resaon_to_reject:'User is currently Busy'
    }
    this.context.socket.send(JSON.stringify(callMesage))
  this.setState({opencall:false})
}

  componentDidMount() {
    if (this.props.location.pathname !== '/admin/messages') {
      this.setState({ currentUserId: null, showMessage: false, currentStudentId: null });
    }
    this.setState({ pageLoading: true })
    this.getChatUsersList().then((response) => {
      this.setState({ pageLoading: false })
    });

    this.getOnlineCount()
    this.listenWebsocket()
    // Add event listener for user interaction
    document.addEventListener('click', this.handleUserInteraction);
  }

  componentWillUnmount() {
    // Remove event listener for user interaction
    document.removeEventListener('click', this.handleUserInteraction);
  }

  handleUserInteraction = () => {
    this.setState({ userInteracted: true });
    // Remove the event listener after the first interaction
    document.removeEventListener('click', this.handleUserInteraction);
  }


  handleSendMessage = async () => {
    const { currentUserId, newMessage, messages } = this.state;
    if (!newMessage.startsWith('/')) {
      if (newMessage.trim() !== "" && currentUserId) {
        const message = {
          full_name: JSON.parse(localStorage.getItem('user')).full_name,
          icon: '',
          time: new Date().toISOString(),
          delivery_type: 'sent',
          state: 'sent',
          userImage: JSON.parse(localStorage.getItem('user')).profile_picture,
          message: newMessage,
          me_id: JSON.parse(localStorage.getItem('user')).id,
          to_user_id: currentUserId,
          user_id: currentUserId,
          phone_number: JSON.parse(localStorage.getItem('user')).phone_number,
          action: 'message',
          user: 50843,
          id: '',
        };
        this.context.socket.send(JSON.stringify(message));
        this.setState({ newMessage: "" });
      }
    }
  };

  resetSaveChatList = () => {
    this.setState({
      saveMessageList: []
    })
  }

  toggleState = (key, value) => {
    this.setState({
      [key]: value,
    });
  }

  setDynamicState = async (key, value) => {
    if (value && value.startsWith('/')) {
      this.setState({ [key]: value })
      if (value.length > 1) {
        await Services.getSaveChatList(value.substring(1, 100)).then((response) => {
          this.setState({ saveMessageList: response })
        })
          .catch((error) => {
            throw error
          })
      }
    } else {
      this.setState({
        [key]: value,
        saveMessageList: []
      });
    }
  };

  // componentDidUpdate(e) {
  //   document.documentElement.scrollTop = 0;
  //   document.scrollingElement.scrollTop = 0;
  //   this.refs.mainContent.scrollTop = 0;
  // }
  getRoutes = routes => {
    return routes.map((prop, key) => {
      if (localStorage.getItem("user")) {
        let userType = JSON.parse(localStorage.getItem('user')).user_type
        if (prop.layout === "/admin") {
          // if (hasAccess(prop.user, userType) && userType){
          return (
            <Route
              path={prop.layout + prop.path}
              key={key}
              render={(routeProps) => (
                <prop.component {...routeProps}
                  socket={this.context.socket}
                  message={this.state.message}
                  onlineUsers={this.state.onlineUsers}
                  filteredUsers={this.state.filteredUsers}
                  handleUserClick={this.handleUserClick}
                  messages={this.state.messages}
                  showMessage={this.state.showMessage}
                  userDetail={this.state.userDetail}
                  userTyping={this.state.userTyping}
                  currentUserId={this.state.currentUserId}
                  currentStudentId={this.state.currentStudentId}
                  handleInputChange={this.handleInputChange}
                  searchQuery={this.state.searchQuery}
                  newMessage={this.state.newMessage}
                  handleSendMessage={this.handleSendMessage}
                  setDynamicState={this.setDynamicState}
                  saveMessageList={this.state.saveMessageList}
                  resetSaveChatList={this.resetSaveChatList}
                  loading={this.state.loading}
                  resetMessage={this.resetMessage}
                  resetUserTyping={this.resetUserTyping}
                  toggleState={this.toggleState}
                  getNext={this.getNext}
                  loader1={this.state.loader1}
                  getChatUsersList={this.getChatUsersList}
                  pageLoading={this.state.pageLoading}
                  nextChatUsers={this.nextChatUsers}
                  onlineCount={this.state.onlineCount}
                  outsideTyping={this.state.outsideTyping}
                />
              )}
            />
          );
          // } else {
          //   this.props.history.push('/auth/login')
          // }
        } else {
          return null;
        }
      } else {
        this.props.history.push('/auth/login')
      }

    });
  };
  getBrandText = path => {
    for (let i = 0; i < routes.length; i++) {
      if (
        this.props.location.pathname.indexOf(
          routes[i].layout + routes[i].path
        ) !== -1
      ) {
        return routes[i].name;
      }
    }
    return "Brand";
  };

  

  render() {

    return (
      <>
        <Notifications />
        <Sidebar
          {...this.props}
          routes={routes}
          logo={{
            innerLink: "/admin/index",
            imgSrc: require("assets/img/brand/argon-logo.png"),
            imgAlt: "..."
          }}
        />
        <div className="main-content" ref="mainContent">
          <AdminNavbar
            socket={this.context.socket}
            message={this.state.message}
            onlineUsers={this.state.onlineUsers}
            {...this.props}
            brandText={this.getBrandText(this.props.location.pathname)}
            opencall={this.state.opencall}
            onAccept={this.onAccept}
            onReject={this.onReject}
            callerName={this.state.incommingCallUserName}
            callerImg={this.state.incommingCallProfile===null?
               require('../assets/img/theme/avatar.png'):
              this.state.incommingCallProfile
              }
          />
          <Switch>
            {this.getRoutes(routes)}
            <Redirect from="*" to="/admin/index" />
          </Switch>
          <Container fluid>
            <AdminFooter />
          </Container>
        </div>
      </>
    );
  }
}

export default Admin;
